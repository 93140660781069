import { truncateString } from "src/utils";

export default function Tabs({ tab = [], title, setContentTab, contentTab, Icon, comeFrom = "" }) {
	const TYPE = {
		Text: "Reading:",
		Audio: "Audio:",
		Video: "Video:",
		FlashCard: "Flashcard:",
		Exam: "Quiz",
	};
	return (
		<>
			<h6 style={{ fontSize: "16px", padding: "14px 16px 0px 16px", margin: 0 }}>{title}</h6>
			{tab.map((item, i) => {
				return (
					<button
						key={item.id}
						type="button"
						aria-label={item.name}
						onClick={() => setContentTab(item.id)}
						className={`accordion-for-subjects-btn ${
							contentTab === item.id ? "accordion-for-subjects-btn-select" : ""
						}`}
					>
						{comeFrom !== "topics"
							? Icon["file"]
							: Icon[item.isComplete ? "complete" : item.type]}
						<div>
							<span
								className="m-0 p-0 exam-details-accordion-body"
								dangerouslySetInnerHTML={{ __html: truncateString(item.name, 25) }}
							></span>

							<p
								style={{
									fontSize: 14,
									fontWeight: 400,
									margin: "5px 0px 0px 0px",
									width: "100%",
								}}
							>
								{TYPE[item.lesson?.content_type]}{" "}
								{item.lesson?.content_type === "FlashCard"
									? item.lesson?.total_count
									: item.lesson?.duration}
							</p>
						</div>
					</button>
				);
			})}
		</>
	);
}
