import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ResultItem({
	type = null,
	icon,
	link,
	progress,
	name,
	summary,
	btnName,
	text,
}) {
	const center = window.innerWidth < 768 ? "center" : "start";
	return (
		<div className={type}>
			<div
				key={1}
				className={type === "result" ? "px-5" : "px-2"}
				style={{
					backgroundColor: "white",
				}}
			>
				<Row className={`blog-author-wrapper align-items-center p-0`}>
					<Col
						className="course-img"
						sm={12}
						md={2}
						lg={1}
						style={{ marginBottom: "10px", textAlign: center }}
					>
						{icon}
					</Col>

					<Col sm={12} md={10} lg={11} className="author-content ">
						<Row sm={12} className="justify-content-between align-items-center">
							<Col sm={6}>
								<h6
									style={{
										fontSize: "20px",
										fontWeight: 700,
										lineHeight: "24px",
										letterSpacing: "0em",
										textAlign: center,
									}}
									className="title"
								>
									{name}
								</h6>
								{progress ? (
									<h6
										className="title"
										style={{
											fontSize: "24px",
											fontWeight: 700,
											lineHeight: "25px",
											letterSpacing: "0em",
											textAlign: center,
											color: parseInt(progress) <= 50 ? "red" : "green",
										}}
									>
										{progress} %
									</h6>
								) : null}

								<p
									className="title"
									style={{
										fontSize: "14px",
										fontWeight: 700,
										lineHeight: "25px",
										letterSpacing: "0em",
										textAlign: center,
										margin: 0,
									}}
								>
									{summary}
								</p>
							</Col>
							<Col sm={4} className="text-end d-none d-md-block">
								{btnName ? (
									<div className="load-more-btn text-end  h-0 ">
										{/* {text ?? null} */}
										<Link
											style={{ fontWeight: 600 }}
											className="btn btn-primary btn-lg py-3 fs-4 mx-4"
											to={link}
										>
											{btnName}
										</Link>
									</div>
								) : null}
							</Col>
						</Row>

						{btnName ? (
							<div className="load-more-btn text-center w-100 h-0 d-md-none  mt-4">
								<div className="mb-4">{text ?? null}</div>

								<Link className="edu-btn w-100" to={link}>
									{btnName}
								</Link>
							</div>
						) : null}
					</Col>
				</Row>
			</div>
		</div>
	);
}
