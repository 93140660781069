import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loading({ width = "100vw", height = "100vh" }) {
	return (
		<div
			style={{
				height,
				width,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Spinner animation="border" role="status" style={{ color: "lightgray" }}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}
