import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Question from "src/components/Quiz/Question";
import Loading from "src/components/common/Loading";
import {
	useGetCourseWiseModelTestQuery,
	useGetModelTestWiseQuestionQuery,
} from "src/redux/course/courseApi";
import { setCourseWiseExams, setQuestions } from "src/redux/course/courseSlice";

export default function Quiz() {
	const { exam_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const type = searchParams.get("type");
	const courseId = searchParams.get("course_id");
	const {
		isLoading: isLoadingForCourseWiseModelTest,
		isFetching,
		data: courseWiseModelTestRes,
		refetch,
	} = useGetCourseWiseModelTestQuery({ course_id: courseId });
	const { isLoading, data } = useGetModelTestWiseQuestionQuery({ exam_id, type });
	const questions = useSelector((state) => state.course.questions?.question);
	const exam_info = useSelector((state) => state.course.questions?.exam_info);
	const dispatch = useDispatch();
	const lan = localStorage.getItem("lang");
	useEffect(() => {
		if (data?.status === "success") {
			dispatch(setQuestions(data.data));
		}
	}, [isLoading]);

	useEffect(() => {
		if (courseWiseModelTestRes?.status === "success") {
			dispatch(setCourseWiseExams(courseWiseModelTestRes.data));
		}
	}, [isLoadingForCourseWiseModelTest, isFetching]);

	useEffect(() => {
		return () => {
			dispatch(setQuestions(null));
		};
	}, []);

	if (isLoading) return <Loading />;
	const questionsItem = questions
		? questions.map((item, index) => {
				return {
					id: item.id,
					sl: index + 1,
					text: lan === "en" ? item.title : item.title_bn,
					type: item.type,
					options: item.question_options.map((option) => {
						return {
							id: option.id,
							value: lan === "en" ? option.title : option.title_bn,
							weight: option.weight,
							is_right: option.is_right,
							checked: false,
						};
					}),
				};
		  })
		: null;
	return (
		<>
			{questionsItem && exam_info ? (
				<Question
					exam_info={exam_info}
					questionsItem={questionsItem}
					quizMin={exam_info?.duration}
					refetch={() => refetch({ course_id: courseId })}
				/>
			) : (
				<Loading />
			)}
		</>
	);
}
