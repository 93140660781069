import { useEffect } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetDashboardQuery } from "src/redux/dashboard/dashboardApi";
import { setDashboard } from "src/redux/dashboard/dashboardSlice";
import { lanChange } from "src/utils";
import Loading from "../common/Loading";

export default function Dashboard() {
	const user_info = useSelector((state) => state.auth?.user?.user);
	const enroll_course = useSelector((state) => state.dashboard?.enroll_course);
	const language = lanChange();
	const user_id = useSelector((state) => state.auth?.user?.user?.id);
	const { data: categoryAndEnrollCourse = {}, isSuccess } = useGetDashboardQuery(user_id);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isSuccess) {
			dispatch(setDashboard(categoryAndEnrollCourse.data));
		}
	}, [isSuccess]);

	if (!enroll_course) {
		return <Loading />;
	}

	return (
		<div>
			<div
				style={{
					backgroundColor: "#F4F9FF",
					padding: "50px 0px",
				}}
			>
				<div className="container">
					<Row>
						<Col sm={12}>
							<h4 className="my-4">
								{language.WelcomeBack}, {user_info?.name}
							</h4>
						</Col>
					</Row>

					<Row className="justify-content-between align-items-center">
						<Col sm={3}>
							<h5 className="my-4">{language.YourEnrolledCourses} </h5>
						</Col>
					</Row>
					{enroll_course?.length > 0 ? (
						<>
							{enroll_course?.map((course, i) => (
								<div
									key={i}
									className="px-5 my-4 shadow"
									style={{ backgroundColor: "white" }}
								>
									<div className="blog-author-wrapper align-items-center">
										<div className="thumbnail">
											<img
												src={course?.featured_image}
												alt="Author Images"
												style={{ height: 160, width: 160 }}
											/>
										</div>
										<div className="author-content w-100 ">
											<Row className="justify-content-between">
												<Col sm={12} md={6} lg={6}>
													{/* <h6 className="title">John Smith</h6> */}
													<h4>{course?.title}</h4>
													<h6 className="title">
														{language.Progress}:{" "}
														{Math.round(course?.total_progress)}%
													</h6>
												</Col>
												<Col
													sm={12}
													md={6}
													lg={4}
													className="text-end d-none d-md-block"
												>
													<div className="load-more-btn text-end h-0">
														<Link
															style={{ fontWeight: 600 }}
															className="btn btn-primary btn-lg py-3 fs-4"
															to={`/my-course/${course?.course_id}`}
														>
															{language.GoToCourse}
														</Link>
													</div>
												</Col>
											</Row>

											<ProgressBar
												now={course?.total_progress}
												max={100}
												min={0}
											/>
											<div className="load-more-btn text-center d-md-none h-0 mt-4 w-100">
												<Link
													style={{ fontWeight: 600 }}
													className="btn btn-primary btn-lg py-3 fs-4 w-100"
													to={`/my-course/${course?.course_id}`}
												>
													Go to Course
												</Link>
											</div>
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<h5 align="center" style={{ color: "gray" }}>
							No Course Found
						</h5>
					)}
				</div>
			</div>

			<div
				style={{
					backgroundColor: "white",
				}}
			>
				{/* <div className="container">
					<Row className="justify-content-between align-items-center">
						<Col sm={3}>
							<h5 className="my-4">Your Enrolled Courses</h5>
						</Col>
					</Row>
					<div className="px-5 my-4 shadow" style={{ backgroundColor: "white" }}>
						<div className="blog-author-wrapper align-items-center">
							<div className="thumbnail">
								<img
									src="/images/blog/author/author-medium/author-02.jpg"
									alt="Author Images"
								/>
							</div>
							<div className="author-content w-100 ">
								<Row className="justify-content-between">
									<Col sm={6}>
										<h6 className="title">John Smith</h6>
										<h4>Jhon Smith is an author</h4>
										<h6 className="title">Progress: 34%</h6>
									</Col>
									<Col
										sm={12}
										md={6}
										lg={4}
										className="text-end d-none d-md-block"
									>
										<div className="load-more-btn text-end h-0">
											<Link className="edu-btn" to="/my-course">
												Resume
											</Link>
										</div>
									</Col>
								</Row>

								<ProgressBar now={34} max={100} min={0} />
								<div className="load-more-btn text-center d-md-none h-0 mt-4 w-100">
									<Link className="edu-btn w-100" to="/my-course">
										Resume
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
}
