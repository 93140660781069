import { lanChange } from "src/utils";

export default function Written({ item }) {
	const language = lanChange();
	return (
		<div className="scroll-off">
			<div
				style={{
					padding: "20px 20px",
					border: "1px solid lightgray",
					borderRadius: 5,
					backgroundColor: "rgb(230, 241, 253)",
					marginBottom: 20,
				}}
			>
				<h6
					style={{ fontSize: 16, margin: 0 }}
					className="exam-details-accordion-body"
					dangerouslySetInnerHTML={{ __html: item?.question }}
				></h6>
			</div>
			{item?.video_url && (
				<iframe
					width="560"
					height="315"
					src={item?.video_url}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					style={{
						marginBottom: 20,
					}}
				></iframe>
			)}

			<div
				style={{
					padding: "20px 20px",
					border: "1px solid lightgray",
					borderRadius: 5,
				}}
			>
				<h6 style={{ fontSize: 16, marginBottom: 5 }}>{language?.Answer}:</h6>
				<div dangerouslySetInnerHTML={{ __html: item?.answer }} />
			</div>
		</div>
	);
}
