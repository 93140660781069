import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useCourseWiseWrittenResultQuery } from "src/redux/course/courseApi";
import { lanChange } from "src/utils";
import Tab from "../MyCourse/Tab";
import BreadcrumbOne from "../common/BreadcrumbOne";
import Loading from "../common/Loading";
export default function ViewWrittenExam() {
	const { exam_id } = useParams();
	const { isLoading, data } = useCourseWiseWrittenResultQuery({ exam_id });
	const language = lanChange();
	const lang = localStorage.getItem("lang");
	if (isLoading) return <Loading />;
	return (
		<div>
			<BreadcrumbOne
				currentUrl={data?.data?.exam?.title}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			<div className="container">
				<Row className="justify-content-between my-4">
					<Col sm={12} className="mb-4 text-center">
						<h4 className="mb-3">{data?.data?.exam?.title}</h4>
						<h5 className="mb-3">
							{language.score}: {data?.data?.score}
						</h5>
					</Col>
					<Col sm={12} className="mb-4">
						<Tab
							defaultTab="1"
							tab={[
								{
									id: "1",
									name: language.Questions,
									content: (
										<>
											{data?.data?.questions?.map((item, i) => (
												<h6
													className="m-0"
													dangerouslySetInnerHTML={{
														__html:
															lang === "en"
																? item.title
																: item.title_bn,
													}}
												></h6>
											))}
										</>
									),
								},
								{
									id: "2",
									name: language.SubmitFiles,
									content: (
										<>
											{data?.data?.submit_ans_files?.map((item, i) => {
												const isPng = item?.file_path?.includes(
													".png" || ".jpeg" || ".jpg",
												);
												const isJpg = item?.file_path?.includes(".jpg");
												const isPdf = item?.file_path?.includes(".pdf");

												if (isPdf) {
													return (
														<div
															key={i}
															className="my-4"
															style={{
																height: "100vh",
															}}
														>
															<h6 className="m-0 mb-2">
																{i + 1}. File
															</h6>
															<object
																key={i}
																data={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
																type="application/pdf"
																width="100%"
																height="100%"
																style={{ border: "none" }}
															>
																<p>
																	It appears you don't have a PDF
																	plugin for this browser.
																</p>
															</object>
														</div>
													);
												} else if (isPng || isJpg) {
													return (
														<div key={i} className="my-4">
															<h6 className="m-0 mb-2">
																{i + 1}. File
															</h6>
															<img
																src={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
																width="100%"
																height="100%"
																alt={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
															/>
														</div>
													);
												}
											})}
										</>
									),
								},
								{
									id: "3",
									name: language.CorrectAnswer,
									content: (
										<>
											{data?.data?.correct_ans_files?.length > 0 ? (
												data?.data?.correct_ans_files?.map((item, i) => {
													const isPng = item?.file_path?.includes(
														".png" || ".jpeg" || ".jpg",
													);
													const isJpg = item?.file_path?.includes(".jpg");
													const isPdf = item?.file_path?.includes(".pdf");

													if (isPdf) {
														return (
															<div
																key={i}
																className="my-4"
																style={{
																	height: "100vh",
																}}
															>
																<h6 className="m-0 mb-2">
																	{i + 1}. File
																</h6>
																<object
																	key={i}
																	data={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
																	type="application/pdf"
																	width="100%"
																	height="100%"
																	style={{ border: "none" }}
																>
																	<p>
																		It appears you don't have a
																		PDF plugin for this browser.
																	</p>
																</object>
															</div>
														);
													} else if (isPng || isJpg) {
														return (
															<div key={i} className="my-4">
																<h6 className="m-0 mb-2">
																	{i + 1}. File
																</h6>
																<img
																	src={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
																	width="100%"
																	height="100%"
																	alt={`${process.env.REACT_APP_BASE_URL}/storage/${item.file_path}`}
																/>
															</div>
														);
													}
												})
											) : (
												<h6>
													{
														language.Afterreviewingdonethecorrectanswerwillbeshownhere
													}
												</h6>
											)}
										</>
									),
								},
							]}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}
