import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	home: {},
};

const homeSlice = createSlice({
	name: "home",
	initialState,
	reducers: {
		setHomePageInfo: (state, action) => {
			state.home = action.payload;
		},
	},
});

export const { setHomePageInfo } = homeSlice.actions;
export default homeSlice.reducer;
