import { useEffect } from "react";
import AudioPlayer from "./Audio";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Layout = ({ children }) => {
	useEffect(() => {
		const lang = localStorage.getItem("lang");
		if (lang === "en") {
			document.body.style.fontFamily = "Onest";
		} else {
			document.body.style.fontFamily = "Noto Sans Bengali";
		}
	}, []);
	return (
		<>
			<main className="main-wrapper">
				<NavBar />
				{children}
				<AudioPlayer />
				<Footer />
			</main>
		</>
	);
};
export default Layout;
