import React, { useState } from "react";

export default function Tab({ defaultTab = "", tab = [], style = {} }) {
	const [contentTab, setContentTab] = useState(defaultTab);

	return (
		<div className={`mb-4 container`} style={style}>
			<div className="course-details-content m-0">
				<ul
					className={`edu-course-tab nav nav-tabs `}
					role="tablist"
					style={{
						justifyContent: "left",
						backgroundColor: "white",
						borderRadius: "0px",
					}}
				>
					{tab.map((item) => {
						return (
							<li
								key={item.id}
								className="nav-item"
								style={{ margin: "0px 25px 0px 0px" }}
							>
								<button
									className={
										contentTab === item.id ? "nav-link active" : "nav-link"
									}
									type="button"
									aria-label={item.name}
									onClick={() => setContentTab(item.id)}
									style={{
										textTransform: "capitalize",
										fontSize: "16px",
										fontWeight: "500",
									}}
								>
									{item.name}
								</button>
							</li>
						);
					})}
				</ul>

				<div
					className="tab-content"
					style={{
						marginTop: "20px",
					}}
				>
					{tab.map((item) => {
						if (item.id === contentTab) {
							return (
								<div
									key={item.id}
									className={`tab-pane fade show ${
										contentTab === item.id ? "active" : ""
									} `}
								>
									<div>{item.content}</div>
								</div>
							);
						}
						return null;
					})}
				</div>
			</div>
		</div>
	);
}
