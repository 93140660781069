import { Modal } from "react-bootstrap";
import CustomButton from "../common/CustomButton";

export default function EnrollModal({ modal, ...props }) {
	const fields = modal.fields?.map((field, i) => {
		switch (field?.type) {
			case "select":
				return (
					<select
						style={{ backgroundColor: "transparent", margin: "10px 0px" }}
						onChange={field.onChange}
						className={field.className}
						required={field.required}
					>
						{field.options.map((option, i) => (
							<option key={i} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				);

			case "submit":
				return (
					<CustomButton
						type={field?.type}
						disabled={field.disabled || props?.isLoadingEnrollCourse}
						className={field.className}
						style={{
							width: "100%",
							background: "#f98a17",
							padding: "20px",
							borderRadius: "5px",
						}}
						btnTextStyle={{
							fontSize: "16px",
							color: field.disabled ? "gray" : "white",
							fontWeight: 600,
						}}
					>
						{modal?.coupon ? (
							<>
								<span
									style={{
										textDecoration: "line-through",
										color: "red",
										marginRight: "10px",
									}}
								>
									{field.value}
								</span>
								<span>{`Pay ${modal?.coupon} BDT`} </span>
							</>
						) : (
							field.value
						)}
					</CustomButton>
				);
			default:
				return (
					<div style={{ margin: "20px 0px" }}>
						<label htmlFor={field.name}>{field.label}</label>
						<input {...field} />
					</div>
				);
		}
	});

	return (
		<Modal
			dialogClassName={`${modal.type}`}
			{...props}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			fullscreen="sm-down"
		>
			<div className="container px-4">
				{modal.iframe ? (
					<>
						<Modal.Header closeButton>
							<div
								style={{ fontSize: "24px", fontWeight: 700, color: "black" }}
								id="contained-modal-title-vcenter"
								className="text-center"
							>
								Payment Gateway
							</div>
						</Modal.Header>
						<Modal.Body>
							<iframe title="payment" src={modal.iframe} height={800}></iframe>
						</Modal.Body>
					</>
				) : (
					<>
						<Modal.Header closeButton>
							<div className="w-100">
								<div
									style={{ fontSize: "24px", fontWeight: 700, color: "black" }}
									id="contained-modal-title-vcenter"
									className="text-center"
								>
									{modal?.title}
								</div>
								<p
									id="contained-modal-title-vcenter"
									className="text-center"
									style={{ fontSize: "16px", fontWeight: 400, color: "gray" }}
								>
									{modal?.subTitle}
								</p>
							</div>
						</Modal.Header>
						<Modal.Body>
							<div className="login-form-box">
								<form
									className={`login-form ${modal.type}`}
									onSubmit={modal.onSubmit}
									autoComplete="off"
								>
									{fields}
								</form>
								<div className="input-box">
									{modal.footer && (
										<>
											<p className="text-center">{modal.footer.title}</p>
											{modal.footer.subTitle && (
												<p className="text-center">
													{modal.footer.subTitle}
												</p>
											)}
										</>
									)}
								</div>
							</div>
						</Modal.Body>
					</>
				)}
			</div>
		</Modal>
	);
}
