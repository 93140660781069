import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default function Search({ navBar, className = "" }) {
	console.log(navBar.search);
	return (
		<form onSubmit={navBar.search.onEnter}>
			<ReactSearchAutocomplete
				items={navBar.search.items}
				onSearch={navBar.search.onChange}
				styling={{ zIndex: 1, borderRadius: "10px" }}
				showNoResultsText=""
				placeholder={navBar.search.field.placeholder}
				showNoResults={false}
				className={className}
			/>
		</form>
	);
}
