import moment from "moment";
import React, { useEffect } from "react";
import { BiCalendar } from "react-icons/bi";
import { ImFileText } from "react-icons/im";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ReactComponent as CompleteIcon } from "src/assets/images/complete.svg";
import { ReactComponent as FileIcon } from "src/assets/images/file.svg";
import { lanChange } from "src/utils";
import CustomAccordionForExam from "./CustomAccordionForExam";
import Info from "./Info";
import ResultItem from "./ResultItem";

const Icon = {
	file: <FileIcon style={{ marginRight: 10, width: 50, height: 50 }} />,
	complete: <CompleteIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
};

export default function Exam({ course_id }) {
	const { courseWiseExams, myCourses } = useSelector((state) => state.course);
	const [filter, setFilter] = React.useState("content");
	const [filterValue, setFilterValue] = React.useState(null);
	const [writtenExam, setWrittenExam] = React.useState(courseWiseExams?.writen_exam);

	const language = lanChange();
	const mcq =
		courseWiseExams && Array.isArray(courseWiseExams?.mcq_test)
			? courseWiseExams?.mcq_test.map((item, i) => {
					const date = item.created_at ? moment(item.created_at) : null;
					const formattedDate = date ? date.format("DD MMM YYYY") : null;
					return {
						id: item.id,
						header: (
							<ResultItem
								type={"exam"}
								btnName={
									item?.attempt_no
										? parseInt(item?.previous_score) >= 50
											? ""
											: language.myCourse.StartAgain
										: language.myCourse.StartExam
								}
								link={"/quiz/" + item?.id + "?course_id=" + course_id}
								progress={item?.previous_score}
								name={item?.title}
								icon={Icon["file"]}
								summary={`${language.myCourse.Passmark}: 50% | ${
									formattedDate
										? `${language.myCourse.Date}: ` + formattedDate
										: ""
								} | ${language.myCourse.Duration} : ${Number(
									item.duration / 60,
								).toFixed(2)} ${language.myCourse.Min}`}
								text={
									item?.attempt_no && parseInt(item?.previous_score) < 50
										? language.myCourse.StartYourSecondAttemptStartAgain
										: ""
								}
							/>
						),
						body: (
							<div
								dangerouslySetInnerHTML={{
									__html: item.description,
								}}
								style={{
									padding: "20px 20px 20px 20px",
								}}
							></div>
						),
					};
			  })
			: [];
	const written =
		writtenExam && Array.isArray(writtenExam)
			? writtenExam.map((item, i) => {
					return {
						id: item.id,
						header: (
							<ResultItem
								type={"exam"}
								btnName={
									item?.attempt_no
										? parseInt(item?.previous_score) >= 50
											? ""
											: language.myCourse.StartAgain
										: language.myCourse.StartExam
								}
								link={"/my-course/" + course_id + "/written-exam/" + item?.id}
								progress={item?.previous_score}
								name={item?.title}
								icon={Icon["file"]}
								summary={`${language.myCourse.Passmark}: 50% `}
								// text={item?.is_review === 1 ? "In Review" : ""}
							/>
						),
						body: (
							<div
								dangerouslySetInnerHTML={{
									__html: item.description,
								}}
								style={{
									padding: "20px 20px 20px 20px",
								}}
							></div>
						),
					};
			  })
			: [];

	let containOptions =
		courseWiseExams && Array.isArray(courseWiseExams?.writen_exam)
			? courseWiseExams?.writen_exam.map((item, i) => {
					if (item.content_title) {
						return {
							value: item.id,
							label: item.content_title,
						};
					}
					return null;
			  })
			: [];
	useEffect(() => {
		if (filterValue) {
			if (filter === "content") {
				const filterValues = courseWiseExams?.writen_exam.filter(
					(item) => item.id === filterValue.value,
				);
				setWrittenExam(filterValues);
			} else {
				const filterValues = courseWiseExams?.writen_exam.filter(
					(item) => item.year === filterValue.value,
				);
				setWrittenExam(filterValues);
			}
		} else {
			setWrittenExam(courseWiseExams?.writen_exam);
		}
	}, [filterValue]);

	// remove null value
	containOptions = containOptions.filter((item) => item !== null);
	// remove duplicate value
	containOptions = [...new Set(containOptions.map((item) => JSON.stringify(item)))].map((item) =>
		JSON.parse(item),
	);
	let yearOptions =
		courseWiseExams && Array.isArray(courseWiseExams?.writen_exam)
			? courseWiseExams?.writen_exam.map((item, i) => {
					if (item.year) {
						return {
							value: item.year,
							label: item.year,
						};
					}
					return null;
			  })
			: [];
	// remove null value
	yearOptions = yearOptions.filter((item) => item !== null);
	// remove duplicate value
	yearOptions = [...new Set(yearOptions.map((item) => JSON.stringify(item)))].map((item) =>
		JSON.parse(item),
	);

	const options = {
		content: containOptions,
		year: yearOptions,
	};

	const tab = [
		{
			key: 1,
			id: 1,
			name: language.PracticeMCQModelTest,
			content:
				mcq.length > 0 ? (
					<CustomAccordionForExam
						defaultAccordion={courseWiseExams?.mcq_test?.[0]?.id}
						items={mcq}
					/>
				) : (
					<h6 className="text-center">{language.Nodatafound}</h6>
				),
		},
		{
			key: 2,
			id: 2,
			name: language.PracticeWrittenModelTest,
			content: (
				<div className={myCourses?.course?.course_type !== "High Court" ? "" : "m-4"}>
					{written.length > 0 ? (
						<>
							{myCourses?.course?.course_type !== "High Court" ? (
								""
							) : (
								<div className="d-flex align-items-center mb-4 w-100 ">
									<div className="w-50 me-5">
										<p className="m-0 fw-bold" style={{ color: "#f98a17" }}>
											{language.Selectquestionsbytype}
										</p>
										<div className="d-flex align-items-center w-100">
											<div className="d-flex align-items-center exam-filter mt-2 w-100">
												<button
													className={`btn d-flex align-items-center me-4 w-100 ${
														filter === "content" ? "select" : ""
													}`}
													style={{ fontSize: 18 }}
													onClick={() => {
														setFilter("content");
														setFilterValue(null);
													}}
												>
													<ImFileText className="me-3" />
													{language.Content}
												</button>
												<button
													className={`btn d-flex align-items-center w-100 ${
														filter === "year" ? "select" : ""
													}`}
													style={{ fontSize: 18 }}
													onClick={() => {
														setFilter("year");
														setFilterValue(null);
													}}
												>
													<BiCalendar className="me-3" /> {language.Year}
												</button>
											</div>
										</div>
									</div>
									<div className="w-50">
										<p
											className="m-0 fw-bold"
											style={{
												color: "#f98a17",
												textTransform: "capitalize",
											}}
										>
											{filter === "year"
												? language.SelectYear
												: language.SelectContent}
										</p>
										<div className="d-flex align-items-center w-100">
											<div className="d-flex align-items-center  mt-2 w-100">
												<Select
													placeholder={language.Select}
													className="basic-single w-100"
													classNamePrefix="select"
													defaultValue={filterValue}
													value={filterValue}
													isClearable={true}
													isSearchable={true}
													onChange={setFilterValue}
													name="color"
													options={options[filter]}
												/>
											</div>
										</div>
									</div>
								</div>
							)}
							<CustomAccordionForExam
								defaultAccordion={courseWiseExams?.writen_exam?.[0]?.id}
								items={written}
							/>
						</>
					) : (
						<h6 className="text-center">{language.Nodatafound}</h6>
					)}
				</div>
			),
		},
	];
	let defaultTabKey = 1;
	if (myCourses?.course?.course_type !== "High Court") {
		defaultTabKey = 1;
	} else {
		defaultTabKey = 2;
		tab.shift();
	}

	return (
		<div>
			<Info defaultTab={defaultTabKey} tab={tab} />
		</div>
	);
}
