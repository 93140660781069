import { lanChange } from "../../utils";
import CategoriesFilter from "./CategoriesFilter";
import CourseFilter from "./CourseFilter";
import FilterAccordion from "./FilterAccordion";
import PriceFilter from "./PriceFilter";
import RatingFilter from "./RatingFilter";

const Filters = () => {
	const language = lanChange();
	return (
		<aside className="edu-course-sidebar">
			<div className="widget-shortby">
				<div className="inner">
					<h5 className="widget-title">{language.courses.filter.title}</h5>
					<div className="content">
						<FilterAccordion
							items={[
								{
									id: "1",
									header: language.courses?.filter?.price,
									body: (
										<div className="price my-2 p-2">
											<PriceFilter />
										</div>
									),
								},
								{
									id: "2",
									header: language.courses?.filter?.category,
									body: (
										<div className="price my-2 p-2">
											<CategoriesFilter />
										</div>
									),
								},
								{
									id: "3",
									header: language.courses?.filter?.courses,
									body: (
										<div className="price my-2 p-2">
											<CourseFilter />
										</div>
									),
								},
								{
									id: "4",
									header: language.courses?.filter?.rating,
									body: (
										<div className="price my-2 p-2">
											<RatingFilter />
										</div>
									),
								},
							]}
						/>
					</div>
				</div>
			</div>
		</aside>
	);
};

export default Filters;
