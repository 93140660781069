import { useSelector } from "react-redux";
import AboutArea from "src/components/Landing/AboutArea";
import CallToActionOne from "src/components/Landing/CallToActionOne";
import Features from "src/components/Landing/Features";
import Gallery from "src/components/Landing/Gallery";
import HomeFiveProgress from "src/components/Landing/HomeFiveProgress";
import HomeFourTeam from "src/components/Landing/HomeFourTeam";
import HomeTwoTestimonial from "src/components/Landing/HomeTwoTestimonial";
import LandingDemoInnerPages from "src/components/Landing/LandingDemoInner";
import LandingDemoInnerPagesFooter from "src/components/Landing/LandingDemoInnerPagesFooter";
import SldierArea from "src/components/Landing/SldierArea";
import DownloadApp from "src/components/common/DownloadApp";
import SEO from "src/components/common/SEO";
import WeOfferTheBest from "src/components/common/WeOfferTheBest";
import { lanChange } from "src/utils";

const video = {
	poster: "https://www.w3schools.com/html/pic_trulli.jpg",
	subtitlesUrl: "https://www.w3schools.com/html/mov_bbb.vtt",
	src: "https://www.w3schools.com/html/mov_bbb.mp4",
};

export default function Landing() {
	// const { Video } = useVideoJS({
	// 	poster: video.poster,
	// 	sources: [{ src: video.src }],
	// 	controls: true,
	// 	playbackRates: [0.5, 1, 1.5, 2],
	// 	responsive: true,
	// 	language: "en",
	// 	enableDocumentPictureInPicture: true,
	// 	experimentalSvgIcons: true,
	// 	controlBar: {
	// 		skipButtons: {
	// 			backward: 10,
	// 			forward: 10,
	// 		},
	// 	},
	// });
	const language = lanChange();
	const { partner, footer_banner } = useSelector((state) => state.homePageInfo.home);
	const partners = partner?.map((item, i) => ({
		id: i,
		src: item?.partner_pic,
		alt: item.name,
		url: item.url,
	}));
	const footerBanner = footer_banner?.map((item, i) => ({
		id: i,
		src: item?.advertisement_pic,
		alt: item.name,
		url: item.url,
	}));
	return (
		<>
			<SEO title="Home" />
			<SldierArea />
			{/* <Video poster={video.poster} playsInline muted>
				<track src={video.subtitlesUrl} kind="subtitles" srcLang="en" label="English" />
			</Video> */}
			<WeOfferTheBest />
			<AboutArea />
			<Features />
			<HomeFiveProgress />
			<HomeFourTeam
				slogan={language.home.skilledProfessionals.title}
				title={language.home.skilledProfessionals.subTitle}
			/>
			<CallToActionOne />
			<HomeTwoTestimonial />
			<DownloadApp />
			{Array.isArray(partners) && partners.length > 0 ? (
				<LandingDemoInnerPages
					title={language.BRANDS}
					subTitle={language.OurTrustedPartners}
					bg="bg-color-light-blue"
					items={[...partners]}
				/>
			) : null}
			<Gallery />
			{Array.isArray(footerBanner) && footerBanner.length > 0 ? (
				<LandingDemoInnerPagesFooter items={[...footerBanner]} />
			) : null}
			`
		</>
	);
}
