import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	test: [],
};

const testSlice = createSlice({
	name: "test",
	initialState,
	reducers: {
		setTest: (state, action) => {
			state.test = action.payload;
		},
	},
});

export const { setTest } = testSlice.actions;
export default testSlice.reducer;
