import { configureStore } from "@reduxjs/toolkit";

import { homeApi } from "./HomePageInfo/homeApi";
import homeReducer from "./HomePageInfo/homeSlice";
import { authApi } from "./auth/authApi";
import authReducer from "./auth/authSlice";
import { dashboardApi } from "./dashboard/dashboardApi";
import dashboardReducer from "./dashboard/dashboardSlice";

import { setupListeners } from "@reduxjs/toolkit/query";
import audioReducer from "./audio/audioSlice";
import { courseApi } from "./course/courseApi";
import courseReducer from "./course/courseSlice";
import { notificationsApi } from "./notifications/notificationsApi";
import { testApi } from "./test/testApi";
import testReducer from "./test/testSlice";
const store = configureStore({
	devTools: process.env.REACT_APP_NODE_ENV === "development" ? true : false,
	reducer: {
		auth: authReducer,
		homePageInfo: homeReducer,
		dashboard: dashboardReducer,
		course: courseReducer,
		audio: audioReducer,
		test: testReducer,

		[authApi.reducerPath]: authApi.reducer,
		[homeApi.reducerPath]: homeApi.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
		[courseApi.reducerPath]: courseApi.reducer,
		[notificationsApi.reducerPath]: notificationsApi.reducer,
		[testApi.reducerPath]: testApi.reducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			authApi.middleware,
			homeApi.middleware,
			dashboardApi.middleware,
			courseApi.middleware,
			notificationsApi.middleware,
			testApi.middleware,
		]),
});

setupListeners(store.dispatch);

export default store;
