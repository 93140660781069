import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	enroll_course: null,
	category: null,
};

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setDashboard: (state, action) => {
			state.category = action.payload.category;
			state.enroll_course = action.payload.enroll_course;
		},
	},
});

export const { setDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
