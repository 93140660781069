import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import {
	useCourseWiseCertificateQuery,
	useGenerateCertificateMutation,
} from "src/redux/course/courseApi";
import { lanChange } from "src/utils";
import Loading from "../common/Loading";

export default function MyCertificates() {
	const [show, setShow] = useState(false);
	const [course, setCourse] = useState();

	const handleClose = () => {
		setShow(false);
		setCourse(null);
	};
	const handleShow = (course) => {
		setCourse(course);
		setShow(true);
	};
	const { data, isLoading, isSuccess } = useCourseWiseCertificateQuery();
	const [
		generateCertificate,
		{ isLoading: isLoadingGenerateCertificate, data: enerateCertificateRes },
	] = useGenerateCertificateMutation();
	const language = lanChange();

	if (isLoading || isLoadingGenerateCertificate) return <Loading />;
	if (!isSuccess)
		return (
			<h1
				className=" container"
				style={{
					height: "60vh",
					fontSize: "3rem",
					textAlign: "center",
					marginTop: "10rem",
				}}
			>
				Data not found!
			</h1>
		);

	return (
		<div
			className="my-4 container"
			style={{
				height: "70vh",
				overflowX: "scroll",
			}}
		>
			<div
				style={{
					overflowX: "scroll",
				}}
			>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>{language.sl}</th>
							<th>{language.courseName}</th>
							<th>{language.course_type}</th>
							<th>{language.course_progress}</th>
							<th>{language.course_duration}</th>
							<th>{language.action}</th>
						</tr>
					</thead>
					<tbody>
						{data?.data?.courseList?.length > 0 ? (
							data.data.courseList.map((item, index) => {
								return (
									<tr key={item.course_id}>
										<td>{index + 1}</td>
										<td>{item.title}</td>
										<td>{item.course_type}</td>
										<td align="center">{item.course_progress || 0}%</td>
										<td>{item.duration}</td>
										<td align="center">
											<button
												className="btn btn-primary fs-5"
												disabled={
													Math.round(data.data.certificate_progress) <=
													Math.round(item.course_progress)
														? false
														: true
												}
												onClick={(e) => {
													handleShow(item);
													generateCertificate({
														course_id: item.course_id,
													});
												}}
											>
												{Math.round(data.data.certificate_progress) <=
												Math.round(item.course_progress)
													? language.getCertificate
													: `Need to complete ${
															data.data.certificate_progress || 0
													  }%`}
											</button>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="6" align="center">
									No data found!
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>{" "}
			<Modal
				className="certificate-modal"
				dialogClassName="modal-90w"
				centered
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{course?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						height: "100%",
						width: "100%",
					}}
				>
					<embed
						src={enerateCertificateRes?.data?.certificate_link}
						type="application/pdf"
						width="100%"
						height="100%"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					{/* <Button
						variant="primary"
						onClick={() => {
							handleClose();
						}}
					>
						{language.DownloadCertificate}
					</Button> */}
				</Modal.Footer>
			</Modal>
		</div>
	);
}
