import { Link } from "react-router-dom";
import { lanChange } from "src/utils";

const PostThree = ({ data, classes, bgGray }) => {
	// let date = data.shortDate.split(" ");
	// let day = date[0];
	// let month = date[1];
	const language = lanChange();
	return (
		<Link to={process.env.PUBLIC_URL + `/course/${data.slug}`}>
			<div
				className={`edu-blog blog-type-2 variation-2 radius-small shadow ${
					classes ? classes : ""
				} ${bgGray === "enable" ? "bg-color-gray" : ""}`}
			>
				<div className="inner">
					<div className="thumbnail">
						<Link
							to={process.env.PUBLIC_URL + `/course/${data.slug}`}
							style={{
								maxWidth: "405px",
								maxHeight: "209px",
							}}
						>
							<img
								src={`${data.category_image}`}
								alt="Blog Thumb"
								style={{
									width: "100%",
									height: "100%",
								}}
							/>
						</Link>
					</div>
					<div className="content p-1">
						<div className="status-group status-style-5 mt-4">
							{/* <Link
							className="eduvibe-status status-05"
							to={
								process.env.PUBLIC_URL +
								`/category/${slugify(data.categories.slice(0, 1))}`
							}
						>
							<i className="icon-price-tag-3-line"></i> {data.categories.slice(0, 1)}
						</Link> */}
							{/* <span className="eduvibe-status status-05">
							<i className="icon-time-line"></i> {data.readingTime}
						</span> */}
						</div>
						<h5 className="title">
							<Link to={process.env.PUBLIC_URL + `/course/${data.slug}`}>
								{data.name}
							</Link>
						</h5>
						<div className="read-more-btn mt-4">
							<Link
								className="btn-transparent"
								to={process.env.PUBLIC_URL + `/course/${data.slug}`}
							>
								{language.home.banner.readMore}
								<i className="icon-arrow-right-line-right"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default PostThree;
