import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import { lanChange } from "../../utils";

export default function AboutArea() {
	const { about } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();
	return (
		<div id="about" class="edu-about-area about-style-1 edu-section-gap bg-color-white">
			<div class="container">
				<div class="row g-5">
					<div class="col-lg-6">
						<div class="about-image-gallery">
							<img
								class="image-1"
								src={about?.full_image || "assets/images/about-img-1.jpg"}
								alt="About Main Thumb"
							/>
							<div class="image-2">
								<img
									src={about?.short_image || "assets/images/about-img-2.jpg"}
									alt="About Parallax Thumb"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="inner">
							<ScrollAnimation
								animateIn="fadeInUp"
								animateOut="fadeInOut"
								animateOnce={true}
							>
								<div class="section-title">
									<span class="pre-title">{language.home.aboutUs.title}</span>
									<h3 class="title">
										{about?.title ||
											"Creating A Community Of Legal Education Learners"}
									</h3>
								</div>
							</ScrollAnimation>
							<ScrollAnimation
								animateIn="fadeInUp"
								animateOut="fadeInOut"
								animateOnce={true}
							>
								<p
									class="description"
									dangerouslySetInnerHTML={{
										__html:
											about?.discription ||
											`The Legalized Learning App is the first and only platform in
													Bangladesh to provide all the necessary legal and compliance
													knowledge and learning for new law graduates, local businesses
													and legal professionals to help improve the standard of legal
													service and quality of legal expertise available to the common
													citizens of the country, thus, ensuring access to justice.`,
									}}
								></p>
							</ScrollAnimation>

							<div class="about-feature-list">
								{/* <!-- Start Single Feature  --> */}
								<ScrollAnimation
									animateIn="fadeInUp"
									animateOut="fadeInOut"
									animateOnce={true}
								>
									<div class="our-feature">
										<div class="icon">
											<i class="icon-Hand---Book"></i>
										</div>
										<div class="feature-content">
											<h6 class="feature-title">
												{language.home.aboutUs.flexibleClasses}
											</h6>
											<p class="feature-description">
												{
													language.home.aboutUs
														.learnFromTheAppAtYourFlexibleTime
												}
											</p>
										</div>
									</div>
								</ScrollAnimation>

								{/* <!-- End Single Feature  --> */}

								{/* <!-- Start Single Feature  --> */}
								<ScrollAnimation
									animateIn="fadeInUp"
									animateOut="fadeInOut"
									animateOnce={true}
								>
									<div class="our-feature">
										<div class="icon">
											<i class="icon-Campus"></i>
										</div>
										<div class="feature-content">
											<h6 class="feature-title">
												{language.home.aboutUs.learnFromAnywhere}
											</h6>
											<p class="feature-description">
												{
													language.home.aboutUs
														.learnFromAnywhereAnyCornerOfThisWorld
												}
											</p>
										</div>
									</div>
								</ScrollAnimation>

								{/* <!-- End Single Feature  --> */}
							</div>
							{/* <!-- <div class="read-more-btn" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <a class="edu-btn" href="#">Know About Us<i class="icon-arrow-right-line-right"></i></a>
                    </div> -->
                    <!-- <div class="shape shape-6 about-parallax-2 d-xl-block d-none">
                        <img src="assets/images/shapes/shape-07.png" alt="Shape Thumb" />
                    </div> --> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
