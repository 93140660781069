// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-slider-track,
.rc-slider-tracks {
	background-color: #f98a17;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	border-color: #f98a17;
	box-shadow: 0 0 0 5px #f98a17;
}
.rc-slider-handle {
	border-color: #f98a17;
	background-color: #f98a17;
}

.accordion-button:not(.collapsed) {
	background-color: transparent;
	color: #303030;
}
`, "",{"version":3,"sources":["webpack://./src/components/AllCourseFilter/multiRangeSlider.css"],"names":[],"mappings":"AAAA;;CAEC,yBAAyB;AAC1B;AACA;CACC,qBAAqB;CACrB,6BAA6B;AAC9B;AACA;CACC,qBAAqB;CACrB,yBAAyB;AAC1B;;AAEA;CACC,6BAA6B;CAC7B,cAAc;AACf","sourcesContent":[".rc-slider-track,\n.rc-slider-tracks {\n\tbackground-color: #f98a17;\n}\n.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {\n\tborder-color: #f98a17;\n\tbox-shadow: 0 0 0 5px #f98a17;\n}\n.rc-slider-handle {\n\tborder-color: #f98a17;\n\tbackground-color: #f98a17;\n}\n\n.accordion-button:not(.collapsed) {\n\tbackground-color: transparent;\n\tcolor: #303030;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
