import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import { lanChange } from "src/utils";
import PostThree from "./PostThree";
import SectionTitle from "./SectionTitle";

export default function WeOfferTheBest() {
	const [categoryLength, setCategoryLength] = React.useState(3);
	const categoryAndEnrollCourse = useSelector((state) => state.dashboard.category);
	const language = lanChange();
	return (
		<div className=" bg-color-light-blue edu-blog-area edu-section-gap">
			<div className="container eduvibe-animated-shape">
				<div className="row">
					<div className="col-lg-6">
						<SectionTitle
							classes="text-start"
							slogan={language.home.banner.whoWeAre}
							title={language.home.banner.weOfferTheBest}
						/>
					</div>
					{categoryAndEnrollCourse?.length > 3 && (
						<div className="col-lg-6">
							<div className="load-more-btn text-end">
								<button
									className="edu-btn"
									onClick={() => {
										if (categoryAndEnrollCourse?.length === categoryLength) {
											setCategoryLength(3);
										} else {
											setCategoryLength(categoryAndEnrollCourse?.length);
										}
									}}
								>
									{categoryAndEnrollCourse?.length === categoryLength
										? language.home.banner.MinimizeCategories
										: language.home.banner.ExploreAllCategories}

									<i className="icon-arrow-right-line-right"></i>
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="row g-5 mt--20">
					{categoryAndEnrollCourse?.slice(0, categoryLength).map((item) => (
						<ScrollAnimation
							animateIn="fadeInUp"
							animateOut="fadeInOut"
							animateOnce={true}
							className="col-lg-4 col-md-6 col-12"
							key={item.id}
						>
							<PostThree data={item} />
						</ScrollAnimation>
					))}
				</div>
			</div>
		</div>
	);
}
