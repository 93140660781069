import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// svg file import
import { useDropzone } from "react-dropzone";
import { FaArrowLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as FileUpload } from "src/assets/images/file-upload.svg";
import { ReactComponent as File } from "src/assets/images/file.svg";
import { useWrittenExamSubmitMutation } from "src/redux/course/courseApi";
import { setQuestions } from "src/redux/course/courseSlice";
import { lanChange, truncateString } from "src/utils";
import CustomButton from "../common/CustomButton";
import ExamSuccessModal from "./ExamSuccessModal";

const thumb = {
	display: "inline-flex",
	borderRadius: 5,
	border: "1px solid #eaeaea",
	marginRight: 15,
	width: 50,
	height: 50,
	boxSizing: "border-box",
	padding: 4,
	backgroundColor: "white",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
};

const img = {
	display: "block",
	width: "auto",
	height: "100%",
	backgroundSize: "cover",
};
export default function WrittenQuestion({ questionsItem = [], questionInfo = {} }) {
	const [files, setFiles] = useState([]);
	const [writtenExamSubmit, { isLoading, data }] = useWrittenExamSubmitMutation();
	const navigate = useNavigate();
	const onDelete = (id) => {
		setFiles(files.filter((file) => file.id !== id));
	};
	const { course_id, exam_id } = useParams();
	const [show, setShow] = useState({ show: false, type: "" });
	const language = lanChange();
	const onHide = () => setShow((prev) => ({ ...prev, show: false, type: "" }));
	const onDrop = useCallback((acceptedFiles) => {
		setFiles((prev) => [...prev, ...acceptedFiles.map((file) => ({ file, id: file.name }))]);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: true,
	});

	const thumbs = files.map(({ file, id }) => (
		<div
			key={id}
			className="d-flex align-items-center justify-content-between my-4"
			style={{ backgroundColor: "#F4F9FF", padding: 10, borderRadius: 10 }}
		>
			<div className="d-flex align-items-center">
				<div style={thumb}>
					<div style={thumbInner}>
						<File style={img} />
					</div>
				</div>
				<div>
					<p className="m-0">{truncateString(file.name, 30)}</p>
					<p className="m-0">{(file.size / (1024 * 1024)).toFixed(2)} MB</p>
				</div>
			</div>
			<IoClose
				role="button"
				style={{ color: "#f98a17", fontSize: 28 }}
				onClick={() => onDelete(id)}
			/>
		</div>
	));

	const onSubmit = async () => {
		const formData = new FormData();
		formData.append(`exam_id`, exam_id);
		files.forEach(({ file }, index) => {
			formData.append(`answer_sheet[${index}]`, file);
		});
		await writtenExamSubmit(formData);
	};

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data?.message);
		}

		if (data?.status === "success") {
			toast.success(data?.message);
			if (course_id) {
				navigate(`/my-course/${course_id}`);
			} else {
				navigate(-1);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		return () => setQuestions([]);
	}, []);

	return (
		<form>
			<div style={{ backgroundColor: "#F4F9FF", marginBottom: 20 }}>
				<div className="container">
					<Row className="justify-content-between align-items-center ">
						<Col sm={1}>
							<FaArrowLeft
								onClick={() => {
									navigate(-1);
								}}
								role="button"
								style={{ fontSize: 20, color: "#f98a17" }}
							/>
						</Col>
						<Col sm={11} className="py-4">
							<h6 className="mb-0">{questionInfo?.title}</h6>
							<p className="m-0">
								{language.TotalQuestion} {questionsItem.length}
							</p>
						</Col>
					</Row>
				</div>
			</div>

			<div className="container">
				<Row className="justify-content-between">
					<Col
						sm={7}
						style={{
							height: "70vh",
							overflowY: "auto",
						}}
					>
						<ol className="questions" style={{ listStyle: "none" }}>
							{questionsItem.map((question, qIndex) => (
								<li key={question.id}>
									<div className="d-flex" style={{ color: "black" }}>
										{/* <h6 className="m-0 me-2">{qIndex + 1}.</h6> */}
										<h6
											className="m-0"
											dangerouslySetInnerHTML={{
												__html: question.text,
											}}
										></h6>
									</div>
								</li>
							))}
						</ol>
					</Col>
					<Col
						sm={4}
						style={{
							height: "70vh",
							border: "1px solid lightgray",
							borderRadius: 5,
							padding: "60px 20px",
						}}
						className="scroll-off"
					>
						<h5 style={{ color: "#f98a17", textAlign: "center" }}>
							{language.Uploadyouranswerhere}
						</h5>
						<div
							className="w-100"
							style={{
								border: "1.5px dashed rgba(32, 107, 196, 0.20)",
								borderRadius: 10,
								padding: 30,
								display: "flex",
								width: "335px",
								height: "248px",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "16px",
								flexShrink: 0,
								backgroundColor: "#F7F8FF",
							}}
							{...getRootProps()}
						>
							<FileUpload />
							<h6 className="m-0 p-0">
								<span style={{ color: "#6f6b80" }}>
									{language.Dropafilehereor},{" "}
								</span>
								<span role="button" style={{ color: "#f98a17" }}>
									{language.Clicktoupload}
									<input {...getInputProps()} />
								</span>
							</h6>
							<p className="m-0 p-0 text-center">{language.Acceptedfiletypesare}</p>
						</div>

						<div className="mx-2 my-4">{thumbs}</div>
					</Col>
				</Row>
			</div>

			<div style={{ backgroundColor: "#F4F9FF", marginTop: 20 }}>
				<div className="container py-4">
					<Row className="align-items-center justify-content-between">
						<Col sm={2}>
							<button
								type="button"
								className="btn btn-outline-danger w-100 text-center mt-4"
								style={{
									fontWeight: "bold",
									fontSize: "14px",
									padding: "10px 30px",
								}}
								onClick={() =>
									setShow((prev) => ({ ...prev, show: true, type: "cancel" }))
								}
							>
								{language.Cancel}
							</button>
						</Col>

						<Col sm={2}>
							<CustomButton
								type="button"
								className="btn btn-primary w-100 text-center mt-4"
								style={{
									fontWeight: "bold",
									fontSize: "14px",
									padding: "10px 30px",
								}}
								onClick={() => {
									if (files.length === 0) {
										toast.error("Please upload your answer sheet");
										return;
									}
									setShow((prev) => ({ ...prev, show: true, type: "submit" }));
								}}
							>
								{language.Submit}
							</CustomButton>
						</Col>
					</Row>
				</div>
			</div>
			{show?.show && (
				<ExamSuccessModal
					show={show}
					onHide={onHide}
					onSubmit={onSubmit}
					isLoading={isLoading}
					course_id={course_id}
				/>
			)}
		</form>
	);
}
