import React, { useEffect, useState } from "react";

export default function CustomTab({ defaultTab = "", tab = [] }) {
	const [contentTab, setContentTab] = useState(defaultTab);
	useEffect(() => {
		setContentTab(defaultTab);
	}, [defaultTab]);

	return (
		<div className="course-details-content m-0">
			<ul
				className={`edu-course-tab nav nav-tabs container`}
				role="tablist"
				style={{
					justifyContent: "left",
					borderRadius: "0px",
					backgroundColor: "#F5F5F5",
					padding: "0px 0px",
				}}
			>
				{tab.map((item) => {
					return (
						<li
							key={item.id}
							className="nav-item"
							style={{ margin: "0px 5px 0px 0px" }}
						>
							<button
								className={contentTab === item.key ? "nav-link active" : "nav-link"}
								type="button"
								aria-label={item.name}
								onClick={() => setContentTab(item.key)}
								style={{
									textTransform: "capitalize",
									fontSize: "12px",
									fontWeight: "700",
									padding: "10px 0px",
									margin: "0px 20px",
									borderRadius: "0px",
									color: "525FE1",
								}}
							>
								{item.name}
							</button>
						</li>
					);
				})}
			</ul>

			<div
				className="tab-content"
				style={{
					marginTop: "20px",
				}}
			>
				{tab.map((item) => {
					if (item.key === contentTab) {
						return (
							<div
								key={item.id}
								className={`tab-pane fade show ${
									contentTab === item.key ? "active" : ""
								} `}
							>
								<div>{item.content}</div>
							</div>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
}
