import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CourseItemsForFilter from "src/components/AllCourseFilter/CourseItemsForFilter";
import Filters from "src/components/AllCourseFilter/Filters";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import SEO from "src/components/common/SEO";
import { setCourseFilter } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";

const AllCourseFilterPages = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const search = searchParams.get("search");
	const language = lanChange();
	const { allCourses = [], filter = {} } = useSelector((state) => state.course);
	const dispatch = useDispatch();

	useEffect(() => {
		if (filter.minPrice || filter.maxPrice) {
			const filteredCourses = allCourses.filter((course) => {
				const price = course.course?.special_price || course.course?.price;

				return price >= filter.minPrice && price <= filter.maxPrice;
			});
			dispatch(setCourseFilter({ ...filter, courses: filteredCourses }));
		} else {
			dispatch(setCourseFilter({ ...filter, courses: allCourses }));
		}
	}, [filter.minPrice, filter.maxPrice]);
	// rating filter
	useEffect(() => {
		const ratings = filter.rating.filter((r) => r.checked === true).map((r) => r.value);
		if (ratings.length) {
			const min = Math.min(...ratings);

			const filteredCourses = allCourses.filter((course) => {
				let rating = Math.round(course.course.total_review / course.course.review_count);
				rating = rating ? rating : 0;

				return rating >= min && rating <= 5;
			});
			console.log(filteredCourses);
			dispatch(setCourseFilter({ ...filter, courses: filteredCourses }));
		} else {
			dispatch(setCourseFilter({ ...filter, courses: allCourses }));
		}
	}, [filter.rating]);
	// category wise filter
	useEffect(() => {
		const categories = filter.categoriesFilter
			.filter((c) => c.checked === true)
			.map((c) => c.id);
		if (categories.length) {
			const filteredCategories = allCourses.filter((course) =>
				categories.includes(course.category_id),
			);
			dispatch(setCourseFilter({ ...filter, courses: filteredCategories }));
		} else {
			dispatch(setCourseFilter({ ...filter, courses: allCourses }));
		}
	}, [filter.categoriesFilter]);
	// courses wise filter
	useEffect(() => {
		const courses = filter.coursesFilter.filter((c) => c.checked === true).map((c) => c.id);
		if (courses.length) {
			const filteredCourses = allCourses.filter((course) =>
				courses.includes(course.course.id),
			);
			dispatch(setCourseFilter({ ...filter, courses: filteredCourses }));
		} else {
			dispatch(setCourseFilter({ ...filter, courses: allCourses }));
		}
	}, [filter.coursesFilter]);

	useEffect(() => {
		if (search) {
			const filteredCourses = allCourses.filter((course) => {
				return course.course.title.toLowerCase().includes(search.toLowerCase());
			});

			dispatch(setCourseFilter({ ...filter, courses: filteredCourses }));
		} else {
			dispatch(setCourseFilter({ ...filter, courses: allCourses }));
		}
	}, [search]);

	return (
		<>
			<SEO title="Courses " />

			<BreadcrumbOne
				title="Courses Filter"
				rootUrl="/"
				parentUrl={language.courses.breadcrumb.parent}
				currentUrl={language.courses.breadcrumb.current}
			/>

			<div className="edu-course-area edu-section-gap bg-color-white">
				<div className="container">
					<Row className="row g-5">
						<Col sm={12} md={12} lg={12} xl={3}>
							<Filters />
						</Col>
						<Col sm={12} md={12} lg={12} xl={9}>
							<CourseItemsForFilter />
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default AllCourseFilterPages;
