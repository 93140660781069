import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as What } from "src/assets/images/what.svg";
import { lanChange } from "src/utils";

export default function ExamSuccessModal({ course_id, show, onHide, onSubmit, isLoading }) {
	const navigate = useNavigate();
	const language = lanChange();
	return (
		<Modal
			show={show.show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			fullscreen="sm-down"
		>
			<div className="container px-4">
				<Modal.Body>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
							padding: "34px",
						}}
					>
						<What />
						<h6 style={{ margin: "10px 0px" }}>
							{show?.type === "cancel"
								? language.AreYouSureYouWantToQuitTheExam
								: language.AreYouSureYouWantToSubmit}
						</h6>
					</div>
					{show?.type === "cancel" && (
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
								margin: "10px",
							}}
						>
							<Col sm={5}>
								<button
									className="btn btn-outline-danger p-4 w-100 mt-4"
									onClick={() => {
										if (course_id) {
											navigate(`/my-course/${course_id}`);
										} else {
											navigate(-1);
										}
										onHide();
									}}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									{language.Quit}
								</button>
							</Col>
							<Col sm={5}>
								<button
									className="btn btn-primary p-4 w-100 mt-4"
									onClick={onHide}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									{language.StayHere}
								</button>
							</Col>
						</Row>
					)}
					{show?.type === "submit" && (
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
								margin: "10px",
							}}
						>
							<Col sm={5}>
								<button
									className="btn btn-outline-danger p-4 w-100 mt-4"
									onClick={() => {
										onHide();
									}}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									{language.StayHere}
								</button>
							</Col>
							<Col sm={5}>
								<button
									className="btn btn-primary p-4 w-100 mt-4"
									onClick={onSubmit}
									disabled={isLoading}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									{language.Submit}
								</button>
							</Col>
						</Row>
					)}
				</Modal.Body>
			</div>
		</Modal>
	);
}
