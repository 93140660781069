import React from "react";
import { Spinner } from "react-bootstrap";

export default function CustomLoading({ height }) {
	return (
		<div style={{ display: "grid", placeItems: "center", height: `${height}vh` }}>
			<Spinner animation="border" role="status" style={{ color: "lightgray" }}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}
