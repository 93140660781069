import { lanChange } from "src/utils";
import HowItWorksOne from "../common/HowItWorksOne";
import SectionTitle from "../common/SectionTitle";

const HomeFiveProgress = () => {
	const language = lanChange();
	return (
		<div className="eduvibe-home-five-progress  edu-service-area edu-section-gap bg-image">
			<div className="container eduvibe-animated-shape">
				<div className="row">
					<div className="col-lg-12">
						<SectionTitle
							classes="text-center"
							slogan={language.home.legalizedWork.title}
							title={language.home.legalizedWork.subTitle}
						/>
					</div>
				</div>

				<HowItWorksOne />
			</div>
		</div>
	);
};

export default HomeFiveProgress;
