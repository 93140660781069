import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useGetResultQuery } from "src/redux/course/courseApi";
import { setQuestionView } from "src/redux/course/courseSlice";
import CustomLoading from "../common/CustomLoading";

export default function QuizViewForChapter({ chapter_id }) {
	const chapters = useSelector((state) => state.course?.subject?.chapter);

	const [chapter, setChapter] = React.useState(chapter_id);
	const lan = localStorage.getItem("lang");
	const { isLoading, data, refetch, isFetching } = useGetResultQuery({ chapter_id: chapter });
	const questions = useSelector((state) => state.course.questionView);

	const dispatch = useDispatch();
	useEffect(() => {
		if (data?.status === "fail") {
			dispatch(setQuestionView([]));
		}
		if (data?.status === "success") {
			dispatch(setQuestionView(data.data));
		}
	}, [isLoading, isFetching]);

	useEffect(() => {
		if (chapter) {
			refetch({ chapter_id: chapter });
		}
	}, [chapter]);

	const questionsItem =
		Array.isArray(questions) && questions?.length > 0
			? questions.map((item, index) => {
					return {
						id: item.id,
						sl: index + 1,
						text: lan === "en" ? item.title : item.title_bn,
						type: item.type,
						options: item.question_options.map((option) => {
							const ans = parseInt(option.is_right) ? true : false;
							const currentAns = item.user_submit_results?.answer_id === option.id;
							return {
								id: option.id,
								value: lan === "en" ? option.title : option.title_bn,
								weight: option.weight,
								is_right: option.is_right,
								checked: ans || currentAns ? true : false,
							};
						}),
					};
			  })
			: null;
	return (
		<>
			<form className="unselectable">
				<div style={{ padding: "20px 0px", backgroundColor: "#F4F9FF" }}>
					<div className="container">
						<Row className="justify-content-end align-items-center ">
							<Col sm={4}>
								{/* <h6 className="mb-0">{exam_info?.title || ""}</h6>
								<p className="m-0">Practice Quiz 6 min 30m per question 1 marks</p> */}
							</Col>

							<Col sm={2}>
								<select
									className="py-3"
									onChange={(e) => {
										setChapter(e.target.value);
									}}
									value={chapter}
									style={{ backgroundColor: "white" }}
								>
									{chapters?.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
								</select>
							</Col>
						</Row>
					</div>
				</div>
				{/* <div style={{ backgroundColor: "#F4F9FF", padding: "20px 0px" }}>
						<div className="container">
							<Row className="justify-content-between align-items-center ">
								<Col sm={12}>
									<h4 className="mb-0">Congratulations! You Passed!</h4>
									<h4 className="mb-0" style={{ color: "#f98a17" }}>
										Your Score: 80%
									</h4>
								</Col>
							</Row>
						</div>
					</div> */}

				{isLoading || isFetching ? (
					<CustomLoading height="60" />
				) : Array.isArray(questionsItem) && questionsItem?.length > 0 ? (
					<div className="container my-4">
						<div className="questions">
							{questionsItem.map((question, qIndex) => (
								<div key={question.id} style={{ contain: `${question.sl}` }}>
									<h6
										className="m-0"
										dangerouslySetInnerHTML={{ __html: question.text }}
									></h6>
									<div className="mt-4">
										{question.options.map((option, optIndex) => (
											<div key={option.id} className="mb-3 ms-4">
												<input
													type={
														question.type === "Multiple"
															? "checkbox"
															: "radio"
													}
													name={`${question.id}-${option.id}`}
													id={`${question.id}-${option.id}`}
													value={option.id}
													checked={option.checked}
													disabled={true}
												/>
												<label
													htmlFor={`${question.id}-${option.id}`}
													className={`ms-2  ${
														parseInt(option.is_right) && option?.checked
															? "mcq-view-label-green"
															: "mcq-view-label-red"
													}`}
													style={{ cursor: "pointer", color: "#000000" }}
												>
													{option.value}
												</label>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="container my-4">
						<div className="questions">
							<h6 className="mb-0 text-center">No Quiz Found!</h6>
						</div>
					</div>
				)}
			</form>
		</>
	);
}
