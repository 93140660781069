import {
	MDBCard,
	MDBCardBody,
	MDBCardImage,
	MDBCol,
	MDBRow,
	MDBTypography,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import profileImg from "src/assets/images/profile.svg";
import {
	useDiscussionReplySubmitMutation,
	useDiscussionSubmitMutation,
	useGetCourseDetailsQuery,
} from "src/redux/course/courseApi";
import { setCourse } from "src/redux/course/courseSlice";
import { getTimeDifference, lanChange, sortByDate } from "src/utils";
import CustomButton from "../common/CustomButton";
import CustomLoading from "../common/CustomLoading";

const Replay = ({ items, showReply, onReply, isLogin, refetch }) => {
	const [commentReplay, setCommentReplay] = useState(null);

	const [discussionReplySubmit, { isLoading, data }] = useDiscussionReplySubmitMutation();
	const language = lanChange();
	const onDiscussionSubmit = (e) => {
		e.preventDefault();
		if (!commentReplay) {
			toast.error("Please enter comment");
			return;
		}
		discussionReplySubmit(commentReplay);
	};

	const onChange = (id, value) => {
		setCommentReplay({
			discussion_id: id,
			comment: value,
		});
	};

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data?.message);
		}
		if (data?.status === "success") {
			toast.success(data?.message);
			setCommentReplay(null);
			refetch();
		}
	}, [isLoading]);

	return (
		<>
			{items.map((item, index) => {
				return (
					<MDBCol sm={12} key={item.id}>
						<div className="d-flex flex-start mt-4">
							<MDBCardImage
								className="rounded-circle shadow-1-strong me-3"
								src={item?.user?.profile_image || profileImg}
								alt="avatar"
								style={{
									width: 65,
									height: 65,
								}}
							/>

							<div className="flex-grow-1 flex-shrink-1">
								<div>
									<div className="d-flex justify-content-between align-items-center">
										<p className="mb-1">
											{item?.user?.name}{" "}
											<span className="small">
												- {getTimeDifference(item?.created_at)}
											</span>
										</p>
									</div>
									<p className="small mb-0">{item?.comment}</p>
									{/* {user ? (
										<button
											style={{
												backgroundColor: "transparent",
												border: "none",
											}}
											onClick={() => {
												onReply(item);
											}}
										>
											<span
												className="small"
												style={{ fontWeight: "bold", color: "gray" }}
											>
												<ChatsCircle style={{ marginRight: 2 }} /> Reply
											</span>
										</button>
									) : null} */}
								</div>
								{showReply?.[`${item.id}`] ? (
									<div className="py-3 border-0">
										<form onSubmit={onDiscussionSubmit}>
											<Row className="align-items-center">
												<Col sm={10} md={9} lg={10}>
													<input
														multiple
														type="text"
														placeholder="Write a comment..."
														id="textAreaExample"
														rows={2}
														className="py-3"
														style={{
															backgroundColor: "#fff",
															fontSize: "16px",
															borderRadius: 5,
														}}
														onChange={(e) =>
															onChange(item.id, e.target.value)
														}
													/>
												</Col>
												<Col sm={2} md={3} lg={2}>
													<button
														className="w-100 btn btn-primary py-3 mt-3 mt-md-0"
														style={{
															fontSize: "16px",
															fontWeight: "700",
															border: "none",
														}}
														type="submit"
													>
														{language.courseDetails.send}
													</button>
												</Col>
											</Row>
										</form>
									</div>
								) : null}

								{item.discussion_reply?.length ? (
									<Replay
										items={item.discussion_reply}
										onReply={onReply}
										showReply={showReply}
										refetch={refetch}
									/>
								) : null}
							</div>
						</div>
					</MDBCol>
				);
			})}
		</>
	);
};
export default function Comment() {
	const { id, course_id } = useParams();
	const user = useSelector((state) => state.auth.user);
	const items = useSelector((state) => state.course?.course?.course?.course_discussions);
	const [fullItems, setFullItems] = useState(3);

	const [showReply, setShowReply] = useState({});
	const [comment, setComment] = useState("");
	const course = useSelector((state) => state.course.course);
	const [discussionSubmit, { isLoading: isLoadingDiscussionSubmit, data: discussionSubmitRes }] =
		useDiscussionSubmitMutation();
	const { isSuccess, isLoading, data, refetch, isFetching } = useGetCourseDetailsQuery({
		course_id: id || course_id,
		user_id: user?.user?.id,
	});
	const dispatch = useDispatch();
	const onReply = (item) => {
		setShowReply({ ...showReply, [item.id]: !showReply[item.id] });
	};
	const language = lanChange();
	const onChange = (value) => {
		setComment(value);
	};

	const onDiscussionSubmit = (e) => {
		e.preventDefault();
		if (!comment) {
			toast.error("Please enter comment");
			return;
		}
		discussionSubmit({
			course_id: course.course.id,
			comment: comment,
		});
	};

	useEffect(() => {
		if (discussionSubmitRes?.status === "fail") {
			toast.error(discussionSubmitRes?.message);
		}
		if (discussionSubmitRes?.status === "success") {
			toast.success(discussionSubmitRes?.message);
			setComment("");
			refetch({
				course_id: id,
				user_id: user?.user?.id,
			});
		}
	}, [isLoadingDiscussionSubmit]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(setCourse(data));
		}
	}, [isLoading, isFetching]);

	if (!course) return <CustomLoading height="60" />;

	return (
		<section className="gradient-custom">
			{course?.is_enroll ? (
				<>
					<MDBCard style={{ border: "0px" }}>
						<MDBCardBody>
							<MDBTypography tag="h4" className=" mb-4 pb-2">
								{language.courseDetails.Discussions} ({items?.length})
							</MDBTypography>
							<div
								className="py-3 border-0"
								// style={{ backgroundColor: "#f8f9fa" }}
							>
								{user ? (
									<form onSubmit={onDiscussionSubmit}>
										<Row className="align-items-center justify-content-end">
											<Col sm={12} md={12} lg={12}>
												<textarea
													multiple
													row={10}
													type="text"
													placeholder={
														language.courseDetails
															.writeDownYourCommentHere
													}
													id="textAreaExample"
													rows={2}
													className="py-3"
													style={{
														backgroundColor: "#fff",
														fontSize: "16px",
														borderRadius: 5,
													}}
													wrapperClass="w-100"
													value={comment}
													onChange={(e) => onChange(e.target.value)}
												/>
											</Col>
											<Col sm={2} md={2} lg={2} className="text-end">
												<CustomButton
													disabled={isLoadingDiscussionSubmit}
													type="submit"
													className="btn my-4 mx-0"
													style={{
														backgroundColor: "#f98a17",
														color: "white",
														fontSize: 16,
														fontWeight: 600,
														padding: "10px 20px",
														width: "100%",
													}}
												>
													{language.courseDetails.send}
												</CustomButton>
											</Col>
										</Row>
									</form>
								) : null}
							</div>

							<MDBRow>
								{isLoading || isFetching ? (
									<CustomLoading height="60" />
								) : Array.isArray(items) && items.length > 0 ? (
									<Replay
										items={sortByDate([...items], "created_at").slice(
											0,
											fullItems,
										)}
										onReply={onReply}
										showReply={showReply}
										refetch={refetch}
									/>
								) : (
									<div className=" my-4">
										<div className="questions">
											<h6 className="mb-0 text-center">
												{language.courseDetails.NoDataFound}
											</h6>
										</div>
									</div>
								)}
							</MDBRow>
						</MDBCardBody>
					</MDBCard>
					{(items.length === fullItems) | (items.length === 0) ? null : (
						<CustomButton
							type="button"
							className="btn my-4 mx-0"
							style={{
								backgroundColor: "#E2ECFF",
								color: "#f98a17",
								fontSize: 16,
								fontWeight: 600,
								padding: "10px 20px",
							}}
							onClick={() => setFullItems(items.length)}
						>
							{language.courseDetails.btnMore}
						</CustomButton>
					)}
				</>
			) : (
				<div className="text-center py-5">
					<h6 className="mb-0">
						{language.courseDetails.PleaseEnrolltoseethediscussion}
					</h6>
				</div>
			)}
		</section>
	);
}
