import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsFillSendFill } from "react-icons/bs";
import DateField from "src/common/DateField";
import CustomButton from "src/components/common/CustomButton";
import SocialLoginButtons from "../NavBar/GoogleLoginBtn";
import OTP from "../NavBar/OTO";

export default function ModalAuth({ modal, step, setStep, ...props }) {
	const [isResendDisabled, setIsResendDisabled] = useState(true);
	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		if (modal.otp && modal.otp.length === 5) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [modal?.otp]);

	return (
		<Modal
			dialogClassName={`${modal.type}`}
			{...props}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			fullscreen="sm-down"
			backdrop="static"
		>
			<div
				className="container px-4"
				style={{
					height: window.innerWidth < 720 ? "100vh" : "auto",
					overflowY: "auto",
				}}
			>
				<Modal.Header closeButton>
					<div className="w-100">
						<div
							style={{ fontSize: "24px", fontWeight: 700, color: "black" }}
							id="contained-modal-title-vcenter"
							className="text-center"
						>
							{modal?.title}
						</div>
						<p
							id="contained-modal-title-vcenter"
							className="text-center"
							style={{ fontSize: "16px", fontWeight: 400, color: "gray" }}
						>
							{modal?.subTitle}
						</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="login-form-box">
						<form
							className={`login-form ${modal.type}`}
							onSubmit={modal.onSubmit}
							autoComplete="off"
						>
							{modal.type === "otp" ? (
								<>
									<label htmlFor="1">Enter OTP</label>
									<div className="mb-3 d-flex">
										{modal.fields?.map((field, i) => (
											<input key={field.id} {...field} />
										))}
									</div>
								</>
							) : (
								<>
									{modal.fields?.map((field, i) => (
										<div key={field.id} className={`input-box mb-4`}>
											{field.label && (
												<label htmlFor={field.id}>{field.label}</label>
											)}

											{field.type === "submit" ? (
												<CustomButton
													type="submit"
													disabled={field?.disabled}
													isLoading={field?.isLoading}
													style={{
														width: "100%",
														background: "#f98a17",
														padding: "20px",
														borderRadius: "5px",
													}}
													btnTextStyle={{
														fontSize: "16px",
														color: "white",
														fontWeight: 600,
													}}
												>
													{field.value}
												</CustomButton>
											) : (
												<>
													{field.type === "date" ? (
														<DateField {...field} />
													) : (
														<input {...field} />
													)}
												</>
											)}
										</div>
									))}
								</>
							)}

							<div className="input-box">
								{modal.type === "otp" ? (
									<OTP
										time={modal?.otp}
										isResendDisabled={isResendDisabled}
										setIsResendDisabled={setIsResendDisabled}
										resetOTPSubmit={modal.resetOTPSubmit}
									/>
								) : (
									<>
										{modal.footer && (
											<>
												<SocialLoginButtons
													type={modal.type}
													setSignUp={modal?.setSignUp}
													setUserInfo={modal?.setUserInfo}
												/>
												<p className="text-center">{modal.footer.title}</p>
												{modal.footer.subTitle && (
													<p className="text-center">
														{modal.footer.subTitle}
													</p>
												)}
											</>
										)}
									</>
								)}
							</div>
							{modal.type === "otp" && (
								<div
									className={`otp-submit mb-4  ${
										disabled || !isResendDisabled ? "otp-submit-disabled" : ""
									}`}
								>
									<CustomButton
										type={disabled || !isResendDisabled ? "button" : "submit"}
										disabled={modal?.disabled}
										style={{
											width: "100%",
											background: "#f98a17",
											padding: "20px",
											borderRadius: "5px",
										}}
										btnTextStyle={{
											fontSize: "16px",
											color: disabled || !isResendDisabled ? "gray" : "white",
											fontWeight: 600,
										}}
									>
										<BsFillSendFill className="me-4" />
										Submit OTP
									</CustomButton>
								</div>
							)}
						</form>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
}
