import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CourseTypeThree from "../common/CourseTypeThree";

const CourseItemsForFilter = () => {
	// const { filtered_courses: courses, searchbycourse, filters } = useFilterContext();
	const [data, setData] = useState([]);
	const courses = useSelector((state) => state.course.filter.courses);
	const [page, setPage] = useState(0);
	const coursesPerPage = 10;

	const paginate = (items) => {
		if (Array.isArray(items) && items.length !== 0) {
			const perPage = coursesPerPage;
			const numberOfPages = Math.ceil(items.length / perPage);

			const newCourses = Array.from({ length: numberOfPages }, (_, index) => {
				const start = index * perPage;
				return items.slice(start, start + perPage);
			});

			return newCourses;
		} else {
			return [];
		}
	};

	useEffect(() => {
		setData(paginate(courses));
	}, [courses]);

	const nextPage = () => {
		setPage((oldPage) => {
			let nextPage = oldPage + 1;
			if (nextPage > data.length - 1) {
				nextPage = 0;
			}
			return nextPage;
		});
	};

	const prevPage = () => {
		setPage((oldPage) => {
			let prevPage = oldPage - 1;
			if (prevPage < 0) {
				prevPage = data.length - 1;
			}
			return prevPage;
		});
	};

	const handlePage = (index) => {
		setPage(index);
	};

	// console.log(courses.length);

	// useEffect(() => {
	// 	setPage(0);
	// }, [searchbycourse, filters]);

	return (
		<>
			{/* <SortCourse showPerPage={ coursesPerPage } /> */}

			<Row className="row g-5">
				{data[page] !== undefined &&
					data[page].map((course) => {
						return (
							<Col key={course.id} sm={12} md={6} lg={4} style={{ height: "100%" }}>
								<CourseTypeThree data={course} />
							</Col>
						);
					})}
			</Row>

			{data.length > 1 && (
				<div className="row">
					<div className="col-lg-12 mt--60">
						<div className="nav">
							<ul className="edu-pagination">
								<li className="prev-btn">
									<button onClick={prevPage}>
										<i className="ri-arrow-drop-left-line"></i>
									</button>
								</li>
								{data.map((_, index) => {
									return (
										<li
											key={index}
											className={`page-item ${
												index === page ? " active" : ""
											}`}
										>
											<button onClick={() => handlePage(index)}>
												{index + 1}
											</button>
										</li>
									);
								})}
								<li className="next-btn">
									<button onClick={nextPage}>
										<i className="ri-arrow-drop-right-line"></i>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CourseItemsForFilter;
