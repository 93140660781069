import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { InnerPagesSliderParams2 } from "../../utils/script";

const LandingDemoInnerPages = ({ title, subTitle, items = [], bg = "" }) => {
	return (
		<div className={"edu-section-gap ".concat(bg)}>
			<div className="container eduvibe-animated-shape">
				<div className="row">
					<div className="col-lg-12 ">
						<ScrollAnimation
							animateIn="fadeInUp"
							animateOut="fadeInOut"
							className="pre-section-title text-center"
							animateOnce={true}
						>
							<span className="color-secondary pretitle">{title}</span>
							<h3 className="title">{subTitle}</h3>
						</ScrollAnimation>
					</div>
				</div>
			</div>

			<div
				className="g-5 mt--40"
				style={{
					maxWidth: "50%",
					margin: "0 auto",
				}}
			>
				<Slider className="landing-inner-pages brand" {...InnerPagesSliderParams2}>
					{items.map((item, index) => (
						<a key={index} href={item.url} target="_blank" rel="noreferrer">
							<img
								// className="w-100"
								src={item.src}
								alt={item.alt}
							/>
						</a>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default LandingDemoInnerPages;
