import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { lanChange } from "src/utils";
import SectionTitle from "../common/SectionTitle";

const CallToActionOne = ({ button = null }) => {
	const { contact } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();
	return (
		<div
			className="edu-newsletter-area bg-image newsletter-style-3 edu-section-gap bg-color-primary"
			style={{
				backgroundImage: "url(assets/images/support-bg.jpg)",
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className="container">
				<div className="row g-5 align-items-center">
					<div className="col-lg-8">
						<div className="inner">
							<SectionTitle
								classes="text-white text-start mb-3"
								slogan={language.home.contactUs.title}
								title={language.home.contactUs.subTitle}
							/>
						</div>
						<div className="contact-info ms-0">
							<a href={`tel:${contact?.phone || "+880 1879167052"}`}>
								<i className="icon-phone-line"></i>{" "}
								{contact?.phone || "+880 1879167052"}
							</a>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="newsletter-right-content d-block d-sm-flex align-items-center justify-content-start justify-content-lg-end">
							<div className="contact-btn">
								{button || (
									<Link className="edu-btn btn-white" to="/contact-us">
										{language.home.contactUs.btn}
										<i className="icon-arrow-right-line-right"></i>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CallToActionOne;
