import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// setup axios in query redux rtk query
const mainServiceApi = createApi({
	reducerPath: "api",
	refetchOnMountOrArgChange: true,
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,

		prepareHeaders: async (headers, { getState }) => {
			// const token = getState()?.user?.token;
			if (localStorage.getItem("lang") === null) {
				localStorage.setItem("lang", "en");
			}
			const lang = localStorage.getItem("lang");
			const token = localStorage.getItem("token");
			headers.set("Access-Control-Allow-Origin", "*");
			headers.set("lang", lang);
			if (token) {
				headers.set("Authorization", "Bearer " + token);
			}
			return headers;
		},
		validateStatus: (response, body) => {
			if (body.code === 401) {
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				window.location.href = "/";
				return false;
			} else {
				return true;
			}
		},
	}),

	tagTypes: [],

	endpoints: () => ({}),
});

export default mainServiceApi;
