import MyCertificates from "src/components/MyCertificates";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import { lanChange } from "src/utils";

export default function MyCertificatesPage() {
	const language = lanChange();
	return (
		<div>
			{" "}
			<BreadcrumbOne
				currentUrl={language.navbar.mycertificates}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			<MyCertificates />
		</div>
	);
}
