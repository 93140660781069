import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	audio: {
		show: false,
		title: "",
		src: "",
		buffered: 0,
		currentProgress: 0,
		duration: 0,
		isReady: false,
		playing: false,
		volume: 0.5,
	},
};

const audioSlice = createSlice({
	name: "audio",
	initialState,
	reducers: {
		setAudio: (state, action) => {
			state.audio = action.payload;
		},
	},
});

export const { setAudio } = audioSlice.actions;
export default audioSlice.reducer;
