import { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { BiTime } from "react-icons/bi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useExamSubmitMutation } from "src/redux/course/courseApi";
import CustomButton from "../common/CustomButton";
import ExamSuccessModal from "./ExamSuccessModal";

export default function Question({ exam_info, questionsItem = [], quizMin = 0, refetch }) {
	const { exam_id } = useParams();
	const [secondsRemaining, setSecondsRemaining] = useState(quizMin);
	const [questions, setQuestions] = useState(questionsItem);

	const [searchParams, setSearchParams] = useSearchParams();
	const type = searchParams.get("type");
	const course_id = searchParams.get("course_id");

	// useExamSubmitMutation
	const [examSubmit, { isLoading, data }] = useExamSubmitMutation();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const onHide = () => setShow(false);

	const [next, setNext] = useState(2);
	const [prev, setPrev] = useState(0);

	const onPrev = () => {
		setNext(next - 2);
		setPrev(prev - 2);
	};
	const onNext = () => {
		setNext(next + 2);
		setPrev(prev + 2);
	};

	useEffect(() => {
		if (secondsRemaining === 0) {
			setShow(true);
		}
		// Start the countdown timer
		const interval = setInterval(() => {
			if (type === "chapter_wise_question") {
				return () => clearInterval(interval);
			}
			if (secondsRemaining === 0) {
				return () => clearInterval(interval);
			}

			// If the timer has expired, clear the interval
			if (secondsRemaining === 0) {
				clearInterval(interval);
			} else {
				setSecondsRemaining(secondsRemaining - 1);
			}
		}, 1000);

		if (type === "chapter_wise_question") {
			// Clear the interval on unmount
			return () => clearInterval(interval);
		}

		// Clear the interval on unmount
		return () => clearInterval(interval);
	}, [secondsRemaining]);

	// Render a countdown 00.00 format
	const renderTime = () => {
		const minutes = Math.floor(secondsRemaining / 60);
		const seconds = secondsRemaining - minutes * 60;
		return `${minutes < 10 ? `0${minutes}` : minutes}:${
			seconds < 10 ? `0${seconds}` : seconds
		}`;
	};

	const onChange = (questionsId, optionId, type, checked) => {
		const newQuestions = JSON.parse(JSON.stringify(questions));
		const questionsIndex = newQuestions.findIndex((q) => q.id === questionsId);
		const optionIndex = newQuestions[questionsIndex].options.findIndex(
			(o) => o.id === optionId,
		);

		if (type === "Multiple") {
			newQuestions[questionsIndex].options[optionIndex].checked = checked;
		} else {
			newQuestions[questionsIndex].options[optionIndex].checked = checked;

			for (let i = 0; i < newQuestions[questionsIndex].options.length; i++) {
				if (i !== optionIndex) {
					newQuestions[questionsIndex].options[i].checked = false;
				}
			}
		}

		setQuestions(newQuestions);
	};

	function transformInput(input) {
		const output = {
			exam_id: exam_id,
			answers: input.map((question) => ({
				question_id: question.id,
				mcq_id: question.options
					.filter((option) => option.checked)
					.map((option) => option.id),
			})),
		};

		return output;
	}

	const onSubmit = (e = null) => {
		if (e) e.preventDefault();

		const mcq = transformInput(questions);
		examSubmit(mcq);
	};

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data?.message);
			if (show) {
				onHide();
			}
			if (course_id) {
				navigate(`/my-course/${course_id}`);
			} else {
				navigate(-1);
			}
		}
		if (data?.status === "success") {
			if (type !== "chapter_wise_question") {
				refetch();
			}

			setShow(true);

			// setQuestions([]);
			// if (course_id) {
			// 	navigate(`/my-course/${course_id}`);
			// } else {
			// 	navigate(-1);
			// }
			toast.success(data?.message);
		}
	}, [isLoading]);

	useEffect(() => {
		return () => setQuestions([]);
	}, []);

	return (
		<form onSubmit={onSubmit} className="unselectable">
			<div style={{ backgroundColor: "#F4F9FF", padding: "20px 0px" }}>
				<div className="container">
					<Row className="justify-content-between align-items-center ">
						<Col sm={8}>
							<h6 className="mb-0">{exam_info?.title}</h6>
							<p className="m-0">Per question 1 marks</p>
						</Col>
						{type !== "chapter_wise_question" && (
							<Col sm={4}>
								<h6 className="text-end m-0">
									Time Remaining:
									<Badge bg="success" className="p-4 ms-4">
										<BiTime
											className="me-2 -mt-1"
											style={{ fontSize: "20px", marginTop: -3 }}
										/>
										<span style={{ width: "50px", display: "inline-block" }}>
											{renderTime()}
										</span>
									</Badge>{" "}
								</h6>
							</Col>
						)}
					</Row>
				</div>
			</div>

			<div className="container my-4">
				<div className="questions">
					{questions.map((question, qIndex) => (
						<div key={question.id} style={{ contain: `${question.sl}` }}>
							<h6
								className="m-0"
								dangerouslySetInnerHTML={{
									__html: question.text,
								}}
							></h6>

							<div className="mt-4">
								{question.options.map((option, optIndex) => (
									<div key={option.id} className="mb-3 ms-4">
										<input
											type={
												question.type === "Multiple" ? "checkbox" : "radio"
											}
											name={question.id}
											id={`${question.id}-${option.id}`}
											value={option.id}
											checked={option.checked}
											onChange={(e) =>
												onChange(
													question.id,
													option.id,
													question.type,
													e.target.checked,
												)
											}
										/>
										<label
											htmlFor={`${question.id}-${option.id}`}
											className="ms-2"
											style={{ cursor: "pointer", color: "#000000" }}
										>
											{option.value}
										</label>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			<div style={{ backgroundColor: "#F4F9FF", padding: "20px 0px" }}>
				<div className="container">
					<Row className="align-items-center justify-content-between">
						<Col sm={2}>
							<button
								type="button"
								className="btn btn-outline-danger w-100 text-center mt-4"
								style={{
									fontWeight: "bold",
									fontSize: "14px",
									padding: "10px 30px",
								}}
								onClick={() => setShow(true)}
							>
								Cancel
							</button>
						</Col>

						<Col sm={2}>
							<CustomButton
								type="submit"
								className="btn btn-primary w-100 text-center mt-4"
								style={{
									fontWeight: "bold",
									fontSize: "14px",
									padding: "10px 30px",
								}}
								disabled={isLoading}
							>
								Submit
							</CustomButton>
						</Col>
					</Row>
				</div>
			</div>
			{show && (
				<ExamSuccessModal
					show={show}
					onHide={onHide}
					secondsRemaining={secondsRemaining}
					onSubmit={onSubmit}
					isLoading={isLoading}
					course_id={course_id}
					data={data}
				/>
			)}
		</form>
	);
}
