import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";
import "react-toastify/ReactToastify.min.css";
import App from "./App";
import Providers from "./components/Providers";
import ErrorBoundary from "./components/common/Error";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<ErrorBoundary>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
			<ParallaxProvider>
				<Provider store={store}>
					<Providers>
						<App />
					</Providers>
				</Provider>
			</ParallaxProvider>
		</GoogleOAuthProvider>
	</ErrorBoundary>,
);

reportWebVitals();
