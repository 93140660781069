import CallToActionOne from "src/components/Landing/CallToActionOne";
import Dashboard from "src/components/StudentDashboard/Dashboard";
import HomeFiveCourses from "src/components/StudentDashboard/HomeFiveCourses";
import WeOfferTheBest from "src/components/common/WeOfferTheBest";
import { lanChange } from "src/utils";

export default function StudentDashboard() {
	const language = lanChange();
	return (
		<>
			<Dashboard />
			<CallToActionOne
				button={
					<button className="edu-btn btn-white" to="/contact-us">
						{language.studentdashboard.bookNow}
						<i className="icon-arrow-right-line-right"></i>
					</button>
				}
			/>
			<HomeFiveCourses />

			<WeOfferTheBest />
		</>
	);
}
