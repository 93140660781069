import mainServiceApi from "../../endpoints/mainServiceApi";

export const testApi = mainServiceApi.enhanceEndpoints({ addTagTypes: ["test"] }).injectEndpoints({
	endpoints: (builder) => ({
		getTests: builder.query({
			query: () => ({
				url: "test",
				method: "GET",
				responseHandler: async (response) => {
					return await response.json();
				},
			}),

			providesTags: (result) => {
				return result
					? [
							...result.map(({ TestId }) => ({
								type: "test",
								id: TestId,
							})),
							{ type: "test", id: "LIST" },
					  ]
					: [{ type: "test", id: "LIST" }];
			},

			// ? Transform the result to prevent nested data
			transformResponse: (response) => {
				return response.test;
			},
		}),
		// ? Query: Get a single Test
		getTest: builder.query({
			query(id) {
				return `test/${id}`;
			},
			transformResponse: (response, args, meta) => response.data,
			providesTags: (result, error, id) => [{ type: "test", id }],
		}),
		// ? Query: Get a single Test
		searchTest: builder.query({
			query(q) {
				return `test/search?q=${q}`;
			},
			transformResponse: (response, args, meta) => response.test,
			providesTags: (result, error, id) => [{ type: "test", id }],
		}),
		// ? Mutation: Create a Test
		createTest: builder.mutation({
			query: (data) => {
				return {
					url: "test",
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: [{ type: "test", id: "LIST" }],
			transformResponse: (response) => {
				return response.Test;
			},
		}),
		// ? Mutation: Update Test
		updateTest: builder.mutation({
			query({ id, formData }) {
				return {
					url: `test/${id}`,
					method: "PATCH",
					body: formData,
				};
			},
			invalidatesTags: (result, error, { id }) =>
				result
					? [
							{ type: "test", id },
							{ type: "test", id: "LIST" },
					  ]
					: [{ type: "test", id: "LIST" }],
			transformResponse: (response) => response.Test,
		}),
		// ? Mutation: Delete Test
		deleteTest: builder.mutation({
			query(id) {
				return {
					url: `test/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: [{ type: "test", id: "LIST" }],
		}),
	}),
});

export const {
	useGetTestsQuery,
	useCreateTestMutation,
	useGetTestQuery,
	useSearchTestQuery,
	useDeleteTestMutation,
	useUpdateTestMutation,
} = testApi;
