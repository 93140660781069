import { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { FaImage } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import Slider from "react-slick";
import { isModalOpen, userModalType } from "src/redux/auth/authSlice";
import { lanChange } from "src/utils";
import { InnerPagesSliderParams4 } from "src/utils/script";

const CourseInfo = ({ data, setShow }) => {
	const [toggler, setToggler] = useState({
		isOpen: false,
		fileType: "",
		file: "",
	});
	const user = useSelector((state) => state.auth.user);
	const currentUrl = window.location.href;
	const language = lanChange();
	const dispatch = useDispatch();
	const regex = /https:\/\/www\.youtube\.com\/embed\/|\.jpg|\.png|\.jpeg|\.svg/;
	const regexYoutube = /https:\/\/www\.youtube\.com\/embed/;
	let files = data.course.course_features.filter((item) => {
		return regex.test(item.file_path);
	});
	files = files.map((item) => {
		if (regexYoutube.test(item.file_path)) {
			return {
				type: "youtube",
				file_path: item.file_path,
				image: data.course.featured_image,
			};
		} else {
			return {
				type: "image",
				file_path: item.file_path,
				image: item.file_path,
			};
		}
	});

	return (
		<div className="eduvibe-sidebar course-details-sidebar">
			<div className="inner">
				<div className="eduvibe-widget">
					<div className="video-area video-area-course ">
						<Slider className="landing-inner-pages brand" {...InnerPagesSliderParams4}>
							{files.map((item, index) => {
								const { image, type, file_path } = item;

								if (type === "youtube") {
									return (
										<div key={index} className="thumbnail video-popup-wrapper">
											<img
												src={image}
												alt="Course Video Thumb"
												style={{
													width: "405px",
													height: "209px",
												}}
											/>
											<button
												onClick={() =>
													setToggler({
														isOpen: true,
														file: file_path,
														fileType: "youtube",
													})
												}
												className="video-play-btn position-to-top video-popup-activation"
											>
												<span className="play-icon"></span>
											</button>
										</div>
									);
								} else {
									return (
										<div key={index} className="thumbnail video-popup-wrapper">
											<img
												key={index}
												src={image}
												alt="Course Video Thumb"
												style={{
													width: "405px",
													height: "209px",
												}}
											/>
											<button
												onClick={() =>
													setToggler({
														isOpen: true,
														file: image,
														fileType: "image",
													})
												}
												className="position-to-top video-popup-activation"
												style={{
													backgroundColor: "white",
													border: "none",
													borderRadius: "50%",
													padding: "15px",
												}}
											>
												<FaImage
													style={{ fontSize: 22, color: "#f98a17" }}
												/>
											</button>
										</div>
									);
								}
							})}
						</Slider>
						<Modal
							className="video-area-course-enroll-modal"
							show={toggler.isOpen}
							onHide={() =>
								setToggler({
									isOpen: false,
									fileType: "",
									file: "",
								})
							}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title"
							scrollable
							centered
						>
							<Modal.Header closeButton />
							<Modal.Body>
								<div>
									{toggler.fileType === "youtube" ? (
										<iframe
											width="100%"
											height="500"
											src={toggler.file}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
											style={{
												width: "89vw",
												height: "80vh",
											}}
										></iframe>
									) : (
										<img
											src={toggler.file}
											alt="Course Video Thumb"
											className="radius-small w-100"
											style={{
												width: "89vw",
												height: "80vh",
											}}
										/>
									)}
								</div>
							</Modal.Body>
						</Modal>
					</div>
					<div className="eduvibe-widget-details mt--50">
						<div className="widget-content">
							<ul>
								<div className="price-list">
									{data.course.special_price ? (
										<div className="d-flex align-items-center my-4">
											<div
												className="price current-price"
												style={{
													fontSize: 28,
													fontWeight: 700,
													color: "#f98a17",
													marginRight: 10,
												}}
											>
												৳ {data.course.special_price}
											</div>
											<div
												className="price old-price"
												style={{
													fontSize: 16,
													fontWeight: 500,
												}}
											>
												৳ {data.course.price}
											</div>
										</div>
									) : (
										<div className="price current-price my-4">
											{" "}
											৳ {data.course.price}
										</div>
									)}
								</div>
								{/* <p style={{ color: "#E34444" }}>
									<RiTimerLine style={{ marginRight: 10 }} />2 days left at this
									price!
								</p> */}
								<div className="read-more-btn">
									{data.is_enroll ? (
										<Row className="justify-content-between align-items-center">
											<Col md={6}>
												<Alert
													key={"success"}
													variant={"success"}
													className="mb-4 text-center"
												>
													{language.courseDetails.CourseInfo.Enrolled}
												</Alert>
											</Col>
											<Col md={6}>
												<Alert
													key={"success"}
													// variant={"primary"}
													className="mb-4 text-center w-100 go-to-course"
												>
													<Link
														to={`/my-course/${data?.course?.id}`}
														style={{
															color: "inherit",
														}}
													>
														{
															language.courseDetails.CourseInfo
																.GoToCourse
														}
													</Link>
												</Alert>
											</Col>
										</Row>
									) : (
										<button
											className="edu-btn btn-bg-alt w-100 text-center"
											onClick={() => {
												if (user) {
													setShow(true);
												} else {
													dispatch(isModalOpen(true));
													dispatch(userModalType("login"));
												}
											}}
										>
											{language.courseDetails.CourseInfo.enrollNow}
										</button>
									)}
								</div>
								{data.course.duration && (
									<li>
										<span>
											<i className="icon-time-line"></i>{" "}
											{language.courseDetails.CourseInfo.Duration}
										</span>
										<span>{data.course.duration}</span>
									</li>
								)}
								{data.total_enroll && (
									<li>
										<span>
											<i className="icon-user-2"></i>{" "}
											{language.courseDetails.CourseInfo.Enrolled}
										</span>
										<span>{data.total_enroll}</span>
									</li>
								)}
								{data.total_lession && (
									<li>
										<span>
											<i className="icon-draft-line"></i>{" "}
											{language.courseDetails.CourseInfo.Lectures}
										</span>
										<span>{data.total_lession}</span>
									</li>
								)}

								{data.total_quiz ? (
									<li>
										<span>
											<i className="icon-artboard-line"></i>{" "}
											{language.courseDetails.CourseInfo.Quizzes}
										</span>
										<span>{data.total_quiz}</span>
									</li>
								) : null}
								{data.total_mcq ? (
									<li>
										<span>
											<i className="icon-artboard-line"></i>{" "}
											{language.courseDetails.CourseInfo.TotalModelTest}
										</span>
										<span>{data.total_mcq}</span>
									</li>
								) : null}
								<li>
									<span>
										<i className="icon-artboard-line"></i>{" "}
										{language.courseDetails.CourseInfo.WrittenExam}
									</span>
									<span>{data?.total_written}</span>
								</li>
								<li>
									<span>
										<i className="icon-artboard-line"></i>
										{language.courseDetails.CourseInfo.FlashCards}
									</span>
									<span>{data?.total_flash_card}</span>
								</li>
								{/* {data.total_written ? (
									<li>
										<span>
											<i className="icon-artboard-line"></i> Written
										</span>
										<span>{data.total_written}</span>
									</li>
								) : null} */}
								{/* {data.certificate && (
									<li>
										<span>
											<i className="icon-award-line"></i> Certificate
										</span>
										<span>
											{data.certificate === "available" ? "Yes" : "No"}
										</span>
									</li>
								)} */}
								{/* {data.passPercentage && (
									<li>
										<span>
											<img
												className="eduvibe-course-sidebar-img-icon"
												src="/images/icons/percent.svg"
												alt="icon Thumb"
											/>
											Pass Percentage
										</span>
										<span>{data.passPercentage}%</span>
									</li>
								)} */}
								{data.access_period && (
									<li>
										<span>
											<i className="icon-calendar-2-line"></i>{" "}
											{language.courseDetails.CourseInfo.AccessPeriod}
										</span>
										<span>{data.access_period}</span>
									</li>
								)}
								{/* {data.course.course_instructor.length > 0 ? (
									<li>
										<span>
											<i className="icon-user-2-line_tie"></i> Instructor
										</span>
										<span>{data.course.course_instructor.length}</span>
									</li>
								) : null} */}
							</ul>

							<div className="read-more-btn mt--30 text-center">
								<div className="eduvibe-post-share">
									<span>{language.courseDetails.CourseInfo.Share}: </span>
									<LinkedinShareButton url={currentUrl} className="linkedin me-4">
										<LinkedinIcon fill="gray" size={30} />
									</LinkedinShareButton>
									<FacebookShareButton
										url={currentUrl}
										facebook
										className="linkedin me-4"
									>
										<FacebookIcon fill="gray" size={30} />
									</FacebookShareButton>
									<TwitterShareButton url={currentUrl} className="linkedin me-4">
										<TwitterIcon fill="gray" size={30} />
									</TwitterShareButton>
									<WhatsappShareButton url={currentUrl} className="youtube me-4">
										<WhatsappIcon fill="gray" size={30} />
									</WhatsappShareButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CourseInfo;
