import About from "src/components/About/About";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import SEO from "src/components/common/SEO";
import { lanChange } from "src/utils";

export default function AboutPage() {
	const language = lanChange();
	return (
		<>
			<SEO title={language.home.aboutUs.title} />
			{/* <Layout> */}
			<BreadcrumbOne
				title={language.home.aboutUs.title}
				rootUrl="/"
				parentUrl={language.category.breadcrumb.parent}
				currentUrl={language.home.aboutUs.title}
			/>

			<About />
			{/* </Layout> */}
		</>
	);
}
