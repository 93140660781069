import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useContactUsMutation } from "src/redux/HomePageInfo/homeApi";
import { lanChange } from "src/utils";
import CustomButton from "../common/CustomButton";

const Result = ({ message }) => {
	return <p className="success-message">{message}</p>;
};

const Contact = {
	name: "",
	email: "",
	phone: "",
	subject: "",
	message: "",
};

function ContactUsForm({ formStyle }) {
	const [contact, setContact] = useState(Contact);
	const [contactUs, { isSuccess, isLoading, isError, data }] = useContactUsMutation();
	const language = lanChange();
	const sendEmail = async (e) => {
		e.preventDefault();
		await contactUs(contact);
	};

	const onChange = (e) => {
		setContact({ ...contact, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data.message);
		}
		if (data?.status === "success") {
			toast.success(data.message);
			setContact(Contact);
		}
	}, [isLoading]);

	return (
		<form className={`${formStyle}`} onSubmit={sendEmail}>
			<div className="col-lg-12">
				<div className="form-group">
					<input
						type="text"
						className="form-control form-control-lg"
						name="name"
						placeholder={language.ContactUs.form.name}
						onChange={onChange}
						value={contact.name}
						required
					/>
				</div>
			</div>

			<div className="col-lg-12">
				<div className="form-group">
					<input
						type="email"
						className="form-control form-control-lg"
						name="email"
						placeholder={language.ContactUs.form.email}
						required
						onChange={onChange}
						value={contact.email}
					/>
				</div>
			</div>

			<div className="col-lg-12">
				<div className="form-group">
					<input
						type="text"
						className="form-control form-control-lg"
						name="phone"
						placeholder={language.ResetYourPassword.MobileNo}
						onChange={onChange}
						required
						value={contact.phone}
					/>
				</div>
			</div>

			<div className="col-lg-12">
				<div className="form-group">
					<input
						type="text"
						className="form-control form-control-lg"
						name="subject"
						placeholder={language.ContactUs.form.subject}
						onChange={onChange}
						required
						value={contact.subject}
					/>
				</div>
			</div>
			<div className="col-lg-12">
				<div className="form-group">
					<textarea
						className="form-control"
						name="message"
						placeholder={language.ContactUs.form.message}
						onChange={onChange}
						required
						value={contact.message}
					></textarea>
				</div>
			</div>
			<div className="col-lg-12">
				<CustomButton
					type={"submit"}
					disabled={isLoading}
					style={{
						width: "100%",
						background: "#f98a17",
						padding: "20px",
						borderRadius: "5px",
					}}
					btnTextStyle={{
						fontSize: "16px",
						color: isLoading ? "gray" : "white",
						fontWeight: 600,
					}}
				>
					<i className="icon-arrow-right-line-right me-4"></i>
					{language.ContactUs.form.requestBtn}
				</CustomButton>
			</div>
		</form>
	);
}
export default ContactUsForm;
