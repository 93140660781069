import React from "react";
import { Spinner } from "react-bootstrap";

export default function CustomButton({
	children,
	type,
	onClick,
	style = {},
	btnTextStyle = {},
	disabled = false,
	className = "",
}) {
	return (
		<button
			className={className}
			type={type}
			disabled={disabled}
			onClick={onClick}
			style={{
				border: "none",
				textAlign: "center",
				padding: "10px 30px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...style,
			}}
		>
			{disabled && (
				<Spinner
					animation="border"
					role="status"
					style={{ color: "white" }}
					className="me-4"
				>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			)}
			<span
				style={{
					...btnTextStyle,
				}}
			>
				{children}
			</span>
		</button>
	);
}
