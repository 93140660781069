import { Link, useSearchParams } from "react-router-dom";
import { lanChange } from "src/utils";

export default function MCQ({ item }) {
	const [searchParams, setSearchParams] = useSearchParams();

	// console.log({ item });
	const language = lanChange();
	return (
		<div>
			<h6 style={{ fontSize: "20px" }}>{item?.name}</h6>

			{/* <p style={{ fontSize: "14px", fontWeight: "bold" }}>Practice Quiz: {item?.duration}</p> */}
			<div className="d-flex align-items-center ">
				<Link
					to={`/quiz/${
						item.id
					}?type=chapter_wise_question&chapter_type=${searchParams.get("chapter_type")}`}
					style={{
						padding: "10px 20px",
						backgroundColor: "#f98a17",
						color: "white",
						fontWeight: 500,
						border: "none",
						borderRadius: "4px",
						marginRight: "15px",
					}}
				>
					{language.subjectAndChapter.StartQuiz}
				</Link>
				{/* <Link
					to={`/my-course/${course_id}/subject/${subject_id}/chapters/${chapter_id}/quiz-view/${item.chapter_id}?lesson_id=${lesson_id}`}
					style={{
						padding: "10px 20px",
						backgroundColor: "#f98a17",
						color: "white",
						fontWeight: 500,
						border: "none",
						borderRadius: "4px",
						marginRight: "15px",
					}}
				>
					View result
				</Link> */}
			</div>

			{/* <p style={{ fontSize: "14px", fontWeight: "bold", color: "black", margin: "24px 0px" }}>
				Your Score: - {item?.score}
			</p> */}
		</div>
	);
}
