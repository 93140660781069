import { useSelector } from "react-redux";
import Courses from "src/components/Course/Courses";
import HomeTwoTestimonial from "src/components/Course/HomeTwoTestimonial";
import HomeFourTeam from "src/components/Landing/HomeFourTeam";
import LandingDemoInnerPages from "src/components/Landing/LandingDemoInner";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import SEO from "src/components/common/SEO";
import { lanChange } from "src/utils";

export default function CoursePage() {
	const language = lanChange();

	const { corporatePartner } = useSelector((state) => state.homePageInfo.home);
	const corporatePartners = corporatePartner?.map((item, i) => ({
		id: i,
		src: item?.partner_pic,
		alt: item.name,
		url: item.url,
	}));
	return (
		<>
			<SEO title={language.category.breadcrumb.title} />
			<BreadcrumbOne
				title={language.category.breadcrumb.title}
				rootUrl="/"
				parentUrl={language.category.breadcrumb.parent}
				currentUrl={language.category.breadcrumb.current}
			/>
			<Courses />
			{Array.isArray(corporatePartners) && corporatePartners.length > 0 ? (
				<LandingDemoInnerPages
					title={language.BRANDS}
					subTitle={language.OurTrustedPartners}
					bg="bg-color-light-blue"
					items={[...corporatePartners]}
				/>
			) : null}
			<HomeFourTeam
				slogan={language.home.skilledProfessionals.title}
				title={language.home.skilledProfessionals.subTitle}
			/>
			{/* <HomeFourTeam slogan={language.courses} title="Our Talented Course Instructors" /> */}
			<HomeTwoTestimonial />
		</>
	);
}
