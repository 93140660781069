import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BsPlayCircleFill, BsStopCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setAudio } from "src/redux/audio/audioSlice";
import { useHighCourtQuestionDetailsQuery } from "src/redux/course/courseApi";
import { lanChange } from "src/utils";
import BreadcrumbOne from "../common/BreadcrumbOne";
import Loading from "../common/Loading";
export default function QuestionsAnswer() {
	const [zoom, setZoom] = useState(1);
	const audio1 = useSelector((state) => state.audio.audio);
	const [isDragging, setIsDragging] = useState(false);
	const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
	const [position, setPosition] = useState({ x: 0, y: 0 });

	const { exam_id } = useParams();
	const dispatch = useDispatch();
	const [toggler, setToggler] = useState({
		isOpen: false,
		fileType: "image",
		file: "",
	});
	const { isLoading, data } = useHighCourtQuestionDetailsQuery({
		exam_id,
	});
	const onClickImg = (e) => {
		setToggler({
			isOpen: true,
			fileType: "image",
			file: e.target.src,
		});
	};
	// add event listener to on click img
	useEffect(() => {
		if (data?.data?.answer) {
			const images = document.querySelectorAll("#answer img");
			images.forEach((img) => {
				img.addEventListener("click", onClickImg);
				img.classList.add("zoom-img");
			});

			return () => {
				images.forEach((img) => {
					img.addEventListener("click", onClickImg);
					img.classList.add("zoom-img");
				});
			};
		}
	}, [isLoading, data]);
	useEffect(() => {
		// setAudio(null);
		dispatch(
			setAudio({
				...audio1,
				src: null,
				title: "",
				buffered: 0,
				currentProgress: 0,
				duration: 0,
				isReady: false,
				playing: false,
			}),
		);

		if (data?.data?.file_path) {
			dispatch(
				setAudio({
					...audio1,
					src: data?.data?.file_path,
					title: "Audio",
					show: false,
					buffered: 0,
					currentProgress: 0,
					duration: 0,
					isReady: false,
					playing: false,
				}),
			);
		}
	}, [data]);
	if (isLoading) {
		return <Loading />;
	}
	const language = lanChange();

	// console.log(data);
	return (
		<div
			style={{
				minHeight: "100vh",
			}}
		>
			<BreadcrumbOne
				currentUrl={language.QuestionAndAnswer}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			<div className="container">
				<div className="card my-3">
					<div className="card-body">
						<p
							className="card-text mb-2 "
							style={{ color: "black", fontWeight: 600, fontSize: 18 }}
						>
							{language.Question}:
						</p>
						<p
							className="card-text mp-0"
							style={{ color: "black" }}
							dangerouslySetInnerHTML={{ __html: data?.data?.question }}
						></p>
					</div>
				</div>
				<div className="card my-3">
					<div className="card-body">
						<p
							className="card-text mb-2 "
							style={{ color: "black", fontWeight: 600, fontSize: 18 }}
						>
							{language.Answer}:
						</p>

						{data?.data?.video_url && (
							<iframe
								width="560"
								height="560"
								src={data?.data?.video_url}
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
								style={{
									marginBottom: 20,
								}}
							></iframe>
						)}

						<p
							id="answer"
							className="card-text mb-2 mp-0 get-img"
							style={{ color: "black" }}
							dangerouslySetInnerHTML={{ __html: data?.data?.answer }}
						></p>
					</div>
				</div>
				<div
					style={{
						position: "fixed",
						right: 0,
						bottom: 0,
						width: "100%",
						zIndex: 9999,
					}}
				>
					<div className="w-100">
						<Row
							className="justify-content-end align-items-center"
							style={{ marginTop: 30, marginBottom: 30 }}
						>
							<Col sm={4}></Col>

							{data?.data?.file_path && (
								<Col sm={1} style={{ height: "5vh" }}>
									{audio1.show ? (
										<BsStopCircleFill
											role="button"
											style={{
												fontSize: 45,
												color: "#f98a17",
												backgroundColor: "white",
												// padding: 5,
												borderRadius: 50,
												boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
											}}
											onClick={() =>
												dispatch(
													setAudio({
														...audio1,
														show: false,
														src: null,
														title: "",
													}),
												)
											}
										/>
									) : (
										<BsPlayCircleFill
											role="button"
											style={{
												fontSize: 45,
												color: "#f98a17",
												backgroundColor: "white",
												// padding: 5,
												borderRadius: 50,
												boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
											}}
											onClick={() => {
												dispatch(
													setAudio({
														...audio1,
														show: true,
														src: data?.data?.file_path,
														title: "Audio",
													}),
												);
											}}
										/>
									)}
								</Col>
							)}
						</Row>
					</div>
				</div>
			</div>
			<Modal
				className="modal-custom"
				show={toggler.isOpen}
				onHide={() => {
					setToggler({
						isOpen: false,
						fileType: "",
						file: "",
					});
					// reset all state
					setZoom(1);
					setIsDragging(false);
					setInitialPosition({ x: 0, y: 0 });
					setPosition({ x: 0, y: 0 });
				}}
				fullscreen={true}
				aria-labelledby="example-custom-modal-styling-title"
				centered
				scrollable
			>
				<Modal.Header closeButton />
				<Modal.Body>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							onWheel={(e) => {
								e.preventDefault();
								const scale = e.deltaY < 0 ? 1.1 : 0.9;

								setZoom((prev) => prev * scale);
							}}
							onMouseDown={(e) => {
								setIsDragging(true);
								setInitialPosition({ x: e.clientX, y: e.clientY });
							}}
							onMouseUp={() => {
								setIsDragging(false);
							}}
							onMouseMove={(e) => {
								if (isDragging) {
									e.preventDefault();
									setPosition((prev) => {
										const newX = prev.x + (e.clientX - initialPosition.x);
										const newY = prev.y + (e.clientY - initialPosition.y);

										// Set the limits for the image movement
										const minX = -100;
										const maxX = 100;
										const minY = -300;
										const maxY = 300;

										return {
											x: Math.max(minX, Math.min(maxX, newX)),
											y: Math.max(minY, Math.min(maxY, newY)),
										};
									});
									setInitialPosition({ x: e.clientX, y: e.clientY });
								}
							}}
							src={toggler.file}
							alt="img"
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
								transition: "transform 0.3s ease", // Add this line
								cursor: isDragging ? "grabbing" : "grab",
								transformOrigin: "center", // Change this line
							}}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
