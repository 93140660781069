import { MDBCheckbox } from "mdb-react-ui-kit";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCourseFilter } from "src/redux/course/courseSlice";

export default function RatingFilter() {
	const dispatch = useDispatch();
	const { filter = {} } = useSelector((state) => state.course);

	const onChange = (id, value) => {
		const newRating = filter.rating.map((item) => {
			if (item.id === id) {
				return { ...item, checked: value };
			} else {
				return { ...item };
			}
		});
		dispatch(setCourseFilter({ ...filter, rating: newRating }));
	};
	return (
		<>
			{filter.rating.map((item) => (
				<Row key={item.id} className="justify-content-between mb-2">
					<Col sm={12}>
						<MDBCheckbox
							name={`rating`}
							id={`rating-${item.id}`}
							value={`rating-${item.id}`}
							label={
								<>
									<i class="icon-Star me-3"></i>
									{item.label}
								</>
							}
							checked={item.checked}
							onChange={(e) => onChange(item.id, e.target.checked)}
						/>
					</Col>
					{/* <Col sm={2}>300</Col> */}
				</Row>
			))}
		</>
	);
}
