import React from "react";
import { BsStar } from "react-icons/bs";

import { BsStarFill } from "react-icons/bs";

export default function Feedback({ rating, setRating }) {
	const handleMouseEnter = (index) => {
		setRating(index + 1);
	};

	const handleClick = (index) => {
		setRating(index + 1);
	};

	return (
		<div>
			<div className="rating eduvibe-course-rating-stars text-center mb-4">
				{Array.from({ length: 5 }).map((_, index) => (
					<span
						key={index}
						onMouseEnter={() => handleMouseEnter(index)}
						onClick={() => handleClick(index)}
					>
						{index < rating ? (
							<BsStarFill color="#FFA41B" style={{ fontSize: 40, marginRight: 10 }} />
						) : (
							<BsStar color="#FFA41B" style={{ fontSize: 40, marginRight: 10 }} />
						)}
					</span>
				))}
			</div>
		</div>
	);
}
