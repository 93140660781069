import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { useChapterWiseFlashCardQuery } from "src/redux/course/courseApi";
import { setChapterWiseFlashCard } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";
import { ThreeColumnCarousel7 } from "src/utils/script";
import CustomLoading from "../common/CustomLoading";

export default function Flashcards({ item }) {
	const { isLoading, data } = useChapterWiseFlashCardQuery({ chapter_id: item.chapter_id });
	const dispatch = useDispatch();
	const cardsRef = useRef(null);
	const lan = localStorage.getItem("lang");
	const handleClick = useCallback((event) => {
		const card = event.target.closest(".card");
		if (card) {
			card.classList.toggle("is-flipped");
		}
	}, []);
	const language = lanChange();

	useEffect(() => {
		if (data?.status === "success") {
			dispatch(setChapterWiseFlashCard(data));
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && cardsRef.current) {
			const cards = cardsRef.current.querySelectorAll(".card");
			[...cards].forEach((card) => {
				card.addEventListener("click", handleClick);
			});
			return () => {
				[...cards].forEach((card) => {
					card.removeEventListener("click", handleClick);
				});
			};
		}
	}, [isLoading, handleClick]);
	// console.log(data);

	if (isLoading) return <CustomLoading height={30} />;
	return (
		<div ref={cardsRef}>
			<h6 className="mb-2 text-center">{language.subjectAndChapter.FlashCard}</h6>
			<p className="text-center m-0">{language.subjectAndChapter.FlipToCheckAnswers}</p>
			<div className="flashcards row g-5 mt--10 justify-content-center">
				<div className="col-lg-5  mx-4" style={{ height: "60vh" }}>
					<Slider
						className="slick-activation-wrapper course-activation-3 edu-slick-button"
						{...ThreeColumnCarousel7}
					>
						{Array.isArray(data) &&
							data.map((item, index) => (
								<div class="scene scene--card" key={index}>
									<div class="card" style={{ border: "0px solid red" }}>
										<div
											class="card__face card__face--front"
											style={{
												border: "0px solid transparent",
												borderRadius: 15,
												overflow: "auto",
											}}
										>
											<div className="p-4">
												<h6
													className="title"
													style={{
														color: "white",
														marginBottom: 0,
														textAlign: "center",
														backgroundColor: "#1043C3",
														width: "50%",
														padding: "12px 16px",
														borderRadius: 8,
														fontWeight: "700",
														fontSize: "16px",
													}}
												>
													{language.subjectAndChapter.Question}{" "}
													{index + 1}
												</h6>
												<p
													className="description"
													style={{
														color: "white",
														textAlign: "left",
														margin: "30px 0px",
														fontSize: 24,
													}}
												>
													{lan === "en"
														? item.question
														: item.question_bn}
												</p>
											</div>
										</div>
										<div
											class="card__face card__face--back"
											style={{
												border: "0px solid transparent",
												borderRadius: 15,
												overflow: "auto",
											}}
										>
											<div className="p-4">
												<h6
													className="title"
													style={{
														color: "white",
														marginBottom: 0,
														textAlign: "center",
														backgroundColor: "#0E9C24",
														width: "50%",
														padding: "12px 16px",
														borderRadius: 8,
														fontWeight: "700",
														fontSize: "16px",
													}}
												>
													{language.subjectAndChapter.Answer} {index + 1}
												</h6>
												<p
													className="description"
													style={{
														color: "black",
														textAlign: "left",
														margin: "30px 0px",
														fontSize: 24,
													}}
												>
													{lan === "en" ? item.answer : item.answer_bn}
												</p>
											</div>
										</div>
									</div>
								</div>
							))}
					</Slider>
				</div>
			</div>
		</div>
	);
}
