import React from "react";
import Slider from "react-slick";
import { InnerPagesSliderParams3 } from "../../utils/script";

const LandingDemoInnerPagesFooter = ({ title, subTitle, items = [], bg = "" }) => {
	return (
		<div>
			<div
				className=" footer"
				style={{
					display: "block",
					width: "100vw",
					margin: "auto",
				}}
			>
				<Slider className="landing-inner-pages" {...InnerPagesSliderParams3}>
					{items.map((item, index) => (
						<a key={index} href={item.url} target="_blank" rel="noreferrer">
							<img className="w-100 footer-banner" src={item.src} alt={item.alt} />
						</a>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default LandingDemoInnerPagesFooter;
