import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as BookIcon } from "src/assets/images/book.svg";
import { ReactComponent as CompleteIcon } from "src/assets/images/complete.svg";
import { ReactComponent as FileIcon } from "src/assets/images/file.svg";
import { ReactComponent as MediaIcon } from "src/assets/images/media.svg";
import TabChapters from "./TabChapters";

export const Icon = {
	Text: <BookIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Exam: <BookIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Audio: <MediaIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Video: <MediaIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	FlashCard: <FileIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	file: <FileIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	complete: <CompleteIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
};

export default function TabForChapters({ defaultTab = "", tab = {} }) {
	const [contentTab, setContentTab] = useState(defaultTab);

	return (
		<div className="container" style={{ marginBottom: "20px" }}>
			<Row className="course-details-content accordion-for-subjects mt-4">
				<Col sm={3}>
					<div
						className="accordion-for-items"
						style={{ border: "1px solid lightgray", borderRadius: 5 }}
					>
						{Object.keys(tab).length ? (
							<TabChapters
								title={tab.title}
								tab={tab.chapters}
								Icon={Icon}
								contentTab={contentTab}
								setContentTab={setContentTab}
								comeFrom="topics"
							/>
						) : null}
					</div>
				</Col>
				<Col sm={9}>
					<div className="tab-content">
						{Object.keys(tab.chapters).length
							? tab.chapters.map((item) => {
									if (item.id === contentTab) {
										return (
											<div
												key={item.id}
												className={`tab-pane fade show ${
													contentTab === item.id ? "active" : ""
												} `}
											>
												<div>{item.content}</div>
											</div>
										);
									}
									return null;
							  })
							: null}
					</div>
				</Col>
			</Row>
		</div>
	);
}
