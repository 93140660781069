import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNewsLetterMutation } from "../../redux/HomePageInfo/homeApi";
import { lanChange } from "../../utils";

export default function SldierArea() {
	const { banner } = useSelector((state) => state.homePageInfo.home);
	const user = useSelector((state) => state.auth.user);
	const [email, setEmail] = React.useState("");

	const [newsLetter, { isSuccess, data }] = useNewsLetterMutation();
	const language = lanChange();
	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};
	const sendEmail = async (e) => {
		e.preventDefault();

		if (!validateEmail(email)) {
			toast.error("Please enter a valid email address");
			return;
		}

		await newsLetter({ email });
	};
	const onChange = (e) => {
		setEmail(e.target.value);
	};

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data?.message);
			setEmail("");
		}
		if (data?.status === "success") {
			toast.success(data?.message);
			setEmail("");
		}
	}, [isSuccess]);
	return (
		<div
			class="slider-area banner-style-4 bg-image pb-5 "
			style={{
				backgroundImage: "url(assets/images/bg-1.svg)",
				backgroundSize: "cover",
				paddingTop: user ? "50px" : "150px",
			}}
		>
			<div
				class="wrapper d-flex align-items-center home-four-banner-wrapper mb-5"
				style={{ minHeight: "100%" }}
			>
				<div class="container eduvibe-animated-shape pt-5">
					<div class="row g-5 align-items-center">
						<div class="col-lg-6 order-2 order-lg-1">
							<div class="inner mt_md--10 mt_sm--10">
								<div class="content text-start">
									<ScrollAnimation
										animateIn="fadeInUp"
										animateOut="fadeInOut"
										animateOnce={true}
										duration={0.8}
										delay={0.2}
										initiallyVisible={true}
									>
										<span
											class="pre-title"
											dangerouslySetInnerHTML={{
												__html: banner?.pre_title || "",
											}}
										></span>
									</ScrollAnimation>
									<ScrollAnimation
										animateIn="fadeInUp"
										animateOut="fadeInOut"
										animateOnce={true}
										duration={1}
										delay={0.5}
										initiallyVisible={true}
									>
										<h1 class="title">{banner?.title || ""}</h1>
									</ScrollAnimation>
									<ScrollAnimation
										animateIn="fadeInUp"
										animateOut="fadeInOut"
										animateOnce={true}
										className="description mb-5"
										duration={1}
										delay={0.5}
										initiallyVisible={true}
									>
										<p
											className="exam-details-accordion-body p-0 m-0"
											dangerouslySetInnerHTML={{
												__html: banner?.discription || "",
											}}
										/>
									</ScrollAnimation>

									<div class="newsletter-form newsletter-form-style-1 mb-5">
										<div class="input-box">
											<form onSubmit={sendEmail}>
												<input
													type="email"
													placeholder={
														language.home.banner.enterYourMailAddress
													}
													class="ps-10"
													value={email}
													onChange={onChange}
												/>
												<div class="mail-icon">
													<i class="icon-mail-open-line"></i>
												</div>
												<button class="send-button" type="submit">
													<i class="icon-send-plane-fill"></i>
												</button>
											</form>
										</div>
									</div>
									<ScrollAnimation
										animateIn="fadeInUp"
										animateOut="fadeInOut"
										animateOnce={true}
										duration={1}
										delay={0.5}
										initiallyVisible={true}
									>
										<Link class="edu-btn" to="/download-app">
											{language.home.banner.getStartedToday}
											<i class="icon-arrow-right-line-right"></i>
										</Link>
									</ScrollAnimation>
								</div>
							</div>
						</div>

						<div class="col-lg-6 order-1 order-lg-2">
							<div class="banner-image" style={{ padding: "22px", width: "75%" }}>
								<div class="thumbnail">
									<img src={banner?.file_path} alt="Banner" />
								</div>
								<div class="round-images">
									<img
										src="assets/images/shapes/circle-shape-2.svg"
										alt="Shape Images"
									/>
								</div>
								<div class="learner-badge">
									<div class="badge-inner">
										<span class="viewer">28K</span>
										<span>Enrolled</span>
										<span>Learner</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
