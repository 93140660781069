import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useChapterLessonContentQuery } from "src/redux/course/courseApi";
import { setChapter } from "src/redux/course/courseSlice";

export default function useChapter({ lession_id, lession_type }) {
	const { isLoading, data } = useChapterLessonContentQuery({
		lession_id,
		lession_type,
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (data?.status === "fail") {
			// console.log(data);
		}
		if (data?.status === "success") {
			dispatch(setChapter(data.data));
		}
	}, [isLoading]);
}
