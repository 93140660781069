import { Link } from "react-router-dom";
import { lanChange, truncateWords } from "src/utils";

const CourseTypeThree = ({ data, classes, bgWhite }) => {
	const lang = localStorage.getItem("lang");
	const language = lanChange();
	// console.log(data);
	return (
		<Link
			to={
				process.env.PUBLIC_URL +
				`/course/${data?.category?.slug}/course-details/${data?.course?.id}`
			}
		>
			<div
				className={`edu-card card-type-1 radius-small ${classes ? classes : ""} ${
					bgWhite === "enable" ? "bg-white" : ""
				}`}
			>
				<div className="inner">
					<div className="thumbnail">
						<Link
							to={
								process.env.PUBLIC_URL +
								`/course/${data?.category?.slug}/course-details/${data?.course?.id}`
							}
							style={{
								// width: "405px",
								height: "209px",
							}}
						>
							<img
								className="w-100"
								src={data?.course?.featured_image}
								alt="Course Thumb"
								style={{
									width: "100%",
									height: "100%",
								}}
							/>
						</Link>
						<div
							className="top-position status-group top-position"
							style={{ left: 10, bottom: -10 }}
						>
							<p className="eduvibe-status status-01" style={{ height: "auto" }}>
								{lang === "en" ? data?.category?.name : data?.category?.name_bn}
							</p>
						</div>
						{/* <div className="wishlist-top-right">
						<button className="wishlist-btn">
							<i className="icon-Heart"></i>
						</button>
					</div> */}
					</div>

					<div className="content">
						<ul className="edu-meta meta-01 justify-content-between">
							<li>
								<i className="icon-file-list-4-line"></i>
								{data?.course?.subject_count}{" "}
								{truncateWords(language.category.subjects, 2)}
							</li>
							<li>
								<i className="icon-time-line"></i>
								{truncateWords(data?.course?.duration, 2)}
							</li>
						</ul>
						<div>
							<h6 className="title" style={{ height: "50px" }}>
								<Link
									to={
										process.env.PUBLIC_URL +
										`/course/${data?.category?.slug}/course-details/${data?.course?.id}`
									}
								>
									{truncateWords(data?.course?.title, 3)}
								</Link>
							</h6>
						</div>

						<div className="mb-4">
							<div className="price-list price-style-03">
								{data?.course?.special_price ? (
									<>
										<div className="price current-price">
											৳ {data.course.special_price}
										</div>
										<div className="price old-price">
											৳ {data?.course?.price}
										</div>
									</>
								) : (
									<div className="price current-price">
										{" "}
										৳ {data?.course?.price}
									</div>
								)}
							</div>
						</div>

						<div className="card-bottom">
							<div className="edu-rating rating-default mb-0">
								<div className="rating eduvibe-course-rating-stars mb-0">
									<i className="icon-Star"></i>
								</div>
								<span className="rating-count">
									{Math.round(
										data?.course?.total_review / data?.course?.review_count ||
											0,
									)}
								</span>
							</div>
							<ul className="edu-meta meta-01">
								<li>
									<i
										className="icon-account-circle-line primary-color"
										style={{ fontSize: "20px" }}
									></i>
									{data?.course?.review_count} {language.category.students}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default CourseTypeThree;
