import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsExclamationTriangle, BsTrash } from "react-icons/bs";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { lanChange } from "src/utils";
import BreadcrumbOne from "../common/BreadcrumbOne";
import Loading from "../common/Loading";

export default function DeviceManager() {
	// base URL
	const baseUrl = process.env.REACT_APP_BASE_URL;
	// user info
	const user_data = useSelector((state) => state.auth?.user);
	// api end point
	const apiUrl = `${baseUrl}/api/linked_device`;
	// token
	const bearerToken = user_data?.token;
	const [linkedDevice, setLinkedDeviceData] = useState([]);
	const language = lanChange();
	useEffect(() => {
		fetch(apiUrl, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				setLinkedDeviceData(data);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [bearerToken]);

	const handleDelete = async (id) => {
		const deleteUrl = `${baseUrl}/api/linked_device_delete/${id}`;
		const response = await fetch(deleteUrl, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${bearerToken}`,
				"Content-Type": "application/json",
			},
		});
		if (response.ok) {
			const updatedData = linkedDevice?.data?.filter((item) => item.id !== id);
			setLinkedDeviceData(updatedData);
			toast.success("Device Remove Successfully");
			window.location.href = "/device-manager";
		} else {
			toast.error("Failed to Remove");
		}
	};

	if (!linkedDevice?.data) {
		return <Loading />;
	}
	// console.log(linkedDevice);
	return (
		<>
			<BreadcrumbOne
				rootUrl="/"
				parentUrl={language.DeviceManager.breadcrumb.parent}
				currentUrl={language.DeviceManager.breadcrumb.current}
			/>
			<div className="container">
				<h6 className="mt-4">{language.DeviceManager.title}</h6>
				<hr />
				{linkedDevice?.data?.map((row) => (
					<Card style={{ padding: "15px", marginBottom: 20 }}>
						<Row className="justify-content-between align-items-center">
							<Col sm={6}>
								<h6 className="mb-0">{row?.device_name}</h6>
								<p>
									{row?.platform} Last accessed on{" "}
									<Moment format="DD-MM-YYYY hh:mm">{row?.last_activity}</Moment>
								</p>
							</Col>
							<Col sm={3} className="text-end">
								{row?.flag ? (
									<p style={{ color: "green" }}>
										{language.DeviceManager.ActiveNow}
									</p>
								) : (
									<BsTrash
										style={{ color: "red" }}
										onClick={() => handleDelete(row?.id)}
									/>
								)}
							</Col>
						</Row>
					</Card>
				))}
				<hr />
				<p style={{ marginBottom: 20 }}>
					<BsExclamationTriangle style={{ color: "orange" }} />{" "}
					{language.DeviceManager.message}
				</p>
			</div>
		</>
	);
}
