import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { lanChange } from "../../utils";

export default function Features() {
	const language = lanChange();

	return (
		<div
			className="eduvibe-home-four-service edu-service-area edu-section-gap bg-color-white position-relative border-bottom-1 bg-color-light-blue"
			style={{
				backgroundColor: "#F4F9FF !important",
			}}
		>
			<div className="container eduvibe-animated-shape">
				<div className="row">
					<div className="col-lg-12">
						<ScrollAnimation
							animateIn="fadeInUp"
							animateOut="fadeInOut"
							animateOnce={true}
						>
							<div className="section-title text-center">
								<span className="pre-title">
									{language.home.ourFeatures.subTitle}
								</span>
								<h3 className="title"> {language.home.ourFeatures.title} </h3>
							</div>
						</ScrollAnimation>
					</div>
				</div>
				<div className="row eduvibe-about-one-service g-5 mt--20">
					{/* <!-- Start Service Grid  --> */}
					<ScrollAnimation
						className="col-lg-3 col-md-6 col-12"
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<div className="service-card service-card-3 text-left shape-bg-1 bg-white">
							<div className="inner">
								<div
									className="icon"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<img
										src="assets/images/flexible-learning.svg"
										alt=""
										width="50px"
									/>
								</div>
								<div className="content text-center">
									<h6 className="title">
										<a> {language.home.ourFeatures.features[0].title}</a>
									</h6>
									<p className="description">
										{" "}
										{language.home.ourFeatures.features[0].description}
									</p>
									{/* <!-- <div className="read-more-btn">
                                        <a className="btn-transparent sm-size heading-color" href="#">Learn More<i className="icon-arrow-right-line-right"></i></a>
                                    </div> --> */}
								</div>
							</div>
						</div>
					</ScrollAnimation>

					{/* <!-- End Service Grid  -->

                    <!-- Start Service Grid  --> */}
					<ScrollAnimation
						className="col-lg-3 col-md-6 col-12"
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<div className="service-card service-card-3 text-left shape-bg-2 bg-white">
							<div className="inner">
								<div
									className="icon"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<img src="assets/images/mobile-app.svg" alt="" width="50px" />
								</div>
								<div className="content text-center">
									<h6 className="title">
										<a> {language.home.ourFeatures.features[1].title}</a>
									</h6>
									<p className="description">
										{" "}
										{language.home.ourFeatures.features[1].description}
									</p>
									{/* <!-- <div className="read-more-btn">
                                        <a className="btn-transparent sm-size heading-color" href="#">Learn More<i className="icon-arrow-right-line-right"></i></a>
                                    </div> --> */}
								</div>
							</div>
						</div>
					</ScrollAnimation>

					{/* <!-- End Service Grid  -->

                    <!-- Start Service Grid  --> */}

					<ScrollAnimation
						className="col-lg-3 col-md-6 col-12"
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<div className="service-card service-card-3 text-left shape-bg-3 bg-white">
							<div className="inner">
								<div
									className="icon"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<img
										src="assets/images/offline-resource.svg"
										alt=""
										width="50px"
									/>
								</div>
								<div className="content text-center">
									<h6 className="title">
										<a> {language.home.ourFeatures.features[2].title}</a>
									</h6>
									<p className="description">
										{" "}
										{language.home.ourFeatures.features[2].description}
									</p>
								</div>
							</div>
						</div>
					</ScrollAnimation>
					{/* <!-- End Service Grid  -->

                    <!-- Start Service Grid  --> */}

					<ScrollAnimation
						className="col-lg-3 col-md-6 col-12"
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<div className="service-card service-card-3 text-left shape-bg-4 bg-white">
							<div className="inner">
								<div
									className="icon"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<img src="assets/images/support.svg" alt="" width="50px" />
								</div>
								<div className="content text-center">
									<h6 className="title">
										<a> {language.home.ourFeatures.features[3].title}</a>
									</h6>
									<p className="description">
										{" "}
										{language.home.ourFeatures.features[3].description}
									</p>
									{/* <!-- <div className="read-more-btn">
                                        <a className="btn-transparent sm-size heading-color" href="#">Learn More<i className="icon-arrow-right-line-right"></i></a>
                                    </div> --> */}
								</div>
							</div>
						</div>
					</ScrollAnimation>
					{/* <!-- End Service Grid  --> */}
				</div>

				<div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
					{/* <!-- <div className="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-29.png" alt="Shape Thumb" />
                    </div> --> */}
					{/* <!-- <div className="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-03-06.png" alt="Shape Thumb" />
                    </div> --> */}
					<div className="shape-image shape-image-3">
						<img src="assets/images/shapes/shape-02-06.png" alt="Shape Thumb" />
					</div>

					{/* <!-- <div className="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-19-02.png" alt="Shape Thumb" />
                    </div> --> */}
				</div>
			</div>
		</div>
	);
}
