import React from "react";
import { Accordion } from "react-bootstrap";

export default function FilterAccordion({ defaultAccordion = "1", items = [] }) {
	return (
		<Accordion defaultActiveKey={defaultAccordion} alwaysOpen={true}>
			{items.map((item, i) => (
				<Accordion.Item key={i} eventKey={"1"}>
					<Accordion.Header>{item.header}</Accordion.Header>
					<Accordion.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
						{item.body}
					</Accordion.Body>
				</Accordion.Item>
			))}
		</Accordion>
	);
}
