import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Audio from "src/components/Chapters/Audio";
import Flashcards from "src/components/Chapters/Flashcards";
import MCQ from "src/components/Chapters/MCQ";
import Questions from "src/components/Chapters/Questions";
import QuizViewForChapter from "src/components/Chapters/QuizViewForChapter";
import TabForChapters from "src/components/Chapters/TabForChapters";
import Video from "src/components/Chapters/Video";
import Comment from "src/components/CourseDetails/Comment";
import Tab from "src/components/MyCourse/Tab";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import Loading from "src/components/common/Loading";

import {
	useChapterWiseHighCourtQuestionsQuery,
	useGetSubjectDetailsQuery,
} from "src/redux/course/courseApi";
import { setSubject } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";

export default function Chapters() {
	const { subject_id, chapter_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter_type = searchParams.get("chapter_type");
	const course_type = searchParams.get("course_type");
	const { isLoading, data, refetch, isFetching } = useGetSubjectDetailsQuery({
		subject_id,
		chapter_type: chapter_type,
	});
	const {
		isLoading: isLoadingChapterWiseHighCourtQuestions,
		data: chapterWiseHighCourtQuestionsRes,
	} = useChapterWiseHighCourtQuestionsQuery({
		chapter_id,
	});
	// get query parrameter

	const subjects = useSelector((state) => state.course.subject);
	const language = lanChange();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSubject(data));
	}, [isLoading, isFetching]);

	if (isLoading) return <Loading />;
	if (isLoadingChapterWiseHighCourtQuestions) return <Loading />;

	const chapter =
		Array.isArray(subjects?.chapter) && subjects?.chapter.length > 0
			? subjects?.chapter?.find((c) => c.id === parseInt(chapter_id))
			: null;
	// console.log({ data });
	const getComponent = (type, item) => {
		console.log(type, item);
		switch (type) {
			case "Text":
				return (
					<Audio
						item={item}
						refetch={() => refetch({ subject_id, chapter_type: chapter_type })}
					/>
				);
			case "Audio":
				return (
					<Audio
						item={item}
						refetch={() => refetch({ subject_id, chapter_type: chapter_type })}
					/>
				);
			case "Video":
				return (
					<Video
						item={item}
						refetch={() => refetch({ subject_id, chapter_type: chapter_type })}
					/>
				);
			case "FlashCard":
				return (
					<Flashcards
						item={item}
						refetch={() => refetch({ subject_id, chapter_type: chapter_type })}
					/>
				);
			case "Exam":
				return (
					<MCQ
						item={item}
						refetch={() => refetch({ subject_id, chapter_type: chapter_type })}
					/>
				);
			default:
				return "No component found!";
		}
	};

	const topics =
		chapter?.content_lessons && Array.isArray(chapter?.content_lessons)
			? chapter.content_lessons.map((item) => {
					return {
						id: item.id,
						type: item.content_type,
						isComplete: item.is_done,
						name: item.name,
						lesson: item,
						content: getComponent(item.content_type, item),
					};
			  })
			: [];

	return (
		<>
			<BreadcrumbOne
				currentUrl={chapter?.name}
				meddle={[
					{
						name: subjects?.name,
						url: `/my-course/${subjects?.course_id}/subject/${
							subjects?.id
						}?chapter_type=${searchParams.get("chapter_type")}${
							course_type ? `&course_type=${course_type}` : ""
						}
						`,
					},
				]}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			<div style={{ minHeight: "80vh" }}>
				<Tab
					defaultTab="1"
					style={{ marginBottom: 0 }}
					tab={[
						{
							id: "1",
							name:
								course_type === "High-Court"
									? language.subjectAndChapter.Outline
									: language.subjectAndChapter.Lectures,
							content: (
								<TabForChapters
									defaultTab={parseInt(searchParams.get("lesson_id"))}
									tab={{
										title: chapter?.name || "",
										chapters: [...topics],
									}}
								/>
							),
						},
						{
							id: "2",
							name:
								course_type === "High-Court"
									? `${language.subjectAndChapter.Questions} ${language.subjectAndChapter.and} ${language.subjectAndChapter.Answer} `
									: language.subjectAndChapter.QuizAnswer,
							content:
								course_type === "High-Court" ? (
									<Questions chapter_id={chapter_id} />
								) : (
									<QuizViewForChapter chapter_id={chapter_id} />
								),
						},
						{
							id: "3",
							name: language.subjectAndChapter.Discussion,
							content: <Comment />,
						},
					]}
				/>
			</div>
		</>
	);
}
