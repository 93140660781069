import { useParams } from "react-router-dom";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import Loading from "src/components/common/Loading";
import SEO from "src/components/common/SEO";
import { useGetPrivacyDetailsQuery } from "src/redux/HomePageInfo/homeApi";
import { lanChange } from "src/utils";

export default function PrivacyDetails() {
	const { slug } = useParams();
	const language = lanChange();
	const { isLoading, isError, data } = useGetPrivacyDetailsQuery(slug);

	if (isLoading) return <Loading />;

	return (
		<>
			<SEO title={data?.title} />
			<BreadcrumbOne
				title={data?.title}
				rootUrl="/"
				parentUrl={language.category.breadcrumb.parent}
				currentUrl={data?.title}
			/>
			<div class="edu-privacy-policy-area edu-privacy-policy pt-4 my-5 pb-5 bg-color-white">
				<div class="container">
					<div class="row g-5">
						<div class="content ">
							{data?.policy ? (
								<div
									class="exam-details-accordion-body"
									dangerouslySetInnerHTML={{
										__html: data?.policy,
									}}
								/>
							) : (
								<h4 className="text-center my-4">Data Not Found!</h4>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
