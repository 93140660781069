import HomeTwoTestimonial from "src/components/Download/HomeTwoTestimonial";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import DownloadApp from "src/components/common/DownloadApp";

export default function Download() {
	return (
		<>
			<BreadcrumbOne
				title="Download App"
				rootUrl="/"
				parentUrl="Home"
				currentUrl="Download App"
			/>
			<DownloadApp />
			<HomeTwoTestimonial />
		</>
	);
}
