const baseUrl = "/api";
export const homePageEndpoint = `${baseUrl}/home-page`;
export const contactRequestEndpoint = `${baseUrl}/contact_request`;
export const newsLetterEndpoint = `${baseUrl}/news_letter`;
export const policyDetailsEndpoint = `${baseUrl}/policy-details`;

// auth
export const loginEndpoint = `${baseUrl}/login`;
export const registerEndpoint = `${baseUrl}/register`;
export const otpVerificationEndpoint = `${baseUrl}/otp_verification`;
export const logoutEndpoint = `${baseUrl}/logout`;
export const forgotPasswordEndpoint = `${baseUrl}/forget_password`;
export const passwordResetEndpoint = `${baseUrl}/password_reset`;
export const passwordCreateEndpoint = `${baseUrl}/password_create`;
export const userEndpoint = `${baseUrl}/profile`;
// dashboard
export const dashboardEndpoint = `${baseUrl}/dashboard`;
export const courseDetailsEndpoint = `${baseUrl}/course_details?course_id=`;
export const myCourseDetailsEndpoint = `${baseUrl}/my_course_details?course_id=`;
export const courseWiseModelTestEndpoint = `${baseUrl}/course_wise_modeltest`;
export const courseReviewAllEndpoint = `${baseUrl}/course_review_all?course_id=`;
export const barAssociationListEndpoint = `${baseUrl}/bar_association_list`;
export const examStageListEndpoint = `${baseUrl}/exam_stage_list`;
export const courseEnrollmentEndpoint = `${baseUrl}/course-enrollment`;
export const webCourseEnrollmentEndpoint = `${baseUrl}/web-course-enrollment`;
export const paySslEndpoint = `${baseUrl}/pay-ssl`;

export const discussionSubmitEndpoint = `${baseUrl}/discussion_submit`;
export const discussionReplySubmitEndpoint = `${baseUrl}/discussion_reply_submit`;
export const courseFeedbackEndpoint = `${baseUrl}/course_feedback`;
export const webGlobalCourseSearchEndpoint = `${baseUrl}/web-global-course-search?search=`;
export const subjectDetailsEndpoint = `${baseUrl}/subject_details`;
export const chapterLessionContentEndpoint = `${baseUrl}/chapter_lession_content`;
export const modelTestWiseQuestionEndpoint = `${baseUrl}/modeltest_wise_question`;
export const examSubmitEndpoint = `${baseUrl}/exam_submit`;
export const chapterWiseFlashCardEndpoint = `${baseUrl}/chapter_wise_flash_card`;
export const chapterWiseQuestionEndpoint = `${baseUrl}/chapter_wise_question`;
export const markAsReadEndpoint = `${baseUrl}/content/mark-as-read`;
export const chapterWiseResultEndpoint = `${baseUrl}/chapter_wise_result`;
export const courseWiseResultEndpoint = `${baseUrl}/course_wise_result`;
export const notificationEndpoint = `${baseUrl}/unread-notifications`;
export const socialLoginEndpoint = `${baseUrl}/social_login`;
export const unreadNotificationsEndpoint = `${baseUrl}/unread-notifications`;
export const markAllNotificationsAsReadEndpoint = `${baseUrl}/mark-all-notifications-as-read-web`;
export const subjectWrittenContentDetailsEndpoint = `${baseUrl}/subject_written_content_details`;
export const writtenExamSubmitEndpoint = `${baseUrl}/written_exam_submit`;
export const courseWiseWrittenResultEndpoint = `${baseUrl}/course_wise_written_result`;
export const chapterWiseHighCourtQuestions = `${baseUrl}/chapter-wise-high-court-questions`;
export const highCourtQuestionDetails = `${baseUrl}/high-court-question-details`;
export const checkCouponEndpoint = `${baseUrl}/check-coupon`;
export const generateCertificateEndpoint = `${baseUrl}/generate-certificate`;
export const courseWiseCertificateEndpoint = `${baseUrl}/course-wise-certificate`;
export const orderHistoryEndpoint = `${baseUrl}/order-history`;
