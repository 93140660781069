import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useChapterWiseHighCourtQuestionsQuery } from "src/redux/course/courseApi";
import { setQuestionView } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";
import CustomLoading from "../common/CustomLoading";

const Questions = ({ chapter_id }) => {
	const [show, setShow] = useState(false);
	const [question, setQuestion] = useState({
		question: "",
		answer: "",
	});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const chapters = useSelector((state) => state.course?.subject?.chapter);

	const [chapter, setChapter] = React.useState(chapter_id);

	const { isLoading, data, refetch, isFetching } = useChapterWiseHighCourtQuestionsQuery({
		chapter_id: chapter,
	});

	const questions = data?.data?.highCourtQuestions;

	const dispatch = useDispatch();
	useEffect(() => {
		if (data?.status === "fail") {
			dispatch(setQuestionView([]));
		}
		if (data?.status === "success") {
			dispatch(setQuestionView(data.data));
		}
	}, [isLoading, isFetching]);

	useEffect(() => {
		if (chapter) {
			refetch({ chapter_id: chapter });
		}
	}, [chapter]);

	return (
		<div className="mt-5 ">
			<form className="unselectable">
				<div style={{ padding: "20px 0px", backgroundColor: "#F4F9FF" }}>
					<div className="container">
						<Row className="justify-content-end align-items-center ">
							<Col sm={4}>
								{/* <h6 className="mb-0">{exam_info?.title || ""}</h6>
								<p className="m-0">Practice Quiz 6 min 30m per question 1 marks</p> */}
							</Col>

							<Col sm={2}>
								<select
									className="py-3"
									onChange={(e) => {
										setChapter(e.target.value);
									}}
									value={chapter}
									style={{ backgroundColor: "white" }}
								>
									{chapters?.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
								</select>
							</Col>
						</Row>
					</div>
				</div>
				{/* <div style={{ backgroundColor: "#F4F9FF", padding: "20px 0px" }}>
						<div className="container">
							<Row className="justify-content-between align-items-center ">
								<Col sm={12}>
									<h4 className="mb-0">Congratulations! You Passed!</h4>
									<h4 className="mb-0" style={{ color: "#f98a17" }}>
										Your Score: 80%
									</h4>
								</Col>
							</Row>
						</div>
					</div> */}

				{isLoading || isFetching ? (
					<CustomLoading height="60" />
				) : Array.isArray(questions) && questions?.length > 0 ? (
					<div className="container my-4">
						<div className="questions">
							{questions.map((question, i) => (
								<div className="card mb-3" key={question?.id}>
									<div className="card-body">
										<p
											className="card-text mb-2 "
											style={{ color: "black" }}
											dangerouslySetInnerHTML={{ __html: question?.question }}
										></p>

										<Link
											to={{
												pathname: "/questions-answer/" + question?.id,
												state: {
													question: question?.question,
													answer: question?.answer,
												},
											}}
											role="button"
											className="card-text m-0"
											style={{
												color: "#f98a17",
												fontWeight: 700,
												// textDecoration: "underline",
												backgroundColor: "rgb(244, 249, 255)",
												padding: "10px 15px",
												borderRadius: "5px",
											}}
										>
											{lanChange().Seefullquestionwithanswer}
										</Link>
									</div>
								</div>
							))}
						</div>
						{/* <div className="d-flex justify-content-end">
							<Pagination size="lg">
								<Pagination.Prev />
								<Pagination.Item active>{1}</Pagination.Item>
								<Pagination.Item>{2}</Pagination.Item>

								<Pagination.Next />
							</Pagination>
						</div> */}
					</div>
				) : (
					<div className="container my-4">
						<div className="questions">
							<h6 className="mb-0 text-center">{lanChange().Nodatafound}!</h6>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};

export default Questions;
