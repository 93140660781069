import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import TabsBody from "src/components/Subject/TabBody";
import TabForSubject from "src/components/Subject/TabForSubject";
import Written from "src/components/Subject/Written";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import Loading from "src/components/common/Loading";
import { useGetSubjectDetailsQuery } from "src/redux/course/courseApi";
import { setSubject } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";

export default function Subject() {
	const { subject_id } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const chapter_type = searchParams.get("chapter_type");
	const course_type = searchParams.get("course_type");

	const { isLoading, data } = useGetSubjectDetailsQuery({ subject_id, chapter_type });
	const subjects = useSelector((state) => state.course.subject);
	const lang = localStorage.getItem("lang");
	const dispatch = useDispatch();
	const language = lanChange();
	useEffect(() => {
		dispatch(setSubject(data));
	}, [isLoading]);

	if (isLoading) return <Loading />;

	const topics = Array.isArray(subjects?.chapter)
		? subjects.chapter.map((item) => {
				return {
					id: item.id,
					type: "file",
					isComplete: false,
					name: item.name,
					audio: lang === "en" ? item?.file_path : item?.file_path_bn,
					content: <TabsBody item={item} />,
				};
		  })
		: [];

	const written = Array.isArray(subjects)
		? subjects.map((item) => {
				return {
					id: item.id,
					type: "file",
					isComplete: false,
					name: item.question,
					audio: lang === "en" ? item?.file_path : item?.file_path_bn,
					content: <Written item={item} />,
				};
		  })
		: [];

	return (
		<>
			<BreadcrumbOne
				currentUrl={subjects?.name || subjects?.[0]?.subject?.name}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			{topics.length || written.length ? (
				<TabForSubject
					defaultTab={subjects?.chapter?.[0]?.id || subjects?.[0]?.id}
					chapter_type={chapter_type}
					course_type={course_type}
					tab={{
						topics: chapter_type !== "Written" ? topics : written,
					}}
				/>
			) : (
				<h4 className="container my-5 text-center">{language.Nodatafound}</h4>
			)}
		</>
	);
}
