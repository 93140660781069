import { markAllNotificationsAsReadEndpoint, unreadNotificationsEndpoint } from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";
import { getTimeDifference } from "../../utils";

export const notificationsApi = mainServiceApi
	.enhanceEndpoints({ addTagTypes: ["notifications"] })
	.injectEndpoints({
		endpoints: (builder) => ({
			unreadNotifications: builder.query({
				query: () => ({
					url: unreadNotificationsEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),

				providesTags: [{ type: "notifications", id: "LIST" }],

				// ? Transform the result to prevent nested data
				transformResponse: (response) => {
					if (response.code === 200 && response.data.length > 0) {
						response.data = response.data.map((item) => ({
							id: item.id,
							created_at: getTimeDifference(item.created_at),
							eventKey: item.id,
							children: item.data.subject,
							value: item.id,
							read_at: item.read_at,
						}));
					}
					return response;
				},
			}),
			markAllNotificationsAsRead: builder.mutation({
				query: (data) => ({
					url: markAllNotificationsAsReadEndpoint,
					method: "POST",
					body: data,
				}),

				invalidatesTags: [{ type: "notifications", id: "LIST" }],

				// ? Transform the result to prevent nested data
				transformResponse: (response) => {
					return response;
				},
			}),
		}),
	});

export const { useUnreadNotificationsQuery, useMarkAllNotificationsAsReadMutation } =
	notificationsApi;
