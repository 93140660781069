import { CiLocationOn } from "react-icons/ci";
import { LuMailOpen } from "react-icons/lu";
import { TbWorld } from "react-icons/tb";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { lanChange } from "src/utils";
import BreadcrumbOne from "../common/BreadcrumbOne";
import SEO from "../common/SEO";
import ContactUsForm from "./ContactUsForm";

const ContactUs = () => {
	const { contact } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();
	return (
		<>
			<SEO title={language.ContactUs.breadcrumb.title} />
			{/* <Layout> */}
			<BreadcrumbOne
				title={language.ContactUs.breadcrumb.title}
				rootUrl="/"
				parentUrl={language.ContactUs.breadcrumb.parent}
				currentUrl={language.ContactUs.breadcrumb.current}
			/>
			<div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
				<div className="container eduvibe-animated-shape">
					<div className="row g-5">
						<div className="col-lg-12">
							<div className="section-title text-center">
								<span className="pre-title">{language.ContactUs.title} </span>
								<h3 className="title">{language.ContactUs.subTitle} </h3>
							</div>
						</div>
					</div>
					<div className="row g-5 mt--20">
						<div className="col-lg-6">
							<div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
								<div className="row g-5">
									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="contact-address-card-1 website">
											<div className="inner">
												<div className="icon">
													<TbWorld style={{ fontSize: 24 }} />
												</div>
												<div className="content">
													<h6 className="title">
														{language.ContactUs.OurWebsite}
													</h6>
													<p>
														<a
															href={
																contact?.web_site ||
																"www.legalizededu.org"
															}
															target="_blank"
															rel="noreferrer"
														>
															{contact?.web_site ||
																"www.legalizededu.org"}
														</a>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="contact-address-card-1 phone">
											<div className="inner">
												<div className="icon">
													<TfiHeadphoneAlt style={{ fontSize: 24 }} />
												</div>
												<div className="content">
													<h6 className="title">
														{language.ContactUs.CallUsOn}
													</h6>
													<p>
														<a
															href={`tel:${
																contact?.phone || "+880 1879167052"
															}`}
														>
															{contact?.phone || "+880 1879167052"}
														</a>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="contact-address-card-1 email">
											<div className="inner">
												<div className="icon">
													<LuMailOpen style={{ fontSize: 24 }} />
												</div>
												<div className="content">
													<h6 className="title">
														{language.ContactUs.EmailUs}
													</h6>
													<p class="break-word">
														<a
															target="_blank"
															href={`mailto: ${
																contact?.email ||
																"legalizededucationbd@gmail.com"
															}`}
															rel="noreferrer"
														>
															{contact?.email ||
																"legalizededucationbd@gmail.com"}
														</a>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="contact-address-card-1 location">
											<div className="inner">
												<div className="icon">
													<CiLocationOn style={{ fontSize: 24 }} />
												</div>
												<div className="content">
													<h6 className="title">
														{language.ContactUs.OurLocation}
													</h6>
													<p>
														{contact?.location ||
															`Legalized
													Education Bangladesh Ltd 
													Flat 2A, House 88, Road 17/A, Block E, Banani,
													Dhaka`}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
						</div>
					</div>

					<div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
						<div className="shape-image shape-image-1">
							<img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
						</div>
						<div className="shape-image shape-image-2">
							<img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
						</div>
						<div className="shape-image shape-image-3">
							<img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
						</div>
					</div>
				</div>
			</div>

			{/* <div className="edu-contact-map-area edu-section-gapBottom">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="google-map alignwide">
									<iframe
										title="Google Map"
										src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
										height="500"
										frameBorder="0"
										style={{ border: 0 }}
										allowFullScreen=""
										aria-hidden="false"
										tabIndex="0"
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			{/* </Layout> */}
		</>
	);
};
export default ContactUs;
