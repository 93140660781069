import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Exam from "src/components/MyCourse/Exam";
import Excercises from "src/components/MyCourse/Excercises";
import Result from "src/components/MyCourse/Result";
import Tab from "src/components/MyCourse/Tab";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import Loading from "src/components/common/Loading";
import {
	useGetCourseWiseModelTestQuery,
	useGetMyCourseDetailsQuery,
} from "src/redux/course/courseApi";
import { setCourseWiseExams, setMyCourses } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";

export default function MyCourse() {
	const { course_id } = useParams();
	const { isLoading, data, isFetching } = useGetMyCourseDetailsQuery({ course_id });
	const {
		isLoading: isLoadingForCourseWiseModelTest,
		isFetching: isFetchingForCourseWiseModelTest,
		data: courseWiseModelTestRes,
	} = useGetCourseWiseModelTestQuery({ course_id });
	const dispatch = useDispatch();
	const language = lanChange();
	useEffect(() => {
		if (data?.status === "success") {
			dispatch(setMyCourses(data.data));
		}
	}, [isLoading, isFetching]);

	useEffect(() => {
		if (courseWiseModelTestRes?.status === "success") {
			dispatch(setCourseWiseExams(courseWiseModelTestRes.data));
		}
	}, [isLoadingForCourseWiseModelTest, isFetchingForCourseWiseModelTest]);

	if (isLoading || isLoadingForCourseWiseModelTest) return <Loading />;

	return (
		<>
			<BreadcrumbOne
				currentUrl={data?.data?.course?.title}
				rootUrl="/student-dashboard"
				parentUrl={language.category.breadcrumb.parent}
			/>
			<Tab
				defaultTab="1"
				style={{ marginBottom: 0 }}
				tab={[
					{
						id: "1",
						name: language.myCourse.Excercises,
						content: <Excercises />,
					},
					{
						id: "2",
						name: language.myCourse.Exam,
						content: <Exam course_id={course_id} />,
					},

					{
						id: "3",
						name: language.myCourse.Result,
						content: <Result />,
					},
					// {
					// 	id: "2",
					// 	name: "Instructors",
					// 	content: <Instructors />,
					// },
					// {
					// 	id: "4",
					// 	name: "Purchase History",
					// 	content: <PurchaseHistory />,
					// },
					// {
					// 	id: "5",
					// 	name: "Settings",
					// 	content: <Settings />,
					// },
				]}
			/>
		</>
	);
}
