import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as CompleteIcon } from "src/assets/images/complete.svg";
import { ReactComponent as FileIcon } from "src/assets/images/file.svg";
import { lanChange } from "src/utils";
import Info from "./Info";
import ResultItem from "./ResultItem";

const Icon = {
	file: <FileIcon style={{ marginRight: 10, width: 50, height: 50 }} />,
	complete: <CompleteIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
};

export default function Result() {
	const { courseWiseExams, myCourses } = useSelector((state) => state.course);
	const language = lanChange();
	const { course_id } = useParams();

	const mcq =
		courseWiseExams && Array.isArray(courseWiseExams?.mcq_test)
			? courseWiseExams?.mcq_test.map((item, i) => {
					const date = item.created_at ? moment(item.created_at) : null;
					const formattedDate = date ? date.format("DD MMM YYYY") : null;
					return (
						<ResultItem
							type="result"
							btnName={item?.attempt_no >= 1 ? language.myCourse.ViewResult : ""}
							link={`${
								item?.attempt_no >= 1
									? `/my-course/${course_id}/quiz-view/${item?.id}`
									: ""
							}`}
							progress={item?.previous_score}
							name={item?.title}
							icon={Icon["file"]}
							summary={`${language.myCourse.Passmark}: 50% | ${
								formattedDate ? `${language.myCourse.Date}: ` + formattedDate : ""
							} | ${language.myCourse.Duration} : ${Number(
								item.duration / 60,
							).toFixed(2)} ${language.myCourse.Min}`}
							// text={
							// 	item?.attempt_no === 3
							// 		? ""
							// 		: item?.attempt_no && parseInt(item?.previous_score) <= 49
							// 		? "Start your second attempt"
							// 		: ""
							// }
						/>
					);
			  })
			: [];
	const written =
		courseWiseExams && Array.isArray(courseWiseExams?.writen_exam)
			? courseWiseExams?.writen_exam.map((item, i) => {
					const date = item.created_at ? moment(item.created_at) : null;
					const formattedDate = date ? date.format("DD MMM YYYY") : null;
					return (
						<ResultItem
							type="result"
							btnName={item?.attempt_no >= 1 ? language.myCourse.ViewResult : ""}
							link={`${
								item?.attempt_no >= 1
									? `/my-course/${course_id}/view-written-exam/${item?.id}`
									: ""
							}`}
							progress={item?.previous_score}
							name={item?.title}
							icon={Icon["file"]}
							summary={`${language.myCourse.Passmark}: 50% `}
							// text={
							// 	item?.attempt_no === 3
							// 		? ""
							// 		: item?.attempt_no && parseInt(item?.previous_score) <= 49
							// 		? "Start your second attempt"
							// 		: ""
							// }
						/>
					);
			  })
			: [];

	const tab = [
		{
			key: 1,
			id: 1,
			name: language.PracticeMCQModelTest,
			content: mcq.length > 0 ? mcq : <h6 className="text-center">{language.Nodatafound}</h6>,
		},
		{
			key: 2,
			id: 2,
			name: language.PracticeWrittenModelTest,
			content:
				written.length > 0 ? (
					written
				) : (
					<h6 className="text-center">{language.Nodatafound}</h6>
				),
		},
	];

	let defaultTabKey = 1;
	if (myCourses?.course?.course_type !== "High Court") {
		defaultTabKey = 1;
	} else {
		defaultTabKey = 2;
		tab.shift();
	}
	return (
		<div>
			<Info defaultTab={defaultTabKey} tab={tab} />
		</div>
	);
}
