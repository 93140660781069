import { Link, useParams, useSearchParams } from "react-router-dom";

export default function TabChapters({
	tab = [],
	title,
	setContentTab,
	contentTab,
	Icon,
	comeFrom = "",
}) {
	const { course_id, subject_id, chapter_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const TYPE = {
		Text: "Reading:",
		Audio: "Audio:",
		Video: "Video:",
		FlashCard: "Flashcard:",
		Exam: "Quiz",
	};
	return (
		<>
			<h6 style={{ fontSize: "16px", padding: "14px 16px 0px 16px", margin: 0 }}>{title}</h6>
			{tab.map((item, i) => {
				return (
					<Link
						key={item.id}
						to={`/my-course/${course_id}/subject/${subject_id}/chapters/${chapter_id}?lesson_id=${
							item.id
						}&chapter_type=${searchParams.get(
							"chapter_type",
						)}&course_type=${searchParams.get("course_type")}`}
						aria-label={item.name}
						onClick={() => setContentTab(item.id)}
						className={`accordion-for-subjects-btn ${
							contentTab === item.id ? "accordion-for-subjects-btn-select" : ""
						}`}
					>
						{comeFrom !== "topics"
							? Icon["file"]
							: Icon[item.isComplete ? "complete" : item.type]}
						<div>
							{item.name.length > 35 ? item.name.substring(0, 35) + "..." : item.name}

							<p
								style={{
									fontSize: 14,
									fontWeight: 400,
									margin: "5px 0px 0px 0px",
									width: "100%",
								}}
							>
								{TYPE[item.lesson?.content_type]}{" "}
								{item.lesson?.content_type === "FlashCard"
									? item.lesson?.total_count
									: item.lesson?.duration}
							</p>
						</div>
					</Link>
				);
			})}
		</>
	);
}
