import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsPlayCircleFill, BsStopCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BookIcon } from "src/assets/images/book.svg";
import { ReactComponent as CompleteIcon } from "src/assets/images/complete.svg";
import { ReactComponent as FileIcon } from "src/assets/images/file.svg";
import { ReactComponent as MediaIcon } from "src/assets/images/media.svg";
import { setAudio } from "src/redux/audio/audioSlice";
import { lanChange } from "src/utils";
import Tabs from "./Tab";

export const Icon = {
	Text: <BookIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Exam: <BookIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Audio: <MediaIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	Video: <MediaIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	FlashCard: <FileIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	file: <FileIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
	complete: <CompleteIcon style={{ marginRight: 10, width: 30, height: 30 }} />,
};

export default function TabForSubject({
	defaultTab = "",
	chapter_type = "",
	course_type = "",
	tab = null,
}) {
	const audio1 = useSelector((state) => state.audio.audio);
	const [contentTab, setContentTab] = useState(defaultTab);
	const subjects = useSelector((state) => state.course.subject);

	const dispatch = useDispatch();

	const language = lanChange();

	useEffect(() => {
		// setAudio(null);
		dispatch(
			setAudio({
				...audio1,
				src: null,
				title: "",
				buffered: 0,
				currentProgress: 0,
				duration: 0,
				isReady: false,
				playing: false,
			}),
		);

		const sub = tab?.topics.find((item) => item.id === contentTab);

		if (sub?.audio) {
			dispatch(
				setAudio({
					...audio1,
					src: sub.audio,
					title: sub.name,
					show: false,
					buffered: 0,
					currentProgress: 0,
					duration: 0,
					isReady: false,
					playing: false,
				}),
			);
		}
	}, [contentTab]);
	const audio = tab?.topics.find((item) => item.id === contentTab)?.audio;

	console.log({ audio });

	return (
		<div className="container" style={{ marginBottom: "20px" }}>
			<Row
				className="course-details-content accordion-for-subjects mt-4"
				style={{ position: "relative" }}
			>
				<Col
					sm={12}
					md={6}
					lg={3}
					style={{
						zIndex: 1,
					}}
				>
					<div
						className="accordion-for-items"
						style={{
							border: "1px solid lightgray",
							borderRadius: 5,
							height: "70vh",
							overflow: "auto",
						}}
					>
						<h6 className="m-0 mt-4 ps-4">{subjects?.name}</h6>

						{Array.isArray(tab.topics) && tab.topics.length ? (
							<Tabs
								title={chapter_type === "MCQ" ? "Chapters" : language.QuestionList}
								tab={tab.topics}
								Icon={Icon}
								contentTab={contentTab}
								setContentTab={setContentTab}
								comeFrom="topics"
							/>
						) : (
							""
						)}
						{/* {Object.keys(tab.writtenExam).length ? (
							<Tabs
								title={"Written Exam"}
								tab={tab.writtenExam}
								Icon={Icon}
								contentTab={contentTab}
								setContentTab={setContentTab}
								comeFrom="writtenExam"
							/>
						) : (
							""
						)}
						{Object.keys(tab.modelTest).length ? (
							<Tabs
								title={"Model Test"}
								tab={tab.modelTest}
								Icon={Icon}
								contentTab={contentTab}
								setContentTab={setContentTab}
								comeFrom="modelTest"
							/>
						) : null}
						{Object.keys(tab.flashcards).length ? (
							<Tabs
								title={"Flashcards"}
								tab={tab.flashcards}
								Icon={Icon}
								contentTab={contentTab}
								setContentTab={setContentTab}
								comeFrom="flashcards"
							/>
						) : (
							""
						)} */}
					</div>
				</Col>
				<Col sm={12} md={6} lg={9}>
					<div
						className="tab-content"
						style={
							chapter_type === "MCQ"
								? {
										height: "60vh",
										overflow: "auto",
								  }
								: {}
						}
					>
						{Array.isArray(tab.topics) && tab.topics.length
							? tab.topics.map((item) => {
									if (item.id === contentTab) {
										return (
											<div
												// onClick={() => console.log(item)}
												key={item.id}
												className={`tab-pane fade show ${
													contentTab === item.id ? "active" : ""
												} `}
											>
												<div>{item.content}</div>
											</div>
										);
									}
									return null;
							  })
							: null}
						<div
							style={{
								position: "absolute",
								right: 0,
								bottom: 0,
								width: "100%",
							}}
						>
							<div className="w-100">
								<Row
									className="justify-content-end align-items-center"
									style={{ marginTop: 30, marginBottom: 30 }}
								>
									<Col sm={4}></Col>

									{audio && (
										<Col sm={1} className="text-end" style={{ height: "5vh" }}>
											{audio1.show ? (
												<BsStopCircleFill
													role="button"
													style={{
														fontSize: 45,
														color: "#f98a17",
													}}
													onClick={() =>
														dispatch(
															setAudio({
																...audio1,
																show: false,
																src: null,
																title: "",
															}),
														)
													}
												/>
											) : (
												<BsPlayCircleFill
													role="button"
													style={{
														fontSize: 45,
														color: "#f98a17",
													}}
													onClick={() => {
														const sub = tab?.topics.find(
															(item) => item.id === contentTab,
														);
														dispatch(
															setAudio({
																...audio1,
																show: true,
																src: sub.audio,
																title: sub.name,
															}),
														);
													}}
												/>
											)}
										</Col>
									)}
								</Row>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
}
