import React from "react";
import { Accordion } from "react-bootstrap";

export default function CustomAccordion({
	alwaysOpen = false,
	defaultAccordion = "1",
	items = [],
}) {
	return (
		<Accordion defaultActiveKey={defaultAccordion}>
			{items.map((item, i) => (
				<Accordion.Item key={item.id} eventKey={item.id}>
					<Accordion.Header>{item.header}</Accordion.Header>
					<Accordion.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
						{item.body}
					</Accordion.Body>
				</Accordion.Item>
			))}
		</Accordion>
	);
}
