import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import profileImg from "src/assets/images/profile.svg";
import { userInfo } from "src/redux/auth/authSlice";
import { lanChange } from "src/utils";
import BreadcrumbOne from "../common/BreadcrumbOne";
import CustomButton from "../common/CustomButton";

export default function Settings() {
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [is_loading, setIsLoading] = useState(null);
	const handleImageChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setSelectedImage(URL.createObjectURL(event.target.files[0]));
	};
	const language = lanChange();
	const dispatch = useDispatch();
	// base URL
	const baseUrl = process.env.REACT_APP_BASE_URL;
	// user info
	const user_info = useSelector((state) => state.auth?.user?.user);
	const user_data = useSelector((state) => state.auth?.user);
	// update profile pai end point
	const apiUrl = `${baseUrl}/api/profile-update`;
	// token
	const bearerToken = user_data?.token;
	const [user_input, setUserInput] = useState({
		name: user_info.name,
		dob: user_info.dob,
		phone: user_info.phone,
		profile_image: user_info.profile_image,
	});
	const onChange = (e) => {
		setUserInput((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};
	const handleSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();
		const formData = new FormData();
		formData.append("profile_image", selectedFile ? selectedFile : "");
		formData.append("name", user_input.name);
		formData.append("dob", user_input.dob);
		formData.append("phone", user_input.phone);
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${bearerToken}`,
				},
				body: formData,
			});
			if (response.ok) {
				const user_profile = await response.json();
				const userData = JSON.parse(JSON.stringify(user_data));
				userData.user.dob = user_profile.data.dob;
				userData.user.name = user_profile.data.name;
				userData.user.profile_image = user_profile.data.profile_image;
				dispatch(userInfo(userData));
				setIsLoading(false);
				toast.success("Profile Update Successfully");
			} else {
				setIsLoading(false);
				toast.error("Failed to post data");
			}
		} catch (error) {
			setIsLoading(false);
			toast.error(error);
		}
	};
	return (
		<>
			<BreadcrumbOne
				rootUrl="/"
				parentUrl={language.Profile.breadcrumb.parent}
				currentUrl={language.Profile.breadcrumb.current}
			/>
			<div className="container">
				<h6 className="mt-4">{language.Profile.title}</h6>
				<Row className="justify-content-between">
					<Col
						sm={3}
						style={{
							border: "1px solid lightgray",
							padding: 24,
							borderRadius: 5,
							marginBottom: 30,
						}}
					>
						<Card
							style={{
								border: "none",
							}}
						>
							<Card.Img
								src={selectedImage || user_info?.profile_image || profileImg}
							/>
							<Card.ImgOverlay
								style={{
									// display: "flex",
									// alignItems: "end",
									// justifyContent: "center",
									position: "relative",
									bottom: 0,
									padding: 0,
								}}
							>
								<label htmlFor="upload" className="w-100">
									<Card.Text
										onChange={handleImageChange}
										style={{
											color: "white",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
											padding: 15,
											cursor: "pointer",
											backgroundColor: "#0000004d",
											position: "relative",
											bottom: 55,
										}}
									>
										<BsUpload style={{ marginRight: 5 }} />
										{language.Profile.UploadPhoto}
									</Card.Text>
								</label>
							</Card.ImgOverlay>
							<p
								style={{
									textAlign: "center",
								}}
							>
								{" "}
								{language.Profile.imageSize}
							</p>
							<input
								type="file"
								accept="image/*"
								style={{ opacity: 0 }}
								id="upload"
								onChange={handleImageChange}
							/>
						</Card>
					</Col>
					<Col sm={8}>
						<form
							className={`login-form `}
							onSubmit={handleSubmit}
							autoComplete="off"
							enctype="multipart/form-data"
						>
							<div className={`input-box mb-4`}>
								<label className="mb-1" htmlFor="name">
									{language.Profile.info.Fullname}
								</label>
								<input
									type="text"
									name="name"
									className="p-3"
									placeholder="Full name"
									style={{ borderRadius: 5 }}
									value={user_input?.name}
									onChange={onChange}
								/>
							</div>
							<div className={`input-box mb-4`}>
								<label htmlFor="username" className="mb-1">
									{language.Profile.info.Username}
								</label>
								<input
									type="text"
									name="username"
									className="p-3"
									placeholder="Enter your username"
									style={{ borderRadius: 5 }}
									value={user_info?.username}
									disabled
									style={{ backgroundColor: "#f5f5f5" }}
								/>
							</div>
							<div className={`input-box mb-4`}>
								<label htmlFor="email" className="mb-1">
									{language.Profile.info.Email}
								</label>
								<input
									type="text"
									name="email"
									className="p-3"
									placeholder="Email address"
									style={{ borderRadius: 5 }}
									value={user_info?.email}
									disabled
									style={{ backgroundColor: "#f5f5f5" }}
								/>
							</div>
							<div className={`input-box mb-4`}>
								<label htmlFor="tittle" className="mb-1">
									{language.Profile.info.Phone}
								</label>
								<input
									type="text"
									name="phone"
									className="p-3"
									placeholder="017XXXXXXXXXX"
									style={{ borderRadius: 5 }}
									value={user_input?.phone}
									disabled
									onChange={onChange}
									style={{ backgroundColor: "#f5f5f5" }}
								/>
							</div>
							<div className={`input-box mb-4`}>
								<label htmlFor="tittle" className="mb-1">
									{language.Profile.info.DOB}
								</label>
								<input
									type="date"
									name="dob"
									className="p-3"
									style={{ borderRadius: 5 }}
									value={user_input?.dob}
									onChange={onChange}
								/>
							</div>
							<div
								// className={`otp-submit mb-4  ${
								// 	disabled || !isResendDisabled ? "otp-submit-disabled" : ""
								// }`}
								className="otp-submit mb-4"
							>
								<CustomButton
									// type={disabled || !isResendDisabled ? "button" : "submit"}
									// value="Submit OTP"
									// className="w-100"
									disabled={is_loading}
									type={"submit"}
									style={{
										padding: "15px 30px",
									}}
								>
									{language.Profile.info.SaveChanges}
								</CustomButton>
							</div>
						</form>
					</Col>

					<hr />
					<Col sm={3}></Col>
				</Row>
			</div>
		</>
	);
}
