import { Card, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useOrderHistoryQuery } from "src/redux/course/courseApi";
import { lanChange } from "src/utils";
import BreadcrumbOne from "../common/BreadcrumbOne";
import Loading from "../common/Loading";

export default function OrderHistory() {
	const { data, isLoading } = useOrderHistoryQuery();
	const language = lanChange();

	if (isLoading) {
		return <Loading />;
	}
	//   console.log(purchaseHistory);
	return (
		<>
			<BreadcrumbOne
				rootUrl="/"
				parentUrl={language.PurchaseHistory.breadcrumb.parent}
				currentUrl={language.PurchaseHistory.breadcrumb.current}
			/>
			<div className="container">
				<h6 className="mt-4">{language.PurchaseHistory.title}</h6>
				<hr />
				{data?.data?.length > 0 ? (
					<>
						{data?.data?.map((row) => (
							<Card style={{ padding: "24px", marginBottom: 20 }}>
								<Row className="justify-content-between align-items-center">
									<Col sm={6}>
										<p className="mb-0">
											<Moment format="DD-MM-YYYY hh:mm">
												{row?.created_at}
											</Moment>
										</p>

										<h5 className="mb-0">{row?.course_title}</h5>
									</Col>
									<Col sm={3} className="text-end">
										<h4 className="mb-0">{row?.order_amount} BDT</h4>
									</Col>
								</Row>
							</Card>
						))}
					</>
				) : (
					<h5 align="center" style={{ color: "gray", marginBottom: 200 }}>
						{language.PurchaseHistory.NoPurchaseHistoryFound}
					</h5>
				)}
			</div>
		</>
	);
}
