import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { lanChange } from "src/utils";
import CustomTab from "./CustomTab";

export default function Info({ defaultTab, tab }) {
	const myCourses = useSelector((state) => state.course.myCourses);
	const language = lanChange();
	return (
		<div style={{ border: "1px solid lightgray", marginBottom: 20, borderRadius: 5 }}>
			<div style={{ padding: "0px 25px" }}>
				<div className="blog-author-wrapper p-0 pt-4">
					<div
						className="thumbnail"
						style={{ margin: "0px 30px 0px 0px", minWidth: 125, maxHeight: 91.23 }}
					>
						<img
							src={myCourses?.course?.featured_image}
							alt="Author Images"
							style={{
								width: "125px",
								height: "91.23px",
								marginRight: "20px",
							}}
						/>
					</div>
					<div className="author-content">
						<Row className="justify-content-between">
							<Col sm={12} md={12} lg={12} xl={12} xxl={12}>
								<h6 className="title" style={{ fontSize: 24 }}>
									{myCourses?.course?.title}
								</h6>
								<ul
									className="d-flex course-ls p-0 m-0"
									style={{ listStyleType: "disc" }}
								>
									<li
										className="me-4"
										style={{ fontSize: 14, fontWeight: 700, color: "black" }}
									>
										{myCourses?.subjects.length} {language.myCourse.Subjects}
									</li>
									<li
										className="mx-4 font-weight-bold"
										style={{ fontSize: 14, fontWeight: 700, color: "#FF7B1B" }}
									>
										{myCourses?.in_progres} {language.myCourse.InProgress}
									</li>
									<li
										className="mx-4 font-weight-bold"
										style={{ fontSize: 14, fontWeight: 700, color: "#0E9C24" }}
									>
										{myCourses?.complete} {language.myCourse.InComplete}
									</li>
								</ul>
								<p
									style={{ color: "black", margin: 0 }}
									dangerouslySetInnerHTML={{
										__html: myCourses?.course?.short_description,
									}}
								>
									{myCourses?.course?.description}
								</p>
							</Col>
						</Row>
					</div>
				</div>
			</div>

			<Row>
				<Col sm={12}>
					<CustomTab defaultTab={defaultTab} tab={tab} />
				</Col>
			</Row>
		</div>
	);
}
