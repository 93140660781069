import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Success } from "src/assets/images/success.svg";
import { ReactComponent as What } from "src/assets/images/what.svg";

export default function ExamSuccessModal({
	course_id,
	show,
	onHide,
	secondsRemaining,
	onSubmit,
	isLoading,
	data,
}) {
	const navigate = useNavigate();
	// console.log(data);
	return (
		<Modal
			show={show}
			onHide={secondsRemaining ? onHide : null}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			fullscreen="sm-down"
		>
			<div className="container px-4" style={{}}>
				<Modal.Body>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
							padding: "34px",
						}}
					>
						{data?.status === "success" ? <Success /> : <What />}

						<h6 className="mt-4 mb-2">
							{data?.status === "success"
								? data?.data?.exam_status
								: "Do you want to quit the quiz?"}
						</h6>
						{data?.status === "success" && (
							<p className="m-0">
								Your score is: {data?.data?.score ? data?.data?.score : 0}
							</p>
						)}
					</div>
					{data?.status === "success" ? (
						<button
							className="btn btn-primary p-4 w-100"
							onClick={() => {
								navigate(-1);
								onHide();
							}}
							style={{
								fontSize: "16px",
								fontWeight: 700,
							}}
						>
							Back to list
						</button>
					) : secondsRemaining ? (
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
								margin: "10px 60px",
							}}
						>
							<Col sm={5}>
								<button
									className="btn btn-outline-danger p-4 w-100 mt-4"
									onClick={() => {
										if (course_id) {
											navigate(`/my-course/${course_id}`);
										} else {
											navigate(-1);
										}
										onHide();
									}}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									Quit
								</button>
							</Col>
							<Col sm={5}>
								<button
									className="btn btn-primary p-4 w-100 mt-4"
									onClick={onHide}
									style={{
										fontSize: "16px",
										fontWeight: 700,
									}}
								>
									Stay here
								</button>
							</Col>
						</Row>
					) : (
						<button
							type="button"
							className="btn btn-primary p-4 w-100 "
							onClick={onSubmit}
							style={{
								fontSize: "16px",
								fontWeight: 700,
							}}
							disabled={isLoading}
						>
							Submit Exam
						</button>
					)}
				</Modal.Body>
			</div>
		</Modal>
	);
}
