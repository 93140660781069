import { useParams } from "react-router-dom";
import WrittenQuestion from "src/components/WrittenQuestion";
import Loading from "src/components/common/Loading";
import { useGetModelTestWiseQuestionQuery } from "src/redux/course/courseApi";

export default function WrittenExam() {
	const { exam_id } = useParams();
	const { data, isLoading } = useGetModelTestWiseQuestionQuery({ exam_id });
	const lang = localStorage.getItem("lang");

	if (isLoading) return <Loading />;
	const questions = data?.data?.question.map((item, i) => ({
		id: item.id,
		sl: i + 1,
		text: lang === "en" ? item.title : item.title_bn,
	}));

	return (
		<>
			<WrittenQuestion questionsItem={questions} questionInfo={data?.data?.exam_info} />
		</>
	);
}
