import { lanChange } from "src/utils";
import SectionTitle from "./SectionTitle";

export default function DownloadApp() {
	const language = lanChange();
	return (
		<div
			id="download"
			className="edu-newsletter-area bg-image newsletter-style-3 edu-section-gap bg-color-primary"
			style={{
				backgroundImage: "url(images/download-app/download.jpg)",
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className="container">
				<div className="row g-5 align-items-center">
					<div className="col-lg-8">
						<div className="inner">
							<SectionTitle
								classes="text-white text-start mb-3"
								slogan=""
								title={language.home.downloadApp.title}
							/>
							<h6
								className="mb-3"
								style={{
									width: "70%",
									color: "white",
									fontSize: "16px",
									fontWeight: "500",
								}}
							>
								{language.home.downloadApp.subTitle}
							</h6>

							<div className="row">
								<div className="col-lg-3 col-md-3 col-sm-3 col-12">
									<a
										href="https://play.google.com/store/apps/details?id=com.sslwireless.legalized.app"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="/images/download-app/google-play.svg"
											alt="download-app"
											style={{
												height: "80px",
												width: "200px",
											}}
										/>
									</a>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 col-12">
									<a
										href="https://apps.apple.com/us/app/legalised/id6468378862"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="/images/download-app/app-store.svg"
											alt="download-app"
											style={{
												height: "80px",
												width: "200px",
											}}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
