import {
	contactRequestEndpoint,
	homePageEndpoint,
	newsLetterEndpoint,
	policyDetailsEndpoint,
} from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";

export const homeApi = mainServiceApi.enhanceEndpoints({ addTagTypes: ["home"] }).injectEndpoints({
	endpoints: (builder) => ({
		getHomePageInfo: builder.query({
			query: () => ({
				url: homePageEndpoint,
				method: "GET",
				responseHandler: async (response) => {
					return await response.json();
				},
			}),

			transformResponse: (response) => {
				return response.data;
			},
		}),

		contactUs: builder.mutation({
			query: (data) => {
				return {
					url: contactRequestEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		newsLetter: builder.mutation({
			query: (data) => {
				return {
					url: newsLetterEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),

		getPrivacyDetails: builder.query({
			query: (slug) => ({
				url: policyDetailsEndpoint + "/" + slug,
				method: "GET",
				responseHandler: async (response) => {
					return await response.json();
				},
			}),

			transformResponse: (response) => {
				return response.data;
			},
		}),
	}),
});

export const {
	useGetHomePageInfoQuery,
	useContactUsMutation,
	useNewsLetterMutation,
	useGetPrivacyDetailsQuery,
} = homeApi;
