import { Link, useParams, useSearchParams } from "react-router-dom";

import CustomAccordion from "./CustomAccordion";
import { Icon } from "./TabForSubject";
export const TYPE = {
	Text: "Reading:",
	Audio: "Audio:",
	Video: "Video:",
	FlashCard: "Flashcard:",
	Exam: "Quiz",
};
export default function TabsBody({ item }) {
	const { course_id, subject_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter_type = searchParams.get("chapter_type");
	const course_type = searchParams.get("course_type");

	const content =
		Array.isArray(item?.content_lessons) && item?.content_lessons.length > 0 ? (
			item?.content_lessons.map((lesson) => {
				return (
					<Link
						key={lesson.id}
						to={`/my-course/${course_id}/subject/${subject_id}/chapters/${
							item.id
						}?lesson_id=${lesson.id}&chapter_type=${chapter_type}${
							course_type ? `&course_type=${course_type}` : ""
						}`}
					>
						<div className="d-flex align-items-center my-4">
							<div>
								{lesson?.is_done ? Icon["complete"] : Icon[lesson?.content_type]}
							</div>
							<dic>
								<p
									className="m-0"
									style={{ color: "black", fontSize: 18, fontWeight: 500 }}
								>
									{lesson.name}
								</p>
								<p className="m-0 fs-4">
									{TYPE[lesson?.content_type]}{" "}
									{lesson?.content_type === "FlashCard"
										? lesson?.total_count
										: lesson?.duration}
								</p>
							</dic>
						</div>
					</Link>
				);
			})
		) : (
			<p className="text-center mt-4">No lesson found!</p>
		);
	return (
		<div>
			{/* <div
				style={{
					padding: "20px 20px",
					border: "1px solid lightgray",
					marginBottom: 20,
					borderRadius: 5,
				}}
			>
				<h6 style={{ fontSize: 16 }}>
					Preliminary definition Bangladesh bar council members, tenure, elections,
					chairman & vice-chairman committee
				</h6>
				<span className="me-4" style={{ fontWeight: 400 }}>
					{Icon["media"]}
					<strong style={{ color: "#f98a17" }}>20m of</strong> videos left{" "}
				</span>
				<span className="me-4" style={{ fontWeight: 400 }}>
					{Icon["book"]}
					<strong style={{ color: "#f98a17" }}>2h 30m</strong> of readings left
				</span>
				<span className="me-4" style={{ fontWeight: 400 }}>
					{Icon["file"]}
					<strong style={{ color: "#f98a17" }}>1</strong> written test left
				</span>
				<span className="me-4" style={{ fontWeight: 400 }}>
					{Icon["file"]}
					<strong style={{ color: "#f98a17" }}>1</strong> model test left
				</span>
				<span className="me-4" style={{ fontWeight: 400 }}>
					{Icon["file"]}
					<strong style={{ color: "#f98a17" }}>4</strong> quizzes left
				</span>
			</div> */}
			<CustomAccordion
				defaultAccordion="1"
				items={[
					{
						id: "1",
						header: item.name,
						body: <div style={{ padding: "0px 20px" }}>{content}</div>,
					},
				]}
			/>
		</div>
	);
}
