import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import img from "src/assets/images/photo.svg";
import { lanChange } from "src/utils";
import CourseTypeThree from "../common/CourseTypeThree";
export default function Courses() {
	const [courses, setCourses] = React.useState([]);
	const [show, setShow] = React.useState(false);
	const { slug } = useParams();
	const allCourses = useSelector((state) => state.course.allCourses);
	const { legal_category, corporate_category } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();
	const handleClose = () => setShow(false);
	useEffect(() => {
		if (slug) {
			const getCourseByCategory = allCourses?.filter((item) => item?.category?.slug === slug);
			setCourses(getCourseByCategory);
		}
	}, [slug]);

	return (
		<div className="edu-course-area edu-section-gap bg-color-white">
			<div className="container">
				{slug == "Legal-Professional" ? (
					<div className="row g-5 mt--10">
						<div className="section-title text-center">
							<h3 className="title">{language.category.title}</h3>
							<p className="description">{language.category.subTitle}</p>
						</div>
						{Array.isArray(courses) && courses?.length ? (
							courses.map((item) => (
								<div className="col-12 col-sm-6 col-lg-4" key={item.id}>
									<CourseTypeThree data={item} />
								</div>
							))
						) : (
							<h3 align="center" style={{ color: "gray", marginTop: "20px" }}>
								{language.category.NoCoursesFound}
							</h3>
						)}
					</div>
				) : slug == "Corporate-Compliance" ? (
					<div className="row">
						<div
							className="col-lg-6 col-12"
							style={{
								backgroundImage: `url(${img})`,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
							}}
						>
							<div className="video-thumbnail">
								<div
									className="play-btn"
									role="button"
									onClick={() => setShow(true)}
								>
									<i className="fa fa-play-circle" aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<h5>Local Corporate Compliance Training</h5>
							<p
								dangerouslySetInnerHTML={{
									__html: corporate_category?.corporate_content,
								}}
							></p>
							{/* <button className="edu-btn btn-medium header-button">
								Course will be available soon
							</button> */}
						</div>
					</div>
				) : (
					<div className="row">
						<div
							className="col-lg-6 col-12"
							style={{
								backgroundImage: `url(${img})`,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
							}}
						>
							<div className="video-thumbnail">
								<div
									className="play-btn"
									role="button"
									onClick={() => setShow(true)}
								>
									<i className="fa fa-play-circle" aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<h5>Legal Professional Training/ Courses</h5>
							<p
								dangerouslySetInnerHTML={{ __html: legal_category?.legal_content }}
							></p>
							{/* <button className="edu-btn btn-medium header-button">
								Course will be available soon
							</button> */}
						</div>
					</div>
				)}
			</div>
			<Modal show={show} onHide={handleClose} centered dialogClassName="modal-60w">
				<Modal.Header
					style={{
						padding: "5px 10px",
					}}
					closeButton
				/>
				<Modal.Body style={{ margin: "5px" }}>
					<iframe
						width="600"
						height="320"
						src={
							slug == "Corporate-Compliance"
								? corporate_category?.corporate_video
								: legal_category?.legal_video
						}
						title="YouTube Video Player"
						allowFullScreen
					></iframe>
				</Modal.Body>
			</Modal>
		</div>
	);
}
