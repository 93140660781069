import { useSelector } from "react-redux";
import GalleryItem from "src/components/Gallery/GalleryItem";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import SEO from "src/components/common/SEO";
import { lanChange } from "src/utils";

const GalleryGridPage = () => {
	const { gallery } = useSelector((state) => state.homePageInfo.home);

	const language = lanChange();

	return (
		<>
			<SEO title={language.home.galleryAndEvents.title} />

			<BreadcrumbOne
				title={language.home.galleryAndEvents.title}
				rootUrl="/"
				parentUrl={language.category.breadcrumb.parent}
				currentUrl={language.home.galleryAndEvents.title}
			/>
			<div className="edu-gallery-grid-area masonary-wrapper-activation edu-section-gap bg-image overflow-hidden">
				<div className="container">
					<div className="row g-5 align-items-center">
						<div className="col-lg-6">
							<div className="section-title text-start">
								<span className="pre-title">
									{language.home.galleryAndEvents.subTitle}
								</span>
								<h3 className="title">{language.home.galleryAndEvents.title}</h3>
							</div>
						</div>
					</div>

					<div className="eduvibe-gallery-items row g-5 mt--5">
						{gallery?.map((item) => (
							<div key={item.id} className="col-lg-4 col-md-6 col-12">
								<GalleryItem data={item} />
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default GalleryGridPage;
