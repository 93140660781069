import {
	barAssociationListEndpoint,
	chapterLessionContentEndpoint,
	chapterWiseFlashCardEndpoint,
	chapterWiseHighCourtQuestions,
	chapterWiseQuestionEndpoint,
	chapterWiseResultEndpoint,
	checkCouponEndpoint,
	courseDetailsEndpoint,
	courseFeedbackEndpoint,
	courseReviewAllEndpoint,
	courseWiseCertificateEndpoint,
	courseWiseModelTestEndpoint,
	courseWiseResultEndpoint,
	courseWiseWrittenResultEndpoint,
	discussionReplySubmitEndpoint,
	discussionSubmitEndpoint,
	examStageListEndpoint,
	examSubmitEndpoint,
	generateCertificateEndpoint,
	highCourtQuestionDetails,
	markAsReadEndpoint,
	modelTestWiseQuestionEndpoint,
	myCourseDetailsEndpoint,
	orderHistoryEndpoint,
	paySslEndpoint,
	subjectDetailsEndpoint,
	subjectWrittenContentDetailsEndpoint,
	webCourseEnrollmentEndpoint,
	webGlobalCourseSearchEndpoint,
	writtenExamSubmitEndpoint,
} from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";

export const courseApi = mainServiceApi
	.enhanceEndpoints({ addTagTypes: ["course"] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getAllCourses: builder.query({
				query: (name) => ({
					url: `${webGlobalCourseSearchEndpoint}${name}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			getCourseDetails: builder.query({
				query: ({ course_id, user_id }) => ({
					url: `${courseDetailsEndpoint}${course_id}${
						user_id ? `&user_id=${user_id}` : ""
					}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),

			hasCouponApi: builder.mutation({
				query: (data) => ({
					url: `${checkCouponEndpoint}`,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),

			getMyCourseDetails: builder.query({
				query: ({ course_id }) => ({
					url: `${myCourseDetailsEndpoint}${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getCourseWiseModelTest: builder.query({
				query: ({ course_id }) => ({
					url: `${courseWiseModelTestEndpoint}?course_id=${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getModelTestWiseQuestion: builder.query({
				query: ({ exam_id, type }) => ({
					url:
						type === "chapter_wise_question"
							? `${chapterWiseQuestionEndpoint}?exam_id=${exam_id}`
							: `${modelTestWiseQuestionEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getResult: builder.query({
				query: ({ exam_id, chapter_id }) => ({
					url: chapter_id
						? `${chapterWiseResultEndpoint}?chapter_id=${chapter_id}`
						: `${courseWiseResultEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getSubjectDetails: builder.query({
				query: ({ subject_id, chapter_type }) => ({
					url:
						chapter_type === "MCQ"
							? `${subjectDetailsEndpoint}?subject_id=${subject_id}&chapter_type=${chapter_type}`
							: `${subjectWrittenContentDetailsEndpoint}?subject_id=${subject_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => {
					// console.log(meta);
					if (response.code === 200) {
						return meta?.chapter_type === "MCQ"
							? response.data.subject_chapter
							: response.data.written_content;
					} else {
						return [];
					}
				},
			}),
			chapterLessonContent: builder.query({
				query: ({ lession_id, lession_type }) => ({
					url: `${chapterLessionContentEndpoint}?lession_id=${lession_id}&lession_type=${lession_type}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseReview: builder.query({
				query: ({ course_id }) => ({
					url: `${courseReviewAllEndpoint}${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),
			chapterWiseFlashCard: builder.query({
				query: ({ chapter_id }) => ({
					url: `${chapterWiseFlashCardEndpoint}?chapter_id=${chapter_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),
			getBarAssociationList: builder.query({
				query: () => ({
					url: barAssociationListEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) =>
					response.data.map((item) => ({
						label: item.name,
						value: item.id,
					})),
			}),
			getExamStageList: builder.query({
				query: () => ({
					url: examStageListEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) =>
					response.data.map((item) => ({
						label: item.name,
						value: item.id,
					})),
			}),

			enrollCourse: builder.mutation({
				query: (data) => ({
					url: webCourseEnrollmentEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			paySsl: builder.mutation({
				query: (data) => ({
					url: paySslEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),

			discussionSubmit: builder.mutation({
				query: (data) => ({
					url: discussionSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),

			discussionReplySubmit: builder.mutation({
				query: (data) => ({
					url: discussionReplySubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseFeedback: builder.mutation({
				query: (data) => ({
					url: courseFeedbackEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			examSubmit: builder.mutation({
				query: (data) => ({
					url: examSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			writtenExamSubmit: builder.mutation({
				query: (data) => ({
					url: writtenExamSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseWiseWrittenResult: builder.query({
				query: ({ exam_id }) => ({
					url: `${courseWiseWrittenResultEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			chapterWiseHighCourtQuestions: builder.query({
				query: ({ chapter_id }) => ({
					url: `${chapterWiseHighCourtQuestions}?chapter_id=${chapter_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			highCourtQuestionDetails: builder.query({
				query: ({ exam_id }) => ({
					url: `${highCourtQuestionDetails}/${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			markAsRead: builder.mutation({
				query: ({ lesson_id }) => ({
					url: `${markAsReadEndpoint}/${lesson_id}`,
					method: "POST",
					body: { lesson_id },
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				invalidatesTags: (result, error, arg) => [{ type: "dashboard", id: "LIST" }],
			}),
			courseWiseCertificate: builder.query({
				query: () => ({
					url: courseWiseCertificateEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			generateCertificate: builder.mutation({
				query: ({ course_id }) => ({
					url: generateCertificateEndpoint,
					method: "POST",
					body: { course_id },
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			orderHistory: builder.query({
				query: () => ({
					url: orderHistoryEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
		}),
	});

export const {
	useGetCourseDetailsQuery,
	useGetMyCourseDetailsQuery,
	useGetBarAssociationListQuery,
	useGetExamStageListQuery,
	useEnrollCourseMutation,
	usePaySslMutation,
	useDiscussionReplySubmitMutation,
	useDiscussionSubmitMutation,
	useCourseFeedbackMutation,
	useGetAllCoursesQuery,
	useCourseReviewQuery,
	useGetSubjectDetailsQuery,
	useChapterLessonContentQuery,
	useGetCourseWiseModelTestQuery,
	useGetModelTestWiseQuestionQuery,
	useExamSubmitMutation,
	useChapterWiseFlashCardQuery,
	useMarkAsReadMutation,
	useGetResultQuery,
	useWrittenExamSubmitMutation,
	useCourseWiseWrittenResultQuery,
	useChapterWiseHighCourtQuestionsQuery,
	useHighCourtQuestionDetailsQuery,
	useHasCouponApiMutation,
	useCourseWiseCertificateQuery,
	useGenerateCertificateMutation,
	useOrderHistoryQuery,
} = courseApi;
