import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetResultQuery } from "src/redux/course/courseApi";
import { setQuestionView } from "src/redux/course/courseSlice";
import Loading from "../common/Loading";

export default function QuizView() {
	const { course_id, chapter_id, subject_id, exam_id } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const lesson_id = searchParams.get("lesson_id");
	const lan = localStorage.getItem("lang");
	const { isLoading, data } = useGetResultQuery({ exam_id: exam_id });
	const questions = useSelector((state) => state.course.questionView);
	// const exam_info = useSelector((state) => state.course.questionView?.exam_info);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (data?.status === "success") {
			dispatch(setQuestionView(data.data));
		}
	}, [isLoading]);

	const onClick = () => {
		if (exam_id) {
			navigate(`/my-course/${course_id}`);
		} else {
			navigate(
				`/my-course/${course_id}/subject/${subject_id}/chapters/${chapter_id}?lesson_id=${lesson_id}`,
			);
		}
	};

	if (isLoading) return <Loading />;
	const questionsItem = questions
		? questions?.map((item, index) => {
				return {
					id: item.id,
					sl: index + 1,
					text: lan === "en" ? item.title : item.title_bn,
					type: item.type,
					options: item.question_options.map((option) => {
						const ans = parseInt(option.is_right) ? true : false;
						const currentAns = item.user_submit_results?.answer_id === option.id;
						return {
							id: option.id,
							value: lan === "en" ? option.title : option.title_bn,
							weight: option.weight,
							is_right: option.is_right,
							checked: ans || currentAns ? true : false,
						};
					}),
				};
		  })
		: null;
	return (
		<>
			{questionsItem ? (
				<form className="unselectable">
					<div style={{ padding: "20px 0px", backgroundColor: "#F4F9FF" }}>
						<div className="container">
							<Row className="justify-content-between align-items-center ">
								<Col sm={8}>
									{/* <h6 className="mb-0">{exam_info?.title || ""}</h6>
								<p className="m-0">Practice Quiz 6 min 30m per question 1 marks</p> */}
								</Col>
								<Col sm={4}>
									<h6 className="text-end m-0">
										<button
											className="btn btn-outline-primary btn-lg me-4 py-3 px-4 fs-4"
											style={{ fontWeight: 600 }}
											onClick={onClick}
										>
											Back
										</button>
									</h6>
								</Col>
							</Row>
						</div>
					</div>
					{/* <div style={{ backgroundColor: "#F4F9FF", padding: "20px 0px" }}>
						<div className="container">
							<Row className="justify-content-between align-items-center ">
								<Col sm={12}>
									<h4 className="mb-0">Congratulations! You Passed!</h4>
									<h4 className="mb-0" style={{ color: "#f98a17" }}>
										Your Score: 80%
									</h4>
								</Col>
							</Row>
						</div>
					</div> */}

					<div className="container my-4">
						<div className="questions">
							{questionsItem.map((question, qIndex) => (
								<div key={question.id} style={{ contain: `${question.sl}` }}>
									<h6 className="mb-0">
										{question.sl}. {question.text}
									</h6>
									<div className="mt-4">
										{question.options.map((option, optIndex) => (
											<div key={option.id} className="mb-3 ms-4">
												<input
													type={
														question.type === "Multiple"
															? "checkbox"
															: "radio"
													}
													name={`${question.id}-${option.id}`}
													id={`${question.id}-${option.id}`}
													value={option.id}
													checked={option.checked}
													disabled={true}
												/>
												<label
													htmlFor={`${question.id}-${option.id}`}
													className={`ms-2  ${
														parseInt(option.is_right) && option?.checked
															? "mcq-view-label-green"
															: "mcq-view-label-red"
													}`}
													style={{ cursor: "pointer", color: "#000000" }}
												>
													{option.value}
												</label>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</form>
			) : (
				<Loading />
			)}
		</>
	);
}
