import { useEffect } from "react";
import { BsCheckLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useMarkAsReadMutation } from "src/redux/course/courseApi";
import { setChapter } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";
import CustomButton from "../common/CustomButton";
import CustomLoading from "../common/CustomLoading";
import useChapter from "./Hook";
export default function Audio({ item, refetch }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const lesson_id = searchParams.get("lesson_id");
	const lan = localStorage.getItem("lang");
	const dispatch = useDispatch();
	const language = lanChange();
	const [markAsRead, { isLoading, data }] = useMarkAsReadMutation();
	useChapter({
		lession_id: item.id,
		lession_type: item.content_type,
	});
	const chapter = useSelector((state) => state.course.chapter);

	useEffect(() => {
		if (data?.status === "fail") {
			toast.error(data.message);
		}
		if (data?.status === "success") {
			toast.success(data.message);
			refetch();
			dispatch(setChapter({ ...chapter, is_done: true }));
		}
	}, [isLoading]);

	useEffect(() => {
		return () => dispatch(setChapter(null));
	}, []);
	// console.log(lan);
	// console.log(chapter);

	return chapter ? (
		<div>
			<h6>{lan === "en" ? chapter.name : chapter.name_bn}</h6>
			{chapter?.video_url && (
				<iframe
					width="560"
					height="315"
					src={item?.video_url}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					style={{
						marginBottom: 20,
					}}
				></iframe>
			)}

			<div
				dangerouslySetInnerHTML={{
					__html: lan === "en" ? chapter.content : chapter.content_bn,
				}}
			></div>
			<div className="">
				{chapter.is_done ? (
					<h6
						className="btn btn-success "
						style={{
							fontWeight: "bold",
							fontSize: "14px",
							color: "#0E9C24",
							border: "none",
							backgroundColor: "transparent",
						}}
					>
						<BsCheckLg className="me-2" style={{ fontSize: "20px" }} />
						{language.subjectAndChapter.Completed}
					</h6>
				) : (
					<CustomButton
						className="btn btn-primary me-2"
						style={{ fontWeight: "bold", fontSize: "14px", padding: "10px 30px" }}
						onClick={() => {
							markAsRead({ lesson_id });
						}}
						disabled={isLoading}
					>
						{language.subjectAndChapter.MarkAsComplete}
					</CustomButton>
				)}
			</div>

			{chapter?.file && (
				<audio className="w-100 mt-4" controls>
					<source
						src={lan === "en" ? chapter?.file : chapter?.file_bn}
						type="audio/mpeg"
					/>
					Your browser does not support the audio element.
				</audio>
			)}
		</div>
	) : (
		<CustomLoading height={30} />
	);
}
