import { BsFillTelephoneFill } from "react-icons/bs";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LOGO from "src/assets/images/logo/logo-white.png";

import { lanChange } from "src/utils";
import ScrollTopButton from "./ScrollTopButton";
const Footer = () => {
	const { contact, policies } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();
	return (
		<>
			<footer
				id="download"
				className="edu-footer footer-style-default"
				style={{ backgroundColor: "#b94a00" }}
			>
				<div className="footer-top">
					<div className="container eduvibe-animated-shape">
						<div className="row g-5">
							<div className="col-lg-3 col-md-6 col-sm-12 col-12">
								<div className="edu-footer-widget">
									<div className="logo">
										<Link to={process.env.PUBLIC_URL + "/"}>
											<img
												className="logo-light"
												src={LOGO}
												alt="Footer Logo"
												style={{ width: "150px" }}
											/>
										</Link>
									</div>
									<p className="description">{language.footer.description}</p>
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget explore-widget">
									<h5 className="widget-title">
										{language.footer.explore.title}
									</h5>
									<div className="inner">
										<ul className="footer-link link-hover">
											<li>
												<Link to="/about">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.about}
												</Link>
											</li>
											<li>
												<Link to="/contact-us">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.contact}
												</Link>
											</li>
											<li>
												<Link to="/gallery-grid">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.galleryAndEvents}
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget quick-link-widget">
									<h5 className="widget-title">
										{language.footer.usefulLinks.title}
									</h5>
									<div className="inner">
										<ul className="footer-link link-hover">
											{policies && Array.isArray(policies)
												? policies.map((policy, index) => (
														<li key={index} style={{ color: "white" }}>
															<Link
																to={`/privacy-details/${policy.slug}`}
															>
																<MdKeyboardDoubleArrowRight
																	style={{
																		verticalAlign: "sub",
																		fontSize: "22px",
																	}}
																/>
																{policy.policy_type}
															</Link>
														</li>
												  ))
												: null}
											{/* <li>
												<Link to="/privacy-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.privacyPolicy}
												</Link>
											</li>
											<li>
												<Link to="/terms-condition">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.termsAndConditions}
												</Link>
											</li>
											<li>
												<Link to="/refund-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.refundPolicy}
												</Link>
											</li>
											<li>
												<Link to="/deletion-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.deletionPolicy}
												</Link>
											</li> */}
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget">
									<h5 className="widget-title">
										{language.footer.contactInfo.title}
									</h5>
									<div className="inner">
										<div className="widget-information">
											<ul className="information-list">
												<li>
													<CiLocationOn
														style={{
															verticalAlign: "bottom",
															fontSize: "22px",
															marginRight: "10px",
														}}
													/>
													{contact?.location ||
														`Legalized
													Education Bangladesh Ltd 
													Flat 2A, House 88, Road 17/A, Block E, Banani,
													Dhaka`}
												</li>
												<li>
													<BsFillTelephoneFill
														style={{
															verticalAlign: "bottom",
															fontSize: "16px",
															marginRight: "10px",
														}}
													/>
													<a
														href={`tel:${
															contact?.phone || "+880 1879167052"
														}`}
													>
														{contact?.phone || "+880 1879167052"}
													</a>
												</li>
												<li>
													<CiMail
														style={{
															verticalAlign: "bottom",
															fontSize: "20px",
															marginRight: "10px",
														}}
													/>
													<a
														target="_blank"
														href={`mailto: ${
															contact?.email ||
															"legalizededucationbd@gmail.com"
														}`}
														rel="noreferrer"
													>
														{contact?.email ||
															"legalizededucationbd@gmail.com"}
													</a>
												</li>
											</ul>
											<br />
											<ul
												className="social-share mb-5"
												style={{ color: "white" }}
											>
												<li style={{ color: "white" }}>
													{language.ETradeLicenseno}: <br /> TRAD/
													DNCC006559/2022
												</li>
												<li style={{ color: "white" }}>
													TIN : 668324194352
												</li>
											</ul>

											<ul className="social-share">
												<li
													style={{
														backgroundColor: "#762900",
														borderRadius: "5px",
													}}
												>
													<a
														href="https://www.facebook.com/ucepbd.org/"
														target="_blank"
														rel="noreferrer"
													>
														<FaFacebookF
															style={{ verticalAlign: "baseline" }}
														/>
													</a>
												</li>
												<li
													style={{
														backgroundColor: "#762900",
														borderRadius: "5px",
													}}
												>
													<a
														href="https://www.linkedin.com/company/ucep-bd/?originalSubdomain=bd"
														target="_blank"
														rel="noreferrer"
													>
														<FaLinkedinIn
															style={{ verticalAlign: "baseline" }}
														/>
													</a>
												</li>
												<li
													style={{
														backgroundColor: "#762900",
														borderRadius: "5px",
													}}
												>
													<a
														href="https://www.instagram.com/explore/locations/477813119088054/ucep-technical-school/"
														target="_blank"
														rel="noreferrer"
													>
														<FaInstagram
															style={{ verticalAlign: "baseline" }}
														/>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-lg-5 col-md-5 col-sm-5 col-12">
								<Link
									to="/download-app"
									target="_blank"
									rel="noreferrer"
									className="me-4"
									style={{
										backgroundColor: "#762900",
										fontWeight: "bold",
										color: "#fff",
										padding: "10px 20px",
										borderRadius: "5px",
										fontSize: "24px",
									}}
								>
									{language.footer.downloadApp}
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div
					className="copyright-area copyright-default"
					style={{
						backgroundColor: "#a33b06",
					}}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="inner text-center">
									<p className="my-3">
										Copyright {new Date().getFullYear()}.All Rights Reserved
									</p>
								</div>
							</div>
							{/* <div className="col-lg-6">
								<div className="inner text-end">
									<p>
										Designed & Developed By &nbsp;
										<a
											href="https://sslwireless.com/"
											target="_blank"
											rel="noreferrer"
										>
											SSL Wireless
										</a>
									</p>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</footer>
			<ScrollTopButton />
		</>
	);
};

export default Footer;
