import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
	const isLoggedIn = useSelector((state) => state.auth.user);
	const navigate = useNavigate();

	if (isLoggedIn) {
		return <>{children}</>;
	} else {
		return navigate("/");
	}
};

export default PrivateRoute;
