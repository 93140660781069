import React from "react";

export default function Video({ title = "", video = "", content = "" }) {
	return (
		<div>
			<h6>Introduction</h6>

			<video className="w-100" controls>
				<source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
			</video>
			<h6>Transcript</h6>
			<ol start="0:01">
				<li>
					0:01 Specific relief refers to the remedies sought by the plaintiff in a civil
					case that are specific to their request, as provided by the Specific Relief Act.
					(উত্তর: সুনির্দিষ্ট প্রতিকার বলতে দেওয়ানী মামলায় বিচারিক আবেদনকারীদের দাবি করা
					প্রতিকারগুলিকে বলা হয়, যা সুনির্দিষ্ট প্রতিকার আইন দ্বারা প্রদান করা হয়।)
				</li>
				<li>
					0:30 Specific relief refers to the remedies sought by the plaintiff in a civil
					case that are specific to their request, as provided by the Specific Relief Act.
					(উত্তর: সুনির্দিষ্ট প্রতিকার বলতে দেওয়ানী মামলায় বিচারিক আবেদনকারীদের দাবি করা
					প্রতিকারগুলিকে বলা হয়, যা সুনির্দিষ্ট প্রতিকার আইন দ্বারা প্রদান করা হয়)
					Specific relief refers to the remedies sought by the plaintiff in a civil case
					that are specific to their request, as provided by the Specific Relief Act.
					(উত্তর: সুনির্দিষ্ট প্রতিকার বলতে দেওয়ানী মামলায় বিচারিক আবেদনকারীদের দাবি করা
					প্রতিকারগুলিকে বলা হয়, যা সুনির্দিষ্ট প্রতিকার আইন দ্বারা প্রদান করা হয়।)
				</li>
				<li>
					0:30 Specific relief refers to the remedies sought by the plaintiff in a civil
					case that are specific to their request, as provided by the Specific Relief Act.
					(উত্তর: সুনির্দিষ্ট প্রতিকার বলতে দেওয়ানী মামলায় বিচারিক আবেদনকারীদের দাবি করা
					প্রতিকারগুলিকে বলা হয়, যা সুনির্দিষ্ট প্রতিকার আইন দ্বারা প্রদান করা হয়।)
				</li>
				<li>
					0:30 Specific relief refers to the remedies sought by the plaintiff in a civil
					case that are specific to their request, as provided by the Specific Relief Act.
					(উত্তর: সুনির্দিষ্ট প্রতিকার বলতে দেওয়ানী মামলায় বিচারিক আবেদনকারীদের দাবি করা
					প্রতিকারগুলিকে বলা হয়, যা সুনির্দিষ্ট প্রতিকার আইন দ্বারা প্রদান করা হয়।)
				</li>
			</ol>
		</div>
	);
}
