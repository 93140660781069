import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { lanChange } from "src/utils";

const BreadcrumbOne = ({ title, rootUrl, parentUrl, currentUrl, meddle = null }) => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.auth.user);
	const language = lanChange();
	return (
		<div
			className={` breadcrumb-style-1  bg-color bg-image ${
				user ? "" : "edu-breadcrumb-area "
			}`}
			style={
				user
					? { backgroundColor: "#F4F9FF" }
					: { paddingTop: "130px", paddingBottom: "60px" }
			}
		>
			<div className="container eduvibe-animated-shape">
				<div className="d-block">
					<div className="breadcrumb-inner text-start">
						{/* <div className="page-title">
								<h3
									className="title"
									dangerouslySetInnerHTML={{ __html: title }}
								></h3>
							</div> */}
						<Row className="justify-content-between align-items-center">
							<Col sm={6} md={10} lg={10}>
								<nav
									className={`edu-breadcrumb-nav `}
									style={user ? { padding: "20px 0px" } : {}}
								>
									<ol className="edu-breadcrumb d-flex justify-content-start liststyle">
										<li className="breadcrumb-item">
											<Link
												to={`${rootUrl}`}
												dangerouslySetInnerHTML={{ __html: parentUrl }}
											></Link>
										</li>
										<IoChevronForwardOutline
											style={{
												color: "gray",
												fontSize: 14,
												fontWeight: 700,
												margin: "0px 5px",
											}}
										/>
										{meddle &&
											meddle.map((item, index) => {
												return (
													<React.Fragment key={index}>
														<li className="breadcrumb-item">
															<Link
																to={item.url}
																dangerouslySetInnerHTML={{
																	__html: item.name,
																}}
															></Link>
														</li>
														<IoChevronForwardOutline
															style={{
																color: "gray",
																fontSize: 14,
																fontWeight: 700,
																margin: "0px 5px",
															}}
														/>
													</React.Fragment>
												);
											})}
										<li
											className="breadcrumb-item active"
											dangerouslySetInnerHTML={{ __html: currentUrl }}
										></li>
									</ol>
								</nav>
							</Col>
							<Col
								sm={6}
								md={2}
								lg={2}
								className="text-end"
								role="button"
								onClick={() => {
									navigate(-1);
								}}
								style={{
									cursor: "pointer",
								}}
							>
								<IoChevronBackOutline
									style={{
										color: "gray",
										fontSize: 14,
										fontWeight: 700,
										margin: "0px 5px 0px 0px",
									}}
								/>
								<span>{language.subjectAndChapter.back}</span>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BreadcrumbOne;
