import { MDBCheckbox } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { setCourseFilter } from "src/redux/course/courseSlice";

export default function CategoriesFilter() {
	const dispatch = useDispatch();
	const { filter = {} } = useSelector((state) => state.course);

	const onChange = (id, value) => {
		const categoriesFilter = filter.categoriesFilter.map((item) => {
			if (item.id === id) {
				return { ...item, checked: value };
			} else {
				return { ...item };
			}
		});

		dispatch(setCourseFilter({ ...filter, categoriesFilter: categoriesFilter }));
	};

	return (
		<>
			{Array.isArray(filter.categoriesFilter) && filter.categoriesFilter.length
				? filter.categoriesFilter.map((item) => (
						<div key={item.id} className="d-flex  justify-content-between mb-2">
							<div>
								<MDBCheckbox
									name={`CategoriesFilter`}
									id={`CategoriesFilter-${item.id}`}
									value={item.id}
									label={item.label}
									checked={item.checked}
									onChange={(e) => onChange(item.id, e.target.checked)}
								/>
							</div>
							<div>{item.totalSubject}</div>
						</div>
				  ))
				: null}
		</>
	);
}
