import { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { BsSend } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Comment from "src/components/CourseDetails/Comment";
import CourseInfo from "src/components/CourseDetails/CourseInfo";
import EnrollModal from "src/components/CourseDetails/EnrollModal";
import Feedback from "src/components/CourseDetails/Feedback";
import BreadcrumbOne from "src/components/common/BreadcrumbOne";
import CustomAccordion from "src/components/common/CustomAccordion";
import CustomButton from "src/components/common/CustomButton";
import Loading from "src/components/common/Loading";
import SEO from "src/components/common/SEO";

import { isModalOpen, userModalType } from "src/redux/auth/authSlice";
import {
	useCourseFeedbackMutation,
	useCourseReviewQuery,
	useEnrollCourseMutation,
	useGetBarAssociationListQuery,
	useGetCourseDetailsQuery,
	useGetExamStageListQuery,
	useHasCouponApiMutation,
	usePaySslMutation,
} from "src/redux/course/courseApi";

import { setCourse } from "src/redux/course/courseSlice";
import { getTimeDifference, lanChange, sortByDate } from "src/utils";

const CourseDetails = () => {
	const [contentTab, setContentTab] = useState("overview");
	// const [showReply, setShowReply] = useState({});
	const [rating, setRating] = useState(1);
	const [feedback, setFeedback] = useState("");
	const [iframe, setIframe] = useState("");
	const [show, setShow] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const courseItem = useSelector((state) => state.course.course);
	const dispatch = useDispatch();
	const [enroll, setEnroll] = useState({});
	const [coupon, setCoupon] = useState(null);
	const [checkCoupon, setCheckCoupon] = useState(null);
	const [seeMore, setSeeMore] = useState(3);
	const { id } = useParams();
	const lang = localStorage.getItem("lang");
	const language = lanChange();

	// api call
	const { isSuccess, isLoading, data, refetch, isFetching } = useGetCourseDetailsQuery({
		course_id: id,
		user_id: user?.user?.id,
	});
	const { isSuccess: isSuccessBarAssociationList, data: barAssociationList } =
		useGetBarAssociationListQuery("", { skip: !user });
	const { isSuccess: isSuccessExamStageList, data: examStageList } = useGetExamStageListQuery(
		"",
		{ skip: !user },
	);
	const [
		enrollCourse,
		{
			isSuccess: isSuccessEnrollCourse,
			isLoading: isLoadingEnrollCourse,
			data: enrollCourseRes,
		},
	] = useEnrollCourseMutation();
	const { data: courseReviewRes } = useCourseReviewQuery({
		course_id: id,
	});
	const [paySsl, { isSuccess: isSuccessPaySsl, isLoading: isLoadingPaySsl, data: paySslRes }] =
		usePaySslMutation();
	const [
		courseFeedback,
		{
			isLoading: isLoadingCourseFeedback,
			isSuccess: isSuccessCourseFeedback,
			data: courseFeedbackRes,
		},
	] = useCourseFeedbackMutation();
	const [
		hasCouponApi,
		{ isLoading: isLoadingHasCoupon, data: couponRes, isError: isErrorCouponRes },
	] = useHasCouponApiMutation();

	// const onReply = (item) => {
	// 	setShowReply({ ...showReply, [item.id]: !showReply[item.id] });
	// };
	const handleTab = (content) => {
		if (content === "overview") {
			setContentTab("overview");
		} else if (content === "curriculum") {
			setContentTab("curriculum");
		} else if (content === "instructor") {
			setContentTab("instructor");
		} else if (content === "reviews") {
			setContentTab("reviews");
		}
	};

	const onFeedbackSubmit = (e) => {
		e.preventDefault();
		if (rating && feedback) {
			courseFeedback({
				feedback,
				rating,
				course_id: courseItem.course.id,
			});
		}
	};

	const onCouponSubmit = (e) => {
		e.preventDefault();
		if (checkCoupon) {
			hasCouponApi({
				coupon_code: checkCoupon,
				order_amount:
					courseItem.course.special_price > 0
						? courseItem.course.special_price
						: courseItem.course.price,
				course_id: courseItem.course.id,
			});
		}
	};
	// enroll course
	useEffect(() => {
		if (enrollCourseRes?.status === "fail") {
			toast.error(enrollCourseRes?.message || "Course enrollment failed");
			setEnroll({});
		}
		if (enrollCourseRes?.status === "success") {
			//payment

			const transaction_id = enrollCourseRes.data.transaction_id;
			const pay = {
				amount: coupon
					? coupon
					: courseItem.course.special_price || courseItem.course.price,
				transaction_id: transaction_id,
				cus_name: user.user.name,
				cus_email: user.user.email,
				cus_phone: user.user.phone,
				course_name: courseItem.course.title,
				category: courseItem.category_name,
			};
			paySsl(pay);
		}
	}, [isSuccessEnrollCourse]);
	// payment
	useEffect(() => {
		if (paySslRes?.status === "fail") {
			toast.error(paySslRes?.message);
			setEnroll({});
		}
		if (paySslRes?.status === "success") {
			// setIframe(paySslRes.data.gateway_url);
			// other outside link open current page react
			setShow(false);
			setEnroll({});
			window.location.href = paySslRes.data.gateway_url;
			// window.open(paySslRes.data.gateway_url);
		}
	}, [isSuccessPaySsl]);
	// course feedback
	useEffect(() => {
		if (courseFeedbackRes?.status === "fail") {
			toast.error(courseFeedbackRes?.message);
			setRating({});
		}
		if (courseFeedbackRes?.status === "success") {
			toast.success(courseFeedbackRes?.message);
			setRating(1);
			setFeedback("");
			refetch({
				course_id: id,
				user_id: user?.user?.id,
			});
		}
	}, [isSuccessCourseFeedback]);

	// get course details
	useEffect(() => {
		if (isSuccess) {
			dispatch(setCourse(data));
		}
	}, [isLoading, isFetching]);

	// check coupon
	useEffect(() => {
		if (couponRes?.code === 200) {
			setCoupon(couponRes?.data?.order_amount);
			toast.success(couponRes?.message);
		} else {
			toast.error(couponRes?.message);
		}
	}, [isLoadingHasCoupon]);

	if (!courseItem) {
		return <Loading />;
	}

	const feedbackStar = language.courseDetails.feedbackStar;

	let enrollFields = [
		{
			id: "1",
			type: "text",
			name: "name",
			label: "Name",
			placeholder: "Enter Full Name",
			className: "py-3",
			required: true,
			onChange: (e) => setEnroll((prev) => ({ ...prev, [e.target.name]: e.target.value })),
		},

		{
			id: "2",
			type: "text",
			name: "mobile_no",
			label: "Phone Number",
			placeholder: "Ex - 01982733918",
			className: "py-3",
			required: true,
			onChange: (e) => setEnroll((prev) => ({ ...prev, [e.target.name]: e.target.value })),
		},
		{
			id: "3",
			type: "email",
			name: "email",
			label: "Email",
			placeholder: "Enter Email Address",
			className: "py-3",
			required: true,
			onChange: (e) => setEnroll((prev) => ({ ...prev, [e.target.name]: e.target.value })),
		},
		{
			id: "7",
			type: "submit",
			disabled: isLoadingPaySsl,
			value: `Pay ${courseItem.course.special_price || courseItem.course.price} BDT`,
			// className: "rn-btn edu-btn w-100 d-block",
		},
	];

	return (
		<>
			<SEO title={courseItem.course.title} />

			<BreadcrumbOne
				rootUrl="/"
				parentUrl={language.courseDetails.breadcrumb.parent}
				currentUrl={language.courseDetails.breadcrumb.current}
			/>
			{courseItem ? (
				<div className="edu-course-details-area bg-color-white">
					<div>
						<div
							style={{
								backgroundColor: "#f98a17",
								minHeight: "30vh",
							}}
						>
							<div className="container">
								<Row className="row ">
									<Col lg={8}>
										<h4 style={{ color: "white", marginTop: 60 }}>
											{courseItem.course.title}
										</h4>
										<div
											className="short_description"
											dangerouslySetInnerHTML={{
												__html: courseItem.course.short_description,
											}}
										/>
									</Col>
								</Row>
							</div>
						</div>
						<div className="container">
							<Row className="row">
								<Col xl={4} lg={5} className="d-block d-lg-none">
									<CourseInfo data={courseItem} setShow={setShow} />
								</Col>
								<Col xl={8} lg={7}>
									<div className="course-details-content">
										<h3 className="title">{courseItem.title}</h3>
										<ul
											className="edu-course-tab nav nav-tabs"
											role="tablist"
											style={{ justifyContent: "start" }}
										>
											<li className="nav-item">
												<a
													href="#overview"
													className={
														contentTab === "overview"
															? "nav-link active"
															: "nav-link"
													}
													type="button"
													aria-label="Overview"
													onClick={() => handleTab("overview")}
												>
													{language.courseDetails.tab.overview}
												</a>
											</li>
											<li className="nav-item">
												<a
													href="#curriculum"
													className={
														contentTab === "curriculum"
															? "nav-link active"
															: "nav-link"
													}
													type="button"
													aria-label="Curriculum"
													onClick={() => handleTab("curriculum")}
												>
													{language.courseDetails.tab.curriculum}
												</a>
											</li>
											{courseItem.course?.syllabus.length > 0 ? (
												<li className="nav-item">
													<a
														href="#syllabus"
														className={
															contentTab === "syllabus"
																? "nav-link active"
																: "nav-link"
														}
														type="button"
														aria-label="Syllabus"
														onClick={() => handleTab("syllabus")}
													>
														{language.courseDetails.tab.syllabus}
													</a>
												</li>
											) : null}

											<li className="nav-item">
												<a
													href="#reviews"
													className={
														contentTab === "reviews"
															? "nav-link active"
															: "nav-link"
													}
													type="button"
													aria-label="Reviews"
													onClick={() => handleTab("reviews")}
												>
													{language.courseDetails.tab.reviews}
												</a>
											</li>
										</ul>

										<div className="tab-content">
											<div
												id="overview"
												className={`tab-pane fade show active `}
											>
												<div
													className="course-tab-content"
													dangerouslySetInnerHTML={{
														__html: courseItem.course.long_description,
													}}
												/>
												<div
													className="course-tab-content"
													dangerouslySetInnerHTML={{
														__html: courseItem.course.key_points,
													}}
												/>
											</div>

											<div
												id="curriculum"
												className={`tab-pane fade show active `}
											>
												<h5 className="mb-4">
													{language.courseDetails.tab.curriculum}
												</h5>
												<div className="course-tab-content">
													{courseItem?.course?.subject &&
													courseItem?.course?.subject?.length !== 0 ? (
														<CustomAccordion
															defaultAccordion={
																courseItem.course.subject[0].id
															}
															items={courseItem?.course?.subject?.map(
																(course, i) => ({
																	id: course.id,
																	header: (
																		<div>
																			<h6 className="m-0">
																				{
																					language
																						.courseDetails
																						.Subject
																				}{" "}
																				{i + 1}:{" "}
																				{lang === "en"
																					? course.name
																					: course.name_bn}
																			</h6>
																			<p className="m-0">
																				{
																					course.total_lession
																				}{" "}
																				{
																					language
																						.courseDetails
																						.Lessons
																				}{" "}
																				{course.quiz_count}{" "}
																				{
																					language
																						.courseDetails
																						.Quizzes
																				}{" "}
																				{
																					course.written_count
																				}{" "}
																				{
																					language
																						.courseDetails
																						.WrittenExam
																				}{" "}
																				{
																					course.flash_cards_count
																				}{" "}
																				{
																					language
																						.courseDetails
																						.FlashCards
																				}{" "}
																				{course.duration}{" "}
																				{
																					language
																						.courseDetails
																						.Duration
																				}{" "}
																			</p>
																		</div>
																	),
																	body: (
																		<div
																			style={{
																				padding: "0px 20px",
																			}}
																		>
																			<div
																				dangerouslySetInnerHTML={{
																					__html:
																						lang ===
																						"en"
																							? course.description
																							: course.description_bn,
																				}}
																			/>
																		</div>
																	),
																}),
															)}
														/>
													) : null}
												</div>
											</div>
											{courseItem?.course?.syllabus?.length > 0 ? (
												<div
													id="syllabus"
													className={`tab-pane fade show active my-5`}
												>
													<h5 className="mb-4">
														{language.courseDetails.tab.syllabus}
													</h5>
													{courseItem.course?.syllabus ? (
														<div className="course-tab-content">
															<CustomAccordion
																defaultAccordion={
																	courseItem.course?.syllabus[0]
																		?.id
																}
																items={courseItem.course?.syllabus.map(
																	(syllabus, i) => ({
																		id: syllabus.id,
																		header: (
																			<div>
																				<h6 className="m-0">
																					{lang === "en"
																						? syllabus.title
																						: syllabus.title}
																				</h6>
																				{/* <p className="m-0">
																		{course.total_lession}{" "}
																		{
																			language
																				.courseDetails
																				.Lessons
																		}{" "}
																		{course.quiz_count}{" "}
																		{
																			language
																				.courseDetails
																				.Quizzes
																		}{" "}
																		{course.written_count}{" "}
																		{
																			language
																				.courseDetails
																				.WrittenExam
																		}{" "}
																		{
																			course.flash_cards_count
																		}{" "}
																		{
																			language
																				.courseDetails
																				.FlashCards
																		}{" "}
																		{course.duration}{" "}
																		{
																			language
																				.courseDetails
																				.Duration
																		}{" "}
																	</p> */}
																			</div>
																		),
																		body: (
																			<div
																				style={{
																					padding:
																						"0px 20px",
																				}}
																			>
																				<div
																					dangerouslySetInnerHTML={{
																						__html:
																							lang ===
																							"en"
																								? syllabus.description
																								: syllabus.description,
																					}}
																				/>
																			</div>
																		),
																	}),
																)}
															/>
														</div>
													) : (
														<h6 className="text-center">
															{language.Nodatafound}
														</h6>
													)}
												</div>
											) : null}

											<div
												id="reviews"
												className={`tab-pane fade show active`}
											>
												<h5 className="my-4">{language.reviews}</h5>
												<div className="course-tab-content">
													<div className="row row--30">
														<div className="col-lg-4">
															<div className="rating-box">
																<div className="rating-number">
																	{courseItem?.rating}
																</div>
																<div className="rating eduvibe-course-rating-stars">
																	{Array.from({
																		length: Math.round(
																			courseItem.rating,
																		),
																	}).map((item, i) => (
																		<i
																			key={i}
																			className="icon-Star"
																		></i>
																	))}
																</div>
																<span>
																	({courseItem.review}{" "}
																	{language.courseDetails.Review})
																</span>
															</div>
														</div>

														<div className="col-lg-8">
															<div className="review-wrapper">
																<div className="single-progress-bar">
																	<div className="rating-text">
																		5{" "}
																		<i className="icon-Star"></i>
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${courseReviewRes?.review_percentage.five}%`,
																			}}
																			aria-valuenow={
																				courseReviewRes
																					?.review_percentage
																					.five
																			}
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																	</div>
																	<span className="rating-value">
																		{
																			courseReviewRes
																				?.review_percentage
																				.five
																		}
																		%
																	</span>
																</div>

																<div className="single-progress-bar">
																	<div className="rating-text">
																		4{" "}
																		<i className="icon-Star"></i>
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${courseReviewRes?.review_percentage.four}%`,
																			}}
																			aria-valuenow={
																				courseReviewRes
																					?.review_percentage
																					.four
																			}
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																	</div>
																	<span className="rating-value">
																		{
																			courseReviewRes
																				?.review_percentage
																				.four
																		}
																		%
																	</span>
																</div>

																<div className="single-progress-bar">
																	<div className="rating-text">
																		3{" "}
																		<i className="icon-Star"></i>
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${courseReviewRes?.review_percentage.three}%`,
																			}}
																			aria-valuenow={
																				courseReviewRes
																					?.review_percentage
																					.three
																			}
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																	</div>
																	<span className="rating-value">
																		{
																			courseReviewRes
																				?.review_percentage
																				.three
																		}
																		%
																	</span>
																</div>

																<div className="single-progress-bar">
																	<div className="rating-text">
																		2{" "}
																		<i className="icon-Star"></i>
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${courseReviewRes?.review_percentage.two}%`,
																			}}
																			aria-valuenow={
																				courseReviewRes
																					?.review_percentage
																					.two
																			}
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																	</div>
																	<span className="rating-value">
																		{
																			courseReviewRes
																				?.review_percentage
																				.two
																		}
																		%
																	</span>
																</div>

																<div className="single-progress-bar">
																	<div className="rating-text">
																		1{" "}
																		<i className="icon-Star"></i>
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${courseReviewRes?.review_percentage.one}%`,
																			}}
																			aria-valuenow={
																				courseReviewRes
																					?.review_percentage
																					.one
																			}
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																	</div>
																	<span className="rating-value">
																		{
																			courseReviewRes
																				?.review_percentage
																				.one
																		}
																		%
																	</span>
																</div>
															</div>
														</div>
													</div>
													{user ? (
														<div
															className="my-4 "
															style={{
																border: "1px solid lightgray",
																borderRadius: 5,
																padding: 20,
															}}
														>
															<h6 className="text-center mt-4 mb-0">
																{
																	language.courseDetails
																		.WriteAReview
																}
															</h6>
															<h6 className="text-center m-0">
																<span style={{ color: "gray" }}>
																	({feedbackStar[rating]})
																</span>
															</h6>

															<div className="mb-4">
																<div className="rating eduvibe-course-rating-stars text-center ">
																	<Feedback
																		rating={rating}
																		setRating={setRating}
																	/>
																</div>
															</div>
															<h6 className="m-0 mt-4">
																{language.courseDetails.Feedback}
															</h6>
															<form onSubmit={onFeedbackSubmit}>
																<textarea
																	rows={5}
																	onChange={(e) =>
																		setFeedback(e.target.value)
																	}
																	value={feedback}
																	placeholder={
																		language.courseDetails
																			.writeDownYourFeedbackHere
																	}
																	required
																></textarea>
																<div className="text-end">
																	<CustomButton
																		disabled={
																			isLoadingCourseFeedback
																		}
																		type="submit"
																		className="btn my-4"
																		style={{
																			backgroundColor:
																				"#f98a17",
																			color: "white",
																			fontSize: 16,
																			fontWeight: 600,
																			padding: "10px 20px",
																		}}
																	>
																		{
																			language.courseDetails
																				.submitBtn
																		}{" "}
																		<BsSend />
																	</CustomButton>
																</div>
															</form>
														</div>
													) : null}

													<div className="comment-wrapper pt--40">
														{Array.isArray(courseItem.course.review) &&
														courseItem.course.review.length > 0 ? (
															<>
																<div className="section-title">
																	<h5 className="mb--25">
																		{
																			language.courseDetails
																				.StudentsFeedback
																		}
																	</h5>
																</div>

																{sortByDate(
																	[...courseItem.course.review],
																	"created_at",
																)
																	.slice(0, seeMore)
																	.map((review, index) => (
																		<div
																			key={review?.id}
																			className="edu-comment"
																		>
																			<div className="thumbnail">
																				<img
																					style={{
																						width: 70,
																						height: 70,
																					}}
																					src={
																						review
																							?.review_user
																							?.profile_image
																					}
																					alt="Student Thumb"
																				/>
																			</div>
																			<div className="comment-content">
																				<div
																					className="comment-top"
																					style={{
																						display:
																							"block",
																					}}
																				>
																					<div className="d-flex align-items-center">
																						<h6 className="title m-0 me-2">
																							{
																								review
																									?.review_user
																									?.username
																							}
																						</h6>
																						<span>
																							(
																							{getTimeDifference(
																								review?.created_at,
																							)}
																							)
																						</span>
																					</div>

																					<div className="rating eduvibe-course-rating-stars m-0">
																						{Array.from(
																							{
																								length: review?.rating,
																							},
																						).map(
																							(
																								item,
																							) => (
																								<i
																									key={
																										item
																									}
																									className="icon-Star"
																								></i>
																							),
																						)}
																					</div>
																				</div>

																				<p>
																					{
																						review?.feedback
																					}
																				</p>
																			</div>
																		</div>
																	))}
																{courseItem?.course?.review
																	?.length > 3 &&
																courseItem?.course?.review
																	?.length !== seeMore ? (
																	<button
																		className="btn my-4"
																		style={{
																			backgroundColor:
																				"#E2ECFF",
																			color: "#f98a17",
																			fontSize: 16,
																			fontWeight: 600,
																			padding: "10px 20px",
																		}}
																		onClick={() =>
																			setSeeMore(
																				courseItem.course
																					.review.length,
																			)
																		}
																	>
																		Load more
																	</button>
																) : null}

																{!user ? (
																	<h6 className="py-4">
																		<span
																			role="button"
																			style={{
																				color: "#f98a17",
																			}}
																			onClick={() => {
																				dispatch(
																					userModalType(
																						"login",
																					),
																				);
																				dispatch(
																					isModalOpen(
																						true,
																					),
																				);
																			}}
																		>
																			{language.login.title}
																		</span>{" "}
																		{
																			language.courseDetails
																				.togiveacommentnow
																		}
																	</h6>
																) : null}
															</>
														) : null}
														{courseItem?.course?.course_discussions
															?.length > 0 &&
														courseItem?.is_enroll ? (
															<>
																{courseItem?.course
																	?.course_discussions ? (
																	<Comment />
																) : null}

																{!user ? (
																	<h6 className="py-4">
																		<span
																			role="button"
																			style={{
																				color: "#f98a17",
																			}}
																			onClick={() => {
																				dispatch(
																					userModalType(
																						"login",
																					),
																				);
																				dispatch(
																					isModalOpen(
																						true,
																					),
																				);
																			}}
																		>
																			{language.login.title}
																		</span>{" "}
																		{
																			language.courseDetails
																				.togiveacommentnow
																		}
																	</h6>
																) : null}
															</>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col xl={4} lg={5} className="d-none d-lg-block">
									<CourseInfo data={courseItem} setShow={setShow} />
								</Col>
							</Row>
						</div>
					</div>
				</div>
			) : (
				<Loading />
			)}

			<EnrollModal
				show={show}
				isLoadingEnrollCourse={isLoadingEnrollCourse}
				onHide={() => {
					setShow(false);
					setEnroll({});
					setIframe("");
				}}
				modal={{
					type: "enrolment",
					title: courseItem.course.title,
					subTitle: "Start better flexible learning",
					iframe: iframe,
					coupon: coupon,
					onSubmit: (e) => {
						e.preventDefault();
						if (courseItem.course.id) {
							enrollCourse({
								...enroll,
								course_id: courseItem.course.id,
								applied_coupon: checkCoupon,
							});
						} else {
							toast.error("Please fill up all the fields");
						}
					},
					fields: enrollFields,
					footer: {
						title: (
							<>
								<form
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									onSubmit={onCouponSubmit}
								>
									<div style={{ marginTop: "20px", marginBottom: "10px" }}>
										<input
											type="text"
											name="checkCoupon"
											placeholder="Enter Coupon Code"
											className="py-3"
											onChange={(e) => setCheckCoupon(e.target.value)}
											value={checkCoupon ? checkCoupon : ""}
											required
											style={{
												width: "100%",
											}}
										/>
									</div>
									<button
										type={"submit"}
										disabled={isLoadingHasCoupon || coupon}
										style={{
											border: "none",
											textAlign: "center",

											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "40%",
											background:
												isLoadingHasCoupon || coupon ? "gray" : "#3d9734",
											padding: "15px 20px",
											borderRadius: "5px",
											marginTop: "20px",
											marginBottom: "10px",
										}}
									>
										{isLoadingHasCoupon ? (
											<Spinner
												animation="border"
												role="status"
												style={{ color: "white" }}
												className="me-4"
											>
												<span className="visually-hidden">Loading...</span>
											</Spinner>
										) : (
											""
										)}
										<span
											style={{
												fontSize: "16px",
												color: "white",

												fontWeight: 600,
											}}
										>
											Apply
										</span>
									</button>
								</form>
								{coupon && (
									<p
										role="button"
										onClick={(e) => {
											setCoupon(null);
											setCheckCoupon(null);
										}}
										className="m-0 mb-3"
										style={{
											color: "red",
											textAlign: "left",
										}}
									>
										Reset Coupon
									</p>
								)}
								By clicking login, you’re agreeing to our Terms and Conditions,
								Terms of Use and Privacy Policy.
							</>
						),
					},
				}}
			/>
		</>
	);
};
export default CourseDetails;
