import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Import Css Here
import "./assets/scss/style.scss";
import Layout from "./components/Layout";

import Error from "./components/Error";
import ErrorBoundary from "./components/ErrorBoundary";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import AboutPage from "./pages/About";
import AllCourseFilterPages from "./pages/AllCourseFilter";
import Chapters from "./pages/Chapters";
import ContactUsPage from "./pages/ContactUs";
import CoursePage from "./pages/Course";
import CourseDetails from "./pages/CourseDetails";
import DeviceManagerPage from "./pages/DeviceManager";
import Download from "./pages/Download";
import GalleryGridPage from "./pages/GalleryGrid";
import Landing from "./pages/Landing";
import MyCertificatesPage from "./pages/MyCertificates";
import MyCourse from "./pages/MyCourse";
import MyProfilePage from "./pages/MyProfile";
import OrderHistoryPage from "./pages/OrderHistory";
import PrivacyDetails from "./pages/PrivacyDetails";
import QuestionsAnswerPage from "./pages/QuestionsAnswer";
import Quiz from "./pages/Quiz";
import QuizViewPage from "./pages/QuizView";
import StudentDashboard from "./pages/StudentDashboard";
import Subject from "./pages/Subjects";
import ViewWrittenExamPage from "./pages/ViewWrittenExam";
import WrittenExam from "./pages/WrittenExam";

function App() {
	return (
		<ErrorBoundary>
			<Router>
				<ScrollToTop>
					<Layout>
						<Routes>
							<Route exact path="/" element={<Landing />} />
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/gallery-grid"}`}
								element={<GalleryGridPage />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/privacy-details/:slug"}`}
								element={<PrivacyDetails />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/about"}`}
								element={<AboutPage />}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/courses"}`}
								element={<AllCourseFilterPages />}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/course/:slug"}`}
								element={<CoursePage />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL + "/course/:slug/course-details/:id"
								}`}
								element={<CourseDetails />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/download-app"}`}
								element={<Download />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/contact-us"}`}
								element={<ContactUsPage />}
							/>
							{/* login required */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/student-dashboard"}`}
								element={<PrivateRoute children={<StudentDashboard />} />}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/my-course/:course_id"}`}
								element={<PrivateRoute children={<MyCourse />} />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/subject/:subject_id"
								}`}
								element={<PrivateRoute children={<Subject />} />}
							/>
							<Route
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/subject/:subject_id/chapters/:chapter_id"
								}`}
								element={<PrivateRoute children={<Chapters />} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/quiz/:exam_id"}`}
								element={<PrivateRoute children={<Quiz />} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/questions-answer/:exam_id"}`}
								element={<PrivateRoute children={<QuestionsAnswerPage />} />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/quiz-view/:exam_id"
								}`}
								element={<PrivateRoute children={<QuizViewPage />} />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/subject/:subject_id/chapters/:chapter_id/quiz-view/:exam_id"
								}`}
								element={<PrivateRoute children={<QuizViewPage />} />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/written-exam/:exam_id"
								}`}
								element={<PrivateRoute children={<WrittenExam />} />}
							/>
							<Route
								exact
								path={`${
									process.env.PUBLIC_URL +
									"/my-course/:course_id/view-written-exam/:exam_id"
								}`}
								element={<PrivateRoute children={<ViewWrittenExamPage />} />}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/my-profile"}`}
								element={<PrivateRoute children={<MyProfilePage />} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/order-history"}`}
								element={<PrivateRoute children={<OrderHistoryPage />} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/device-manager"}`}
								element={<PrivateRoute children={<DeviceManagerPage />} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL + "/my-certificates"}`}
								element={<PrivateRoute children={<MyCertificatesPage />} />}
							/>

							<Route path="*" element={<Error />} />
						</Routes>
					</Layout>
				</ScrollToTop>
			</Router>
		</ErrorBoundary>
	);
}

export default App;
