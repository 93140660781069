import { Col, Offcanvas, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Search from "./Search";

export default function MobileNav({ navBar, isActiveFunc }) {
	return (
		<div>
			<Offcanvas.Header closeButton className="align-items-center">
				<NavLink to="/">
					<img
						className="logo-light"
						alt={navBar.logo.alt}
						src={navBar.logo.src}
						width={navBar.logo.width}
						height={navBar.logo.height}
					/>
				</NavLink>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Row className="justify-content-center align-items-center">
					<Col sm={12} className="mb-4">
						<Search navBar={navBar} className="w-100" />
					</Col>
					<Col sm={12}>
						<Row className="justify-content-between align-items-center">
							<Col sm={12} className="d-flex align-items-center mb-4">
								<div className="header-menu-bar w-100">
									<div className="quote-icon quote-user  w-100"></div>
								</div>
							</Col>
							{navBar.nav.map((path, index) => {
								return (
									<Col key={index} sm={12} className="text-center mb-4">
										<NavLink
											to={path.link}
											onClick={path.onClick}
											className={` ${isActiveFunc}`}
										>
											{path.text}
										</NavLink>
									</Col>
								);
							})}
						</Row>
					</Col>
				</Row>
			</Offcanvas.Body>
		</div>
	);
}
