import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setCourseFilter } from "src/redux/course/courseSlice";
import { lanChange } from "src/utils";
import "./multiRangeSlider.css";
const PriceFilter = () => {
	const { filter = {} } = useSelector((state) => state.course);
	const dispatch = useDispatch();
	const handleChange = (value) => {
		dispatch(setCourseFilter({ ...filter, minPrice: value[0], maxPrice: value[1] }));
	};

	const language = lanChange();

	return (
		<Row className="justify-content-between px-4">
			<Col sm={12}>
				<Slider
					range
					min={0}
					max={50000}
					allowCross={false}
					value={[filter.minPrice, filter.maxPrice]}
					onChange={handleChange}
					className="mb-4"
				/>
			</Col>
			<Col sm={6}>
				<label className="text-muted">{language.MinPrice}:</label>
				<input
					className="py-3 text-center  w-100"
					style={{
						border: "1px solid lightgray",
						borderRadius: 5,
						fontSize: 14,
						fontWeight: 600,
					}}
					type="number"
					value={filter.minPrice}
					onChange={(e) => {
						dispatch(
							setCourseFilter({
								...filter,
								minPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0,
							}),
						);
					}}
					placeholder="৳ min:"
					min={0}
					max={50000}
				/>
			</Col>
			<Col sm={6}>
				<label className="text-muted">{language.MaxPrice}:</label>
				<input
					className="py-3 text-center  w-100"
					style={{
						border: "1px solid lightgray",
						borderRadius: 5,
						fontSize: 14,
						fontWeight: 600,
					}}
					type="number"
					value={filter.maxPrice}
					onChange={(e) => {
						dispatch(
							setCourseFilter({
								...filter,
								maxPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0,
							}),
						);
					}}
					placeholder="৳ max:"
					min={0}
					max={50000}
				/>
			</Col>
		</Row>
	);
};

export default PriceFilter;
