import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { lanChange } from "src/utils";
import GalleryItem from "../Gallery/GalleryItem";
import SectionTitle from "../common/SectionTitle";

export default function Gallery() {
	const { gallery } = useSelector((state) => state.homePageInfo.home);

	const language = lanChange();

	return (
		<div className="edu-gallery-grid-area masonary-wrapper-activation edu-section-gap bg-image bg-image--25 overflow-hidden">
			<div className="wrapper">
				<div className="container">
					<div className="row g-5">
						<div className="col-lg-12">
							<SectionTitle
								classes="text-center"
								slogan={language.home.galleryAndEvents.title}
								title={language.home.galleryAndEvents.subTitle}
							/>
						</div>
					</div>

					<div className="row g-5 mt--5">
						<div className="container">
							<div className="eduvibe-gallery-items row g-5 mt--5">
								{gallery?.map((item) => (
									<div key={item?.id} className="col-lg-4 col-md-6 col-12">
										<GalleryItem data={item} />
									</div>
								))}
							</div>
						</div>

						<div className="col-12 text-center">
							<ScrollAnimation
								animateIn="fadeInUp"
								animateOut="fadeInOut"
								animateOnce={true}
							>
								<div className="read-more-btn sal-animate">
									<Link className="edu-btn" to="/gallery-grid">
										{language.home.galleryAndEvents.btn}
										<i className="icon-arrow-right-line-right"></i>
									</Link>
								</div>
							</ScrollAnimation>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
