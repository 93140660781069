import React from "react";
import Error from "./Error";

export default class ErrorBoundary extends React.Component {
	state = { hasError: false };
	static getDerivedStateFromError(error) {
		return { hasError: true };
	}
	componentDidCatch(error, errorInfo) {
		console.log({ error, errorInfo });
	}
	render() {
		if (this.state.hasError) {
			return <Error />;
		}
		return this.props.children;
	}
}
