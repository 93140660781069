import { Link } from "react-router-dom";

const CourseTwo = ({ data, classes }) => {
	// const price = Number(data.price);
	// const oldPrice = data.oldPrice ? Number(data.oldPrice) : 0;
	// const isOffer = data.oldPrice && price < oldPrice;
	// const offerInPercentage = Math.round((100 * (oldPrice - price)) / oldPrice);
	// console.log(data);
	return (
		<div className={`edu-card card-type-5 radius-small ${classes ? classes : ""}`}>
			<div className="inner">
				<div className="thumbnail">
					<Link
						to={
							process.env.PUBLIC_URL +
							`/course/${String(data?.title).split(" ").join("-")}/course-details/${
								data?.id
							}`
						}
					>
						<img
							className="w-100"
							src={data?.featured_image}
							alt="Course Thumb"
							style={{ width: 425, height: 200 }}
						/>
					</Link>

					<ul className="edu-meta meta-01 justify-content-between mt-4">
						<li>
							<i className="icon-time-line"></i>
							{data?.duration}
						</li>
						<li>
							<i className="icon-file-list-4-line"></i>
							{data?.subject_count} Subjects
						</li>
					</ul>
					<h6 className="title mt-4">
						<Link
							to={
								process.env.PUBLIC_URL +
								`/course/${data?.title}/course-details/${data?.id}`
							}
						>
							{data?.title}
						</Link>
					</h6>

					{/* <div className="top-position status-group left-top">
						{isOffer && (
							<span className="eduvibe-status status-01 bg-secondary-color">
								{offerInPercentage}% Off
							</span>
						)}
						<span className="eduvibe-status status-01 bg-primary-color">
							{data.level}
						</span>
					</div>
					<div className="wishlist-top-right">
						<button className="wishlist-btn">
							<i className="icon-Heart"></i>
						</button>
					</div> */}
				</div>
				{/* <div className="content"> */}
				{/* <div className="price-list price-style-03">
						{data.price === "0" ? (
							<div className="price current-price">Free</div>
						) : (
							<div className="price current-price">${data.price}</div>
						)}
						{data.oldPrice && <div className="price old-price">${data.oldPrice}</div>}
					</div> */}

				{/* <div className="card-bottom">
						<div className="read-more-btn">
							<Link
								className="btn-transparent"
								to={process.env.PUBLIC_URL + `/course-details/${data.id}`}
							>
								Enroll Now<i className="icon-arrow-right-line-right"></i>
							</Link>
						</div>
					</div> */}
				{/* </div> */}
			</div>
		</div>
	);
};
export default CourseTwo;
