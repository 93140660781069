import { MDBCheckbox } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { setCourseFilter } from "src/redux/course/courseSlice";

export default function CourseFilter() {
	const dispatch = useDispatch();
	const { filter = {} } = useSelector((state) => state.course);

	const onChange = (id, value) => {
		const coursesFilter = filter.coursesFilter.map((item) => {
			if (item.id === id) {
				return { ...item, checked: value };
			} else {
				return { ...item };
			}
		});

		dispatch(setCourseFilter({ ...filter, coursesFilter: coursesFilter }));
	};

	return (
		<>
			{Array.isArray(filter.coursesFilter) && filter.coursesFilter.length
				? filter.coursesFilter.map((item) => (
						<div key={item.id} className="d-flex justify-content-between mb-2">
							<div>
								<MDBCheckbox
									name={`CourseFilter`}
									id={`CourseFilter-${item.id}`}
									value={item.id}
									label={item.label}
									checked={item.checked}
									onChange={(e) => onChange(item.id, e.target.checked)}
								/>
							</div>
							<div>{item.totalSubject}</div>
						</div>
				  ))
				: null}
		</>
	);
}
