import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { lanChange } from "src/utils";
import { ThreeColumnCarousel } from "src/utils/script";
import Loading from "../common/Loading";
import SectionTitle from "../common/SectionTitle";
import CourseTwo from "./CourseTwo";

const HomeFiveCourses = () => {
	// base URL
	const baseUrl = process.env.REACT_APP_BASE_URL;
	// user info
	const user_data = useSelector((state) => state.auth?.user);
	// api end point
	const apiUrl = `${baseUrl}/api/fetured-course`;
	// token
	const bearerToken = user_data?.token;
	const [feturedCourse, setFeturedCourseData] = useState([]);
	const language = lanChange();
	useEffect(() => {
		fetch(apiUrl, {
			headers: {
				Authorization: `Bearer ${bearerToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				setFeturedCourseData(data);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [bearerToken]);
	if (!feturedCourse?.data) {
		return <Loading />;
	}
	return (
		<>
			{Array.isArray(feturedCourse?.data) && feturedCourse?.data?.length > 0 ? (
				<div className="eduvibe-home-five-course slider-dots edu-course-area edu-section-gap ">
					<div className="container">
						<div className="eduvibe-animated-shape">
							<div className="row g-5 justify-content-between">
								<div className="col-lg-6">
									<SectionTitle
										classes="text-start"
										slogan={language.studentdashboard?.feturedCourse?.title}
										title={language.studentdashboard?.feturedCourse?.subTitle}
									/>
								</div>
								<div className="col-lg-6">
									<div
										className="view-more-btn text-end section-title"
										style={{ marginBottom: 10 }}
									></div>
								</div>
							</div>
							<div className="row g-5 mt--10">
								<div
									className="col-lg-4
						"
								>
									<Slider
										className="slick-activation-wrapper course-activation-3 edu-slick-button"
										{...ThreeColumnCarousel}
									>
										{feturedCourse?.data.map((item) => (
											<div className="single-slick-card" key={item.id}>
												<CourseTwo data={item} />
											</div>
										))}
									</Slider>
								</div>
							</div>

							{/* <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-13-10.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-04-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-15-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-03-07.png" alt="Shape Thumb" />
                    </div>
                </div> */}
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default HomeFiveCourses;
