import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import mcq from "src/assets/images/mcq.svg";
import written from "src/assets/images/written.svg";
import { lanChange } from "src/utils";
import Info from "./Info";
import SubjectOutlineListModal from "./SubjectOutlineListModal";

export default function Excercises() {
	const { course_id } = useParams();
	const [show, setShow] = React.useState(false);
	const [subjectOutlineList, setSubjectOutlineList] = React.useState(null);
	const myCourses = useSelector((state) => state.course.myCourses);

	const language = lanChange();
	return (
		<div>
			<Info
				defaultTab={1}
				tab={myCourses?.subjects.map((item, i) => ({
					key: i + 1,
					id: item?.name.id,
					name: `${i + 1}. ${item?.name}`,
					content: (
						<div
							key={item.id}
							className="px-5"
							style={{
								backgroundColor: "white",
							}}
						>
							{myCourses?.course?.course_type !== "High Court" ? (
								<>
									<div className="blog-author-wrapper">
										<div
											className="thumbnail"
											style={{ marginRight: "0px", minWidth: 100 }}
										>
											<img src={mcq} alt="Author Images" />
										</div>
										<div className="author-content w-100 ">
											<Row className="justify-content-between">
												<Col sm={6} md={6} lg={6}>
													<h6
														style={{
															fontSize: "20px",
															fontWeight: 700,
															lineHeight: "24px",
															letterSpacing: "0em",
															textAlign: "left",
														}}
														className="title"
													>
														{language.myCourse.BarCouncilOrderMCQ}
													</h6>
													<h6
														className="title"
														style={{
															fontSize: "14px",
															fontWeight: 700,
															lineHeight: "25px",
															letterSpacing: "0em",
															textAlign: "left",
														}}
													>
														{language.studentdashboard.Progress}:{" "}
														{item?.total_progress?.[0]
															?.total_progress || 0}
														%
													</h6>
												</Col>
												<Col
													sm={6}
													md={6}
													lg={6}
													className="text-end d-none d-md-block"
												>
													<div className="load-more-btn mb-4">
														{item?.black_letter_laws?.length > 0 && (
															<button
																className="btn btn-outline-primary btn-lg me-4 py-3 fs-4"
																style={{ fontWeight: 600 }}
																onClick={() => {
																	setSubjectOutlineList(
																		item?.black_letter_laws,
																	);
																	setShow(true);
																}}
															>
																{language.myCourse.SubjectOutline}
															</button>
														)}

														<Link
															style={{
																fontWeight: 600,
																padding: "0px 25px",
															}}
															className="btn btn-primary btn-lg py-3 fs-4"
															to={`/my-course/${course_id}/subject/${item.id}?chapter_type=MCQ`}
														>
															{language.myCourse.Resume}
														</Link>
													</div>
												</Col>
											</Row>

											<ProgressBar
												now={item?.total_progress?.[0]?.total_progress}
												max={100}
												min={0}
											/>
											{item?.black_letter_laws?.length > 0 && (
												<button
													className="btn btn-outline-primary btn-lg mt-4 me-4 py-4 d-md-none w-100 fs-4"
													style={{ fontWeight: 600 }}
													onClick={() => {
														setSubjectOutlineList(
															item?.black_letter_laws,
														);
														setShow(true);
													}}
												>
													{language.myCourse.SubjectOutline}
												</button>
											)}
											<div className="load-more-btn text-center w-100 h-0 d-md-none  mt-4">
												<Link
													className="edu-btn w-100"
													to={`/my-course/${course_id}/subject/${item.id}?chapter_type=MCQ`}
												>
													{language.myCourse.Resume}
												</Link>
											</div>
										</div>
									</div>
									<div className="blog-author-wrapper">
										<div
											className="thumbnail"
											style={{ marginRight: "0px", minWidth: 100 }}
										>
											<img src={written} alt="Author Images" />
										</div>
										<div className="author-content w-100 ">
											<Row className="justify-content-between">
												<Col sm={6} md={6} lg={6}>
													<h6
														style={{
															fontSize: "20px",
															fontWeight: 700,
															lineHeight: "24px",
															letterSpacing: "0em",
															textAlign: "left",
														}}
														className="title"
													>
														{language.myCourse.BarCouncilOrderWritten}
													</h6>
													{/* {item?.total_progress?.[0]?.total_progress && (
												<h6
													className="title"
													style={{
														fontSize: "14px",
														fontWeight: 700,
														lineHeight: "25px",
														letterSpacing: "0em",
														textAlign: "left",
													}}
												>
													Progress:{" "}
													{item?.total_progress?.[0]?.total_progress}%
												</h6>
											)} */}
												</Col>
												{item && (
													<Col
														sm={6}
														md={6}
														lg={6}
														className="text-end d-none d-md-block"
													>
														<div className="load-more-btn mb-4">
															{item?.black_letter_laws?.length >
																0 && (
																<button
																	className="btn btn-outline-primary btn-lg me-4 py-3 fs-4"
																	style={{ fontWeight: 600 }}
																	onClick={() => {
																		setSubjectOutlineList(
																			item?.black_letter_laws,
																		);
																		setShow(true);
																	}}
																>
																	{
																		language.myCourse
																			.SubjectOutline
																	}
																</button>
															)}

															<Link
																className="btn btn-primary btn-lg py-3 fs-4"
																style={{
																	fontWeight: 600,
																	padding: "0px 35px",
																}}
																to={`/my-course/${course_id}/subject/${item.id}?chapter_type=Written`}
															>
																{item.written_subject_progress_count
																	? language.myCourse.Resume
																	: language.start}
															</Link>
														</div>
													</Col>
												)}
											</Row>

											{/* <ProgressBar
										now={item?.total_progress?.[0]?.total_progress}
										max={100}
										min={0}
									/> */}
											{item?.black_letter_laws?.length > 0 && (
												<button
													className="btn btn-outline-primary btn-lg me-4 py-4 d-md-none w-100 fs-4"
													style={{ fontWeight: 600 }}
													onClick={() => {
														setSubjectOutlineList(
															item?.black_letter_laws,
														);
														setShow(true);
													}}
												>
													{language.myCourse.SubjectOutline}
												</button>
											)}

											<div className="load-more-btn text-center w-100 h-0 d-md-none  mt-4">
												<Link
													className="edu-btn w-100"
													to={`/my-course/${course_id}/subject/${item.id}?chapter_type=Written`}
												>
													{item.written_subject_progress_count
														? language.myCourse.Resume
														: language.start}
												</Link>
											</div>
										</div>
									</div>
								</>
							) : (
								<div className="blog-author-wrapper">
									<div
										className="thumbnail"
										style={{ marginRight: "0px", minWidth: 100 }}
									>
										<img src={mcq} alt="Author Images" />
									</div>
									<div className="author-content w-100 ">
										<Row className="justify-content-between">
											<Col sm={6} md={6} lg={6}>
												<h6
													style={{
														fontSize: "20px",
														fontWeight: 700,
														lineHeight: "24px",
														letterSpacing: "0em",
														textAlign: "left",
													}}
													className="title"
												>
													{item?.name}
												</h6>
												<h6
													className="title"
													style={{
														fontSize: "14px",
														fontWeight: 700,
														lineHeight: "25px",
														letterSpacing: "0em",
														textAlign: "left",
													}}
												>
													{language.studentdashboard.Progress}:{" "}
													{item?.total_progress?.[0]?.total_progress || 0}
													%
												</h6>
											</Col>
											<Col
												sm={6}
												md={6}
												lg={6}
												className="text-end d-none d-md-block"
											>
												<div className="load-more-btn mb-4">
													{item?.black_letter_laws?.length > 0 && (
														<button
															className="btn btn-outline-primary btn-lg me-4 py-3 fs-4"
															style={{ fontWeight: 600 }}
															onClick={() => {
																setSubjectOutlineList(
																	item?.black_letter_laws,
																);
																setShow(true);
															}}
														>
															{language.myCourse.SubjectOutline}
														</button>
													)}

													<Link
														style={{
															fontWeight: 600,
															padding: "0px 25px",
														}}
														className="btn btn-primary btn-lg py-3 fs-4"
														to={`/my-course/${course_id}/subject/${
															item.id
														}?chapter_type=MCQ&course_type=${String(
															myCourses?.course?.course_type,
														)
															.split(" ")
															.join("-")}`}
													>
														{language.myCourse.Resume}
													</Link>
												</div>
											</Col>
										</Row>

										<ProgressBar
											now={item?.total_progress?.[0]?.total_progress}
											max={100}
											min={0}
										/>
										{item?.black_letter_laws?.length > 0 && (
											<button
												className="btn btn-outline-primary btn-lg mt-4 me-4 py-4 d-md-none w-100 fs-4"
												style={{ fontWeight: 600 }}
												onClick={() => {
													setSubjectOutlineList(item?.black_letter_laws);
													setShow(true);
												}}
											>
												{language.myCourse.SubjectOutline}
											</button>
										)}
										<div className="load-more-btn text-center w-100 h-0 d-md-none  mt-4">
											<Link
												className="edu-btn w-100"
												to={`/my-course/${course_id}/subject/${
													item.id
												}?chapter_type=MCQ&course_type=${String(
													myCourses?.course?.course_type,
												)
													.split(" ")
													.join("-")}`}
											>
												{language.myCourse.Resume}
											</Link>
										</div>
									</div>
								</div>
							)}
						</div>
					),
				}))}
			/>

			{subjectOutlineList && (
				<SubjectOutlineListModal
					show={show}
					onHide={() => setShow(false)}
					subjectOutlineList={subjectOutlineList}
				/>
			)}
		</div>
	);
}
