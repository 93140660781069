import { Col, Modal, Row } from "react-bootstrap";
import { lanChange } from "src/utils";

export default function SubjectOutlineListModal({ show, onHide, subjectOutlineList }) {
	const openPDF = (file_path) => {
		const baseUrl = process.env.REACT_APP_BASE_URL;
		window.open(baseUrl + "/storage/" + file_path, "_blank");
	};
	const language = lanChange();
	return (
		<Modal
			show={show}
			onHide={() => onHide()}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			fullscreen="sm-down"
		>
			<Modal.Header closeButton className="subjectOutlineListModal">
				<div className="text-center">{language.myCourse.SubjectOutline}</div>
			</Modal.Header>
			<div className="container">
				<div>
					{subjectOutlineList.map((item, i) => (
						<Row
							key={i}
							className="justify-content-between align-items-center mb-2  p-4"
							style={{
								borderBottom: "1px solid #d3d3d3",
							}}
						>
							<Col sm={6}>
								{language.myCourse.SubjectOutline} {i + 1}
							</Col>
							<Col sm={4}>
								<button
									onClick={() => openPDF(item.file_path)}
									style={{
										backgroundColor: "#f98a17",
										border: "1px solid #d3d3d3",
										borderRadius: 5,
										padding: 10,
										width: "100%",
										fontSize: 14,
										fontWeight: 700,
										lineHeight: "24px",
										letterSpacing: "0em",
										textAlign: "center",
										color: "#ffffff",
									}}
								>
									View
								</button>
							</Col>
						</Row>
					))}
				</div>
			</div>
		</Modal>
	);
}
