import { getMonth, getYear } from "date-fns";
import { range } from "lodash";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function DateField({ className, onChange, placeholder, required, value }) {
	const years = range(1990, getYear(new Date()) + 1, 1);

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	return (
		<ReactDatePicker
			className={className}
			placeholderText={placeholder}
			renderCustomHeader={({
				date,
				changeYear,
				changeMonth,
				decreaseMonth,
				increaseMonth,
				prevMonthButtonDisabled,
				nextMonthButtonDisabled,
			}) => (
				<div
					style={{
						margin: 10,
						display: "flex",
						justifyContent: "center",
					}}
				>
					<button
						type="button"
						onClick={decreaseMonth}
						disabled={prevMonthButtonDisabled}
						style={{
							fontSize: "16px",
							border: "2px solid #f98a17",
							backgroundColor: "#f98a17",
							color: "white",
							fontWeight: 600,
							borderRadius: "5px",
						}}
					>
						{"<"}
					</button>
					<select
						value={getYear(date)}
						onChange={({ target: { value } }) => changeYear(value)}
						style={{
							border: "2px solid #f98a17",
							margin: "0 10px",
						}}
					>
						{years.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<select
						value={months[getMonth(date)]}
						onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
						style={{
							border: "2px solid #f98a17",
							margin: "0 10px",
						}}
					>
						{months.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<button
						type="button"
						onClick={increaseMonth}
						disabled={nextMonthButtonDisabled}
						style={{
							fontSize: "16px",
							border: "2px solid #f98a17",
							backgroundColor: "#f98a17",
							color: "white",
							fontWeight: 600,
							borderRadius: "5px",
						}}
					>
						{">"}
					</button>
				</div>
			)}
			selected={value}
			onChange={(date) => onChange(date)}
			value={value}
		/>
	);
}
