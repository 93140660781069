import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetSocialLoginMutation } from "src/redux/auth/authApi";
import { userModalType } from "src/redux/auth/authSlice";

const SocialLoginButtons = ({ type, setSignUp, setUserInfo }) => {
	const [getSocialLogin, { isLoading, data }] = useGetSocialLoginMutation();
	const [user, setUser] = React.useState(null);
	const dispatch = useDispatch();
	const fbRaf = useRef(null);
	const login = useGoogleLogin({
		onSuccess: async (codeResponse) => {
			const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
				headers: {
					Authorization: `${codeResponse.token_type} ${codeResponse.access_token}`,
				},
			});
			const userData = await response.json();

			setUser(userData);
			await getSocialLogin({
				token: codeResponse.access_token,
				provider: "google",
			});
		},
	});

	const onLoginSuccess = (user) => {
		console.log(user);
	};
	const responseFacebook = (response) => {
		console.log(response);
	};
	useEffect(() => {
		if (data?.status === "success") {
			if (data.data?.is_new) {
				dispatch(userModalType("sign-up"));
				setSignUp({
					name: user?.name,
					email: user?.email,
				});
			} else {
				setUserInfo(data);
			}
		} else {
			toast.error(data?.message);
		}
	}, [isLoading]);
	return (
		<>
			<p className="p-0 m-0 text-center">or</p>
			{/* <SocialLogin
				provider="facebook"
				appId={process.env.REACT_APP_FACEBOOK_APP_ID}
				// onLoginSuccess={onLoginSuccess}
				// onLoginFailure={(e) => console.log(e)}
				// onLogoutSuccess={(e) => console.log(e)}
				// getInstance={() => fbRaf}
				key={"facebook"}
				onInternetFailure={() => {
					return true;
				}}
			>
				
			</SocialLogin> */}
			{/* <FacebookLoginButton />
			<GoogleLoginButton onClick={login} className="my-4" /> */}
		</>
	);
};

export default SocialLoginButtons;
